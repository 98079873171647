<div class="search-content filemanager">
  <div class="search-type " *ngIf="documents && documents.length > 0">
    <h3>Dokumenti</h3>
    <div class="search-content">
      <a href="{{document.file}}" *ngFor="let document of documents">
        <app-file [file]="document"></app-file>
      </a>
    </div>
  </div>

  <div class="search-type" *ngIf="employees && employees.length > 0">
    <h3>Ljudje</h3>
    <div class="search-people">
      <div class="row">
        <div class="col-6 mb-3 {{small? '' : 'col-lg-3'}}" *ngFor="let employee of employees">
          <app-employee-item [employee]="employee" [extendedInfo]="true"></app-employee-item>
        </div>
      </div>
    </div>
  </div>

  <div class="search-type" *ngIf="(news && news.length > 0) || (notices && notices.length > 0)">
    <h3>Top zadetki</h3>
    <div class="search-content">
      <div class="result" *ngFor="let article of news">
        <a [routerLink]="['/novice/details/' + article.id]">
          <h4>{{article.headline}}</h4>
        </a>
        <p>{{article.introduction}}</p>
      </div>
      <div class="result" *ngFor="let article of notices">
        <a [routerLink]="['/obvestila/details/' + article.id]">
          <h4>{{article.headline}}</h4>
        </a>
        <p>{{article.introduction}}</p>
      </div>
    </div>
  </div>

  <div class="search-type" *ngIf="vehicles && vehicles.length > 0">
    <h3>Avtopark</h3>
    <div class="search-content">
      <div class="result" *ngFor="let vehicle of vehicles">
        <a [routerLink]="['/vozni-park/vozila']">
          {{vehicle.brand}} {{vehicle.model}} {{vehicle.registration_number}}
        </a>
      </div>
    </div>
  </div>
</div>
