<app-content-box class="calendar">
  <div class="title">
    <h2>Koledar</h2>
    <a [routerLink]="['/koledar']" class="archive">Vsi koledarji</a>
  </div>
  <div class="sidebar-calendar-container">
    <full-calendar [options]="calendarOptions" #calendar></full-calendar>
  </div>

  <ng-template #popoverTmpl let-events="events">
    <!-- <h6>{{ event.title }}</h6> -->
    <ul class="popover-event-list">
      <li *ngFor="let event of events">
        <h4>{{event.title}}</h4>
        <p>{{event.scheduled_start_datetime | date:'dd.MM.YYYY'}} - {{(event.recurring_period_end_date? event.recurring_period_end_date : event.scheduled_end_datetime) | date:'dd.MM.YYYY'}}</p>
        <p><strong> Lastnik dogodka: {{getName(event.author)}} </strong></p>
      </li>
    </ul>
  </ng-template>
</app-content-box>
