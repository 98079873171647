<nav class="navbar navbar-expand-lg navbar-light">
  <div class="col-12">
    <div class="container">
      <a class="navbar-brand" [routerLink]="['']" routerLinkActive="router-link-active"><img
          src="/assets/image/logo.svg" alt=""></a>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        (click)="isCollapsed = !isCollapsed" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse pt-2 pt-lg-0" [ngbCollapse]="isCollapsed" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/organizacijski-prirocnik']"
              routerLinkActive="router-link-active">Knjižnica</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/foto-in-video-galerija']" routerLinkActive="router-link-active">Galerija</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/koledar']" routerLinkActive="router-link-active">Koledar</a>
          </li>
          <li class="nav-item">
            <a class="nav-link news-icon" [routerLink]="['/novice']" [queryParams]="{tags_in: 7}" routerLinkActive="router-link-active">Življenjski slog</a>
          </li>
          <li class="nav-item">
            <a class="nav-link eco-icon" [routerLink]="['/novice']" [queryParams]="{tags_in: 17}" routerLinkActive="router-link-active">EKO kotiček</a>
          </li>
          <div class="d-lg-none">
            <li class="nav-item">
              <a [routerLink]="['/coc']" routerLinkActive="router-link-active" class="nav-link">Smernice CoC</a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/vozni-park']" *ngIf="auth.isCarParkManager()" routerLinkActive="router-link-active" class="nav-link">Vozni park</a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/imenik']" routerLinkActive="router-link-active" class="nav-link">Imenik</a>
            </li>
            <li class="nav-item">
              <a href="" class="nav-link" (click)="$event.preventDefault(); logout()">Odjava</a>
            </li>
          </div>
        </ul>

        <div class="dir-search search-form my-2 my-lg-0">
          <form action="#" id="dirsearch" method="post" name="module-directory-search-form" [formGroup]="searchForm">
            <input formControlName="search" class="input-placeholder" type="text" placeholder="Vpiši iskalne nize"
              name="module-directory-search" id="module-directory-search">
            <button (click)="onSearch()" title="Začni iskanje">Išči</button>
            <div class="search-results-panel" *ngIf="openSearchResults">
              <app-search-results [vehicles]="vehicles" [employees]="employees" [news]="news" [notices]="notices" [documents]="documents" [small]="true"></app-search-results>
              <a routerLink="['/iskalnik']" [routerLink]="['/iskalnik']" [queryParams]="{search: searchForm.value.search}" class="all">Vsi zadetki</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</nav>
