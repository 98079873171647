<div class="file-item" *ngIf="file">

  <a href="{{file.file}}" target="_blank" class="file-icon {{getFileType(file.title)}}" *ngIf="file" (click)="onDownload($event)" download="{{file.title}}"></a>
  <div class="file" *ngIf="file">
      <div class="content">
        <p>
          <a href="{{file.file}}" target="_blank" (click)="onDownload($event)" download="{{file.title}}">{{trimExtension(file.title)}}</a>
          <em>({{getExtension(file.title)}}: {{file.media_size}} KB)</em>
        </p>
        <span class="date">
          {{file.created_at | date: 'dd.MM.yyyy'}}
        </span>
      </div>
      <div class="buttons-wrap">
        <div class="buttons editable">
          <a href="{{file.file}}" target="_blank" title="Prenesi" class="download" (click) = "onDownload($event)" download="{{file.title}}"></a>
          <a href="" title="Pridobi povezavo do datoteke" class="urllink" (click) = "onGetUrl($event)"></a>
          <a href="" title="Uredi" class="edit" (click) = "onEdit($event)" *ngIf="editable"></a>
          <a href="" title="Premakni" class="move" (click) = "onMove($event)" *ngIf="editable"></a>
          <a href="" title="Izbriši" class="delete" (click) = "onDelete($event)" *ngIf="editable"></a>
        </div>
      </div>
  </div>
</div>
