/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Announcement } from '../models/announcement';
import { AnnouncementRequest } from '../models/announcement-request';
import { AnnualLeave } from '../models/annual-leave';
import { AnnualLeaveRequest } from '../models/annual-leave-request';
import { Article } from '../models/article';
import { ArticleListRetrieve } from '../models/article-list-retrieve';
import { ArticleRequest } from '../models/article-request';
import { ConferenceRoom } from '../models/conference-room';
import { Directory } from '../models/directory';
import { DirectoryCreateUpdate } from '../models/directory-create-update';
import { DirectoryCreateUpdateRequest } from '../models/directory-create-update-request';
import { Employee } from '../models/employee';
import { EmployeeRequest } from '../models/employee-request';
import { Event } from '../models/event';
import { EventCategory } from '../models/event-category';
import { EventCreateUpdate } from '../models/event-create-update';
import { EventCreateUpdateRequest } from '../models/event-create-update-request';
import { ExtraLeave } from '../models/extra-leave';
import { ExtraLeaveRequest } from '../models/extra-leave-request';
import { Gallery } from '../models/gallery';
import { GalleryCreateUpdate } from '../models/gallery-create-update';
import { GalleryCreateUpdateRequest } from '../models/gallery-create-update-request';
import { GalleryMediaBulkUpdateRequest } from '../models/gallery-media-bulk-update-request';
import { GalleryMediaChangePositionRequest } from '../models/gallery-media-change-position-request';
import { Group } from '../models/group';
import { Holiday } from '../models/holiday';
import { HolidayRequest } from '../models/holiday-request';
import { Link } from '../models/link';
import { LinkRequest } from '../models/link-request';
import { Magazine } from '../models/magazine';
import { MagazineList } from '../models/magazine-list';
import { MagazineRequest } from '../models/magazine-request';
import { Media } from '../models/media';
import { MediaAllowedFormats } from '../models/media-allowed-formats';
import { MediaRequest } from '../models/media-request';
import { MediaUpdate } from '../models/media-update';
import { MediaUpdateRequest } from '../models/media-update-request';
import { Notice } from '../models/notice';
import { NoticeListRetrieve } from '../models/notice-list-retrieve';
import { NoticeRequest } from '../models/notice-request';
import { Occurrence } from '../models/occurrence';
import { Office } from '../models/office';
import { OfficeRequest } from '../models/office-request';
import { PaginatedAnnouncementList } from '../models/paginated-announcement-list';
import { PaginatedAnnualLeaveList } from '../models/paginated-annual-leave-list';
import { PaginatedArticleListRetrieveList } from '../models/paginated-article-list-retrieve-list';
import { PaginatedConferenceRoomList } from '../models/paginated-conference-room-list';
import { PaginatedDirectoryList } from '../models/paginated-directory-list';
import { PaginatedEmployeeList } from '../models/paginated-employee-list';
import { PaginatedEventCategoryList } from '../models/paginated-event-category-list';
import { PaginatedEventList } from '../models/paginated-event-list';
import { PaginatedExtraLeaveList } from '../models/paginated-extra-leave-list';
import { PaginatedGalleryList } from '../models/paginated-gallery-list';
import { PaginatedGalleryMediaList } from '../models/paginated-gallery-media-list';
import { PaginatedGroupList } from '../models/paginated-group-list';
import { PaginatedHolidayList } from '../models/paginated-holiday-list';
import { PaginatedLinkList } from '../models/paginated-link-list';
import { PaginatedMagazineListList } from '../models/paginated-magazine-list-list';
import { PaginatedMediaList } from '../models/paginated-media-list';
import { PaginatedNoticeListRetrieveList } from '../models/paginated-notice-list-retrieve-list';
import { PaginatedOccurrenceList } from '../models/paginated-occurrence-list';
import { PaginatedOfficeList } from '../models/paginated-office-list';
import { PaginatedPressList } from '../models/paginated-press-list';
import { PaginatedRentalList } from '../models/paginated-rental-list';
import { PaginatedRuleList } from '../models/paginated-rule-list';
import { PaginatedSpecialLeaveList } from '../models/paginated-special-leave-list';
import { PaginatedTagList } from '../models/paginated-tag-list';
import { PaginatedUserList } from '../models/paginated-user-list';
import { PaginatedVehicleList } from '../models/paginated-vehicle-list';
import { PatchedAnnouncementRequest } from '../models/patched-announcement-request';
import { PatchedArticleRequest } from '../models/patched-article-request';
import { PatchedDirectoryCreateUpdateRequest } from '../models/patched-directory-create-update-request';
import { PatchedEmployeeRequest } from '../models/patched-employee-request';
import { PatchedEventCreateUpdateRequest } from '../models/patched-event-create-update-request';
import { PatchedGalleryCreateUpdateRequest } from '../models/patched-gallery-create-update-request';
import { PatchedHolidayRequest } from '../models/patched-holiday-request';
import { PatchedLinkRequest } from '../models/patched-link-request';
import { PatchedMagazineRequest } from '../models/patched-magazine-request';
import { PatchedMediaUpdateRequest } from '../models/patched-media-update-request';
import { PatchedNoticeRequest } from '../models/patched-notice-request';
import { PatchedOfficeRequest } from '../models/patched-office-request';
import { PatchedPressRequest } from '../models/patched-press-request';
import { PatchedRentalCreateUpdateRequest } from '../models/patched-rental-create-update-request';
import { PatchedUserUpdateRequest } from '../models/patched-user-update-request';
import { PatchedVehicleRequest } from '../models/patched-vehicle-request';
import { Press } from '../models/press';
import { PressRequest } from '../models/press-request';
import { Rental } from '../models/rental';
import { RentalCreateUpdate } from '../models/rental-create-update';
import { RentalCreateUpdateRequest } from '../models/rental-create-update-request';
import { Rule } from '../models/rule';
import { SpecialLeave } from '../models/special-leave';
import { SpecialLeaveRequest } from '../models/special-leave-request';
import { Tag } from '../models/tag';
import { TagRequest } from '../models/tag-request';
import { TagPatch } from '../models/tag-patch';
import { TravelOrder } from '../models/travel-order';
import { TravelOrderRequest } from '../models/travel-order-request';
import { User } from '../models/user';
import { UserUpdate } from '../models/user-update';
import { Vehicle } from '../models/vehicle';
import { VehicleRequest } from '../models/vehicle-request';

@Injectable({
  providedIn: 'root',
})
export class V1Service extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation v1CalendarCategoriesList
   */
  static readonly V1CalendarCategoriesListPath = '/api/v1/calendar/categories/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CalendarCategoriesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarCategoriesList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
    type?: 'conference_room_availability' | 'general';
  }): Observable<StrictHttpResponse<PaginatedEventCategoryList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CalendarCategoriesListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedEventCategoryList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CalendarCategoriesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarCategoriesList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
    type?: 'conference_room_availability' | 'general';
  }): Observable<PaginatedEventCategoryList> {

    return this.v1CalendarCategoriesList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedEventCategoryList>) => r.body as PaginatedEventCategoryList)
    );
  }

  /**
   * Path part for operation v1CalendarCategoriesRetrieve
   */
  static readonly V1CalendarCategoriesRetrievePath = '/api/v1/calendar/categories/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CalendarCategoriesRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarCategoriesRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Event Category.
     */
    id: number;
  }): Observable<StrictHttpResponse<EventCategory>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CalendarCategoriesRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventCategory>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CalendarCategoriesRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarCategoriesRetrieve(params: {

    /**
     * A unique integer value identifying this Event Category.
     */
    id: number;
  }): Observable<EventCategory> {

    return this.v1CalendarCategoriesRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<EventCategory>) => r.body as EventCategory)
    );
  }

  /**
   * Path part for operation v1CalendarConferenceRoomsList
   */
  static readonly V1CalendarConferenceRoomsListPath = '/api/v1/calendar/conference-rooms/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CalendarConferenceRoomsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarConferenceRoomsList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedConferenceRoomList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CalendarConferenceRoomsListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedConferenceRoomList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CalendarConferenceRoomsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarConferenceRoomsList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedConferenceRoomList> {

    return this.v1CalendarConferenceRoomsList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedConferenceRoomList>) => r.body as PaginatedConferenceRoomList)
    );
  }

  /**
   * Path part for operation v1CalendarConferenceRoomsRetrieve
   */
  static readonly V1CalendarConferenceRoomsRetrievePath = '/api/v1/calendar/conference-rooms/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CalendarConferenceRoomsRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarConferenceRoomsRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Conference room.
     */
    id: number;
  }): Observable<StrictHttpResponse<ConferenceRoom>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CalendarConferenceRoomsRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConferenceRoom>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CalendarConferenceRoomsRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarConferenceRoomsRetrieve(params: {

    /**
     * A unique integer value identifying this Conference room.
     */
    id: number;
  }): Observable<ConferenceRoom> {

    return this.v1CalendarConferenceRoomsRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<ConferenceRoom>) => r.body as ConferenceRoom)
    );
  }

  /**
   * Path part for operation v1CalendarEventsList
   */
  static readonly V1CalendarEventsListPath = '/api/v1/calendar/events/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CalendarEventsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarEventsList$Response(params?: {

    /**
     * Multiple values may be separated by commas.
     */
    category__in?: Array<number>;

    /**
     * Multiple values may be separated by commas.
     */
    category__not_in?: Array<number>;

    /**
     * Multiple values may be separated by commas.
     */
    category__type__in?: Array<'conference_room_availability' | 'general'>;

    /**
     * Multiple values may be separated by commas.
     */
    category__type__not_in?: Array<'conference_room_availability' | 'general'>;

    /**
     * Multiple values may be separated by commas.
     */
    conference_room__in?: Array<number>;

    /**
     * Multiple values may be separated by commas.
     */
    conference_room__not_in?: Array<number>;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * Occurrences greater or equal
     */
    occurrences__gte?: string;

    /**
     * Occurrences less or equal
     */
    occurrences__lte?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedEventList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CalendarEventsListPath, 'get');
    if (params) {
      rb.query('category__in', params.category__in, {"style":"form","explode":false});
      rb.query('category__not_in', params.category__not_in, {"style":"form","explode":false});
      rb.query('category__type__in', params.category__type__in, {"style":"form","explode":false});
      rb.query('category__type__not_in', params.category__type__not_in, {"style":"form","explode":false});
      rb.query('conference_room__in', params.conference_room__in, {"style":"form","explode":false});
      rb.query('conference_room__not_in', params.conference_room__not_in, {"style":"form","explode":false});
      rb.query('limit', params.limit, {});
      rb.query('occurrences__gte', params.occurrences__gte, {});
      rb.query('occurrences__lte', params.occurrences__lte, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedEventList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CalendarEventsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarEventsList(params?: {

    /**
     * Multiple values may be separated by commas.
     */
    category__in?: Array<number>;

    /**
     * Multiple values may be separated by commas.
     */
    category__not_in?: Array<number>;

    /**
     * Multiple values may be separated by commas.
     */
    category__type__in?: Array<'conference_room_availability' | 'general'>;

    /**
     * Multiple values may be separated by commas.
     */
    category__type__not_in?: Array<'conference_room_availability' | 'general'>;

    /**
     * Multiple values may be separated by commas.
     */
    conference_room__in?: Array<number>;

    /**
     * Multiple values may be separated by commas.
     */
    conference_room__not_in?: Array<number>;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * Occurrences greater or equal
     */
    occurrences__gte?: string;

    /**
     * Occurrences less or equal
     */
    occurrences__lte?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedEventList> {

    return this.v1CalendarEventsList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedEventList>) => r.body as PaginatedEventList)
    );
  }

  /**
   * Path part for operation v1CalendarEventsCreate
   */
  static readonly V1CalendarEventsCreatePath = '/api/v1/calendar/events/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CalendarEventsCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CalendarEventsCreate$Json$Response(params: {
    body: EventCreateUpdateRequest
  }): Observable<StrictHttpResponse<EventCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CalendarEventsCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CalendarEventsCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CalendarEventsCreate$Json(params: {
    body: EventCreateUpdateRequest
  }): Observable<EventCreateUpdate> {

    return this.v1CalendarEventsCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventCreateUpdate>) => r.body as EventCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CalendarEventsCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CalendarEventsCreate$XWwwFormUrlencoded$Response(params: {
    body: EventCreateUpdateRequest
  }): Observable<StrictHttpResponse<EventCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CalendarEventsCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CalendarEventsCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CalendarEventsCreate$XWwwFormUrlencoded(params: {
    body: EventCreateUpdateRequest
  }): Observable<EventCreateUpdate> {

    return this.v1CalendarEventsCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<EventCreateUpdate>) => r.body as EventCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CalendarEventsCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CalendarEventsCreate$FormData$Response(params: {
    body: EventCreateUpdateRequest
  }): Observable<StrictHttpResponse<EventCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CalendarEventsCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CalendarEventsCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CalendarEventsCreate$FormData(params: {
    body: EventCreateUpdateRequest
  }): Observable<EventCreateUpdate> {

    return this.v1CalendarEventsCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<EventCreateUpdate>) => r.body as EventCreateUpdate)
    );
  }

  /**
   * Path part for operation v1CalendarEventsRetrieve
   */
  static readonly V1CalendarEventsRetrievePath = '/api/v1/calendar/events/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CalendarEventsRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarEventsRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Event.
     */
    id: number;
  }): Observable<StrictHttpResponse<Event>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CalendarEventsRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Event>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CalendarEventsRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarEventsRetrieve(params: {

    /**
     * A unique integer value identifying this Event.
     */
    id: number;
  }): Observable<Event> {

    return this.v1CalendarEventsRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Event>) => r.body as Event)
    );
  }

  /**
   * Path part for operation v1CalendarEventsUpdate
   */
  static readonly V1CalendarEventsUpdatePath = '/api/v1/calendar/events/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CalendarEventsUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CalendarEventsUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Event.
     */
    id: number;
    body: EventCreateUpdateRequest
  }): Observable<StrictHttpResponse<EventCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CalendarEventsUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CalendarEventsUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CalendarEventsUpdate$Json(params: {

    /**
     * A unique integer value identifying this Event.
     */
    id: number;
    body: EventCreateUpdateRequest
  }): Observable<EventCreateUpdate> {

    return this.v1CalendarEventsUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventCreateUpdate>) => r.body as EventCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CalendarEventsUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CalendarEventsUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Event.
     */
    id: number;
    body: EventCreateUpdateRequest
  }): Observable<StrictHttpResponse<EventCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CalendarEventsUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CalendarEventsUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CalendarEventsUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Event.
     */
    id: number;
    body: EventCreateUpdateRequest
  }): Observable<EventCreateUpdate> {

    return this.v1CalendarEventsUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<EventCreateUpdate>) => r.body as EventCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CalendarEventsUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CalendarEventsUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Event.
     */
    id: number;
    body: EventCreateUpdateRequest
  }): Observable<StrictHttpResponse<EventCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CalendarEventsUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CalendarEventsUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CalendarEventsUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Event.
     */
    id: number;
    body: EventCreateUpdateRequest
  }): Observable<EventCreateUpdate> {

    return this.v1CalendarEventsUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<EventCreateUpdate>) => r.body as EventCreateUpdate)
    );
  }

  /**
   * Path part for operation v1CalendarEventsDestroy
   */
  static readonly V1CalendarEventsDestroyPath = '/api/v1/calendar/events/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CalendarEventsDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarEventsDestroy$Response(params: {

    /**
     * A unique integer value identifying this Event.
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CalendarEventsDestroyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CalendarEventsDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarEventsDestroy(params: {

    /**
     * A unique integer value identifying this Event.
     */
    id: number;
  }): Observable<void> {

    return this.v1CalendarEventsDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v1CalendarEventsPartialUpdate
   */
  static readonly V1CalendarEventsPartialUpdatePath = '/api/v1/calendar/events/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CalendarEventsPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CalendarEventsPartialUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Event.
     */
    id: number;
    body?: PatchedEventCreateUpdateRequest
  }): Observable<StrictHttpResponse<EventCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CalendarEventsPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CalendarEventsPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CalendarEventsPartialUpdate$Json(params: {

    /**
     * A unique integer value identifying this Event.
     */
    id: number;
    body?: PatchedEventCreateUpdateRequest
  }): Observable<EventCreateUpdate> {

    return this.v1CalendarEventsPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EventCreateUpdate>) => r.body as EventCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CalendarEventsPartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CalendarEventsPartialUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Event.
     */
    id: number;
    body?: PatchedEventCreateUpdateRequest
  }): Observable<StrictHttpResponse<EventCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CalendarEventsPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CalendarEventsPartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CalendarEventsPartialUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Event.
     */
    id: number;
    body?: PatchedEventCreateUpdateRequest
  }): Observable<EventCreateUpdate> {

    return this.v1CalendarEventsPartialUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<EventCreateUpdate>) => r.body as EventCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CalendarEventsPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CalendarEventsPartialUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Event.
     */
    id: number;
    body?: PatchedEventCreateUpdateRequest
  }): Observable<StrictHttpResponse<EventCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CalendarEventsPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CalendarEventsPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CalendarEventsPartialUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Event.
     */
    id: number;
    body?: PatchedEventCreateUpdateRequest
  }): Observable<EventCreateUpdate> {

    return this.v1CalendarEventsPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<EventCreateUpdate>) => r.body as EventCreateUpdate)
    );
  }

  /**
   * Path part for operation v1CalendarOccurrencesList
   */
  static readonly V1CalendarOccurrencesListPath = '/api/v1/calendar/occurrences/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CalendarOccurrencesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarOccurrencesList$Response(params?: {
    end_datetime__gte?: string;
    end_datetime__lte?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-created_at' | '-id' | 'created_at' | 'id';
    start_datetime__gte?: string;
    start_datetime__lte?: string;
  }): Observable<StrictHttpResponse<PaginatedOccurrenceList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CalendarOccurrencesListPath, 'get');
    if (params) {
      rb.query('end_datetime__gte', params.end_datetime__gte, {});
      rb.query('end_datetime__lte', params.end_datetime__lte, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('ordering', params.ordering, {});
      rb.query('start_datetime__gte', params.start_datetime__gte, {});
      rb.query('start_datetime__lte', params.start_datetime__lte, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedOccurrenceList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CalendarOccurrencesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarOccurrencesList(params?: {
    end_datetime__gte?: string;
    end_datetime__lte?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-created_at' | '-id' | 'created_at' | 'id';
    start_datetime__gte?: string;
    start_datetime__lte?: string;
  }): Observable<PaginatedOccurrenceList> {

    return this.v1CalendarOccurrencesList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedOccurrenceList>) => r.body as PaginatedOccurrenceList)
    );
  }

  /**
   * Path part for operation v1CalendarOccurrencesRetrieve
   */
  static readonly V1CalendarOccurrencesRetrievePath = '/api/v1/calendar/occurrences/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CalendarOccurrencesRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarOccurrencesRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Event Occurrence.
     */
    id: number;
  }): Observable<StrictHttpResponse<Occurrence>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CalendarOccurrencesRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Occurrence>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CalendarOccurrencesRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarOccurrencesRetrieve(params: {

    /**
     * A unique integer value identifying this Event Occurrence.
     */
    id: number;
  }): Observable<Occurrence> {

    return this.v1CalendarOccurrencesRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Occurrence>) => r.body as Occurrence)
    );
  }

  /**
   * Path part for operation v1CalendarRulesList
   */
  static readonly V1CalendarRulesListPath = '/api/v1/calendar/rules/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CalendarRulesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarRulesList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
    ordering?: '-position' | 'position';
  }): Observable<StrictHttpResponse<PaginatedRuleList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CalendarRulesListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('ordering', params.ordering, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedRuleList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CalendarRulesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarRulesList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
    ordering?: '-position' | 'position';
  }): Observable<PaginatedRuleList> {

    return this.v1CalendarRulesList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedRuleList>) => r.body as PaginatedRuleList)
    );
  }

  /**
   * Path part for operation v1CalendarRulesRetrieve
   */
  static readonly V1CalendarRulesRetrievePath = '/api/v1/calendar/rules/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CalendarRulesRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarRulesRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Rule.
     */
    id: number;
  }): Observable<StrictHttpResponse<Rule>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CalendarRulesRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Rule>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CalendarRulesRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CalendarRulesRetrieve(params: {

    /**
     * A unique integer value identifying this Rule.
     */
    id: number;
  }): Observable<Rule> {

    return this.v1CalendarRulesRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Rule>) => r.body as Rule)
    );
  }

  /**
   * Path part for operation v1CarParkPressList
   */
  static readonly V1CarParkPressListPath = '/api/v1/car-park/press/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkPressList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CarParkPressList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-agency' | '-id' | '-name' | 'agency' | 'id' | 'name';
  }): Observable<StrictHttpResponse<PaginatedPressList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkPressListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('ordering', params.ordering, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedPressList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkPressList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CarParkPressList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-agency' | '-id' | '-name' | 'agency' | 'id' | 'name';
  }): Observable<PaginatedPressList> {

    return this.v1CarParkPressList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedPressList>) => r.body as PaginatedPressList)
    );
  }

  /**
   * Path part for operation v1CarParkPressCreate
   */
  static readonly V1CarParkPressCreatePath = '/api/v1/car-park/press/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkPressCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CarParkPressCreate$Json$Response(params: {
    body: PressRequest
  }): Observable<StrictHttpResponse<Press>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkPressCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Press>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkPressCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CarParkPressCreate$Json(params: {
    body: PressRequest
  }): Observable<Press> {

    return this.v1CarParkPressCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Press>) => r.body as Press)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkPressCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CarParkPressCreate$XWwwFormUrlencoded$Response(params: {
    body: PressRequest
  }): Observable<StrictHttpResponse<Press>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkPressCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Press>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkPressCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CarParkPressCreate$XWwwFormUrlencoded(params: {
    body: PressRequest
  }): Observable<Press> {

    return this.v1CarParkPressCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Press>) => r.body as Press)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkPressCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CarParkPressCreate$FormData$Response(params: {
    body: PressRequest
  }): Observable<StrictHttpResponse<Press>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkPressCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Press>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkPressCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CarParkPressCreate$FormData(params: {
    body: PressRequest
  }): Observable<Press> {

    return this.v1CarParkPressCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Press>) => r.body as Press)
    );
  }

  /**
   * Path part for operation v1CarParkPressRetrieve
   */
  static readonly V1CarParkPressRetrievePath = '/api/v1/car-park/press/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkPressRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CarParkPressRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Press.
     */
    id: number;
  }): Observable<StrictHttpResponse<Press>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkPressRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Press>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkPressRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CarParkPressRetrieve(params: {

    /**
     * A unique integer value identifying this Press.
     */
    id: number;
  }): Observable<Press> {

    return this.v1CarParkPressRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Press>) => r.body as Press)
    );
  }

  /**
   * Path part for operation v1CarParkPressUpdate
   */
  static readonly V1CarParkPressUpdatePath = '/api/v1/car-park/press/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkPressUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CarParkPressUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Press.
     */
    id: number;
    body: PressRequest
  }): Observable<StrictHttpResponse<Press>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkPressUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Press>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkPressUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CarParkPressUpdate$Json(params: {

    /**
     * A unique integer value identifying this Press.
     */
    id: number;
    body: PressRequest
  }): Observable<Press> {

    return this.v1CarParkPressUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Press>) => r.body as Press)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkPressUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CarParkPressUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Press.
     */
    id: number;
    body: PressRequest
  }): Observable<StrictHttpResponse<Press>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkPressUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Press>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkPressUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CarParkPressUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Press.
     */
    id: number;
    body: PressRequest
  }): Observable<Press> {

    return this.v1CarParkPressUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Press>) => r.body as Press)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkPressUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CarParkPressUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Press.
     */
    id: number;
    body: PressRequest
  }): Observable<StrictHttpResponse<Press>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkPressUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Press>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkPressUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CarParkPressUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Press.
     */
    id: number;
    body: PressRequest
  }): Observable<Press> {

    return this.v1CarParkPressUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Press>) => r.body as Press)
    );
  }

  /**
   * Path part for operation v1CarParkPressDestroy
   */
  static readonly V1CarParkPressDestroyPath = '/api/v1/car-park/press/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkPressDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CarParkPressDestroy$Response(params: {

    /**
     * A unique integer value identifying this Press.
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkPressDestroyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkPressDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CarParkPressDestroy(params: {

    /**
     * A unique integer value identifying this Press.
     */
    id: number;
  }): Observable<void> {

    return this.v1CarParkPressDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v1CarParkPressPartialUpdate
   */
  static readonly V1CarParkPressPartialUpdatePath = '/api/v1/car-park/press/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkPressPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CarParkPressPartialUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Press.
     */
    id: number;
    body?: PatchedPressRequest
  }): Observable<StrictHttpResponse<Press>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkPressPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Press>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkPressPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CarParkPressPartialUpdate$Json(params: {

    /**
     * A unique integer value identifying this Press.
     */
    id: number;
    body?: PatchedPressRequest
  }): Observable<Press> {

    return this.v1CarParkPressPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Press>) => r.body as Press)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkPressPartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CarParkPressPartialUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Press.
     */
    id: number;
    body?: PatchedPressRequest
  }): Observable<StrictHttpResponse<Press>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkPressPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Press>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkPressPartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CarParkPressPartialUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Press.
     */
    id: number;
    body?: PatchedPressRequest
  }): Observable<Press> {

    return this.v1CarParkPressPartialUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Press>) => r.body as Press)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkPressPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CarParkPressPartialUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Press.
     */
    id: number;
    body?: PatchedPressRequest
  }): Observable<StrictHttpResponse<Press>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkPressPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Press>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkPressPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CarParkPressPartialUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Press.
     */
    id: number;
    body?: PatchedPressRequest
  }): Observable<Press> {

    return this.v1CarParkPressPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Press>) => r.body as Press)
    );
  }

  /**
   * Path part for operation v1CarParkRentalsList
   */
  static readonly V1CarParkRentalsListPath = '/api/v1/car-park/rentals/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkRentalsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CarParkRentalsList$Response(params?: {
    end_date?: string;
    end_date__gte?: string;
    end_date__lte?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-created_at' | '-end_date' | '-id' | '-start_date' | '-updated_at' | '-vehicle__brand' | '-vehicle__model' | '-vehicle__vin' | 'created_at' | 'end_date' | 'id' | 'start_date' | 'updated_at' | 'vehicle__brand' | 'vehicle__model' | 'vehicle__vin';
    press?: number;

    /**
     * A search term. Searches by: (&#x27;vehicle__vin&#x27;,)
     */
    search?: string;
    start_date?: string;
    start_date__gte?: string;
    start_date__lte?: string;
    vehicle?: number;
  }): Observable<StrictHttpResponse<PaginatedRentalList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkRentalsListPath, 'get');
    if (params) {
      rb.query('end_date', params.end_date, {});
      rb.query('end_date__gte', params.end_date__gte, {});
      rb.query('end_date__lte', params.end_date__lte, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('ordering', params.ordering, {});
      rb.query('press', params.press, {});
      rb.query('search', params.search, {});
      rb.query('start_date', params.start_date, {});
      rb.query('start_date__gte', params.start_date__gte, {});
      rb.query('start_date__lte', params.start_date__lte, {});
      rb.query('vehicle', params.vehicle, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedRentalList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkRentalsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CarParkRentalsList(params?: {
    end_date?: string;
    end_date__gte?: string;
    end_date__lte?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-created_at' | '-end_date' | '-id' | '-start_date' | '-updated_at' | '-vehicle__brand' | '-vehicle__model' | '-vehicle__vin' | 'created_at' | 'end_date' | 'id' | 'start_date' | 'updated_at' | 'vehicle__brand' | 'vehicle__model' | 'vehicle__vin';
    press?: number;

    /**
     * A search term. Searches by: (&#x27;vehicle__vin&#x27;,)
     */
    search?: string;
    start_date?: string;
    start_date__gte?: string;
    start_date__lte?: string;
    vehicle?: number;
  }): Observable<PaginatedRentalList> {

    return this.v1CarParkRentalsList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedRentalList>) => r.body as PaginatedRentalList)
    );
  }

  /**
   * Path part for operation v1CarParkRentalsCreate
   */
  static readonly V1CarParkRentalsCreatePath = '/api/v1/car-park/rentals/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkRentalsCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CarParkRentalsCreate$Json$Response(params: {
    body: RentalCreateUpdateRequest
  }): Observable<StrictHttpResponse<RentalCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkRentalsCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RentalCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkRentalsCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CarParkRentalsCreate$Json(params: {
    body: RentalCreateUpdateRequest
  }): Observable<RentalCreateUpdate> {

    return this.v1CarParkRentalsCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RentalCreateUpdate>) => r.body as RentalCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkRentalsCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CarParkRentalsCreate$XWwwFormUrlencoded$Response(params: {
    body: RentalCreateUpdateRequest
  }): Observable<StrictHttpResponse<RentalCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkRentalsCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RentalCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkRentalsCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CarParkRentalsCreate$XWwwFormUrlencoded(params: {
    body: RentalCreateUpdateRequest
  }): Observable<RentalCreateUpdate> {

    return this.v1CarParkRentalsCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<RentalCreateUpdate>) => r.body as RentalCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkRentalsCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CarParkRentalsCreate$FormData$Response(params: {
    body: RentalCreateUpdateRequest
  }): Observable<StrictHttpResponse<RentalCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkRentalsCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RentalCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkRentalsCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CarParkRentalsCreate$FormData(params: {
    body: RentalCreateUpdateRequest
  }): Observable<RentalCreateUpdate> {

    return this.v1CarParkRentalsCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<RentalCreateUpdate>) => r.body as RentalCreateUpdate)
    );
  }

  /**
   * Path part for operation v1CarParkRentalsRetrieve
   */
  static readonly V1CarParkRentalsRetrievePath = '/api/v1/car-park/rentals/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkRentalsRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CarParkRentalsRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Rental.
     */
    id: number;
  }): Observable<StrictHttpResponse<Rental>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkRentalsRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Rental>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkRentalsRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CarParkRentalsRetrieve(params: {

    /**
     * A unique integer value identifying this Rental.
     */
    id: number;
  }): Observable<Rental> {

    return this.v1CarParkRentalsRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Rental>) => r.body as Rental)
    );
  }

  /**
   * Path part for operation v1CarParkRentalsUpdate
   */
  static readonly V1CarParkRentalsUpdatePath = '/api/v1/car-park/rentals/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkRentalsUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CarParkRentalsUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Rental.
     */
    id: number;
    body: RentalCreateUpdateRequest
  }): Observable<StrictHttpResponse<RentalCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkRentalsUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RentalCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkRentalsUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CarParkRentalsUpdate$Json(params: {

    /**
     * A unique integer value identifying this Rental.
     */
    id: number;
    body: RentalCreateUpdateRequest
  }): Observable<RentalCreateUpdate> {

    return this.v1CarParkRentalsUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RentalCreateUpdate>) => r.body as RentalCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkRentalsUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CarParkRentalsUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Rental.
     */
    id: number;
    body: RentalCreateUpdateRequest
  }): Observable<StrictHttpResponse<RentalCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkRentalsUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RentalCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkRentalsUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CarParkRentalsUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Rental.
     */
    id: number;
    body: RentalCreateUpdateRequest
  }): Observable<RentalCreateUpdate> {

    return this.v1CarParkRentalsUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<RentalCreateUpdate>) => r.body as RentalCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkRentalsUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CarParkRentalsUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Rental.
     */
    id: number;
    body: RentalCreateUpdateRequest
  }): Observable<StrictHttpResponse<RentalCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkRentalsUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RentalCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkRentalsUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CarParkRentalsUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Rental.
     */
    id: number;
    body: RentalCreateUpdateRequest
  }): Observable<RentalCreateUpdate> {

    return this.v1CarParkRentalsUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<RentalCreateUpdate>) => r.body as RentalCreateUpdate)
    );
  }

  /**
   * Path part for operation v1CarParkRentalsDestroy
   */
  static readonly V1CarParkRentalsDestroyPath = '/api/v1/car-park/rentals/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkRentalsDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CarParkRentalsDestroy$Response(params: {

    /**
     * A unique integer value identifying this Rental.
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkRentalsDestroyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkRentalsDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CarParkRentalsDestroy(params: {

    /**
     * A unique integer value identifying this Rental.
     */
    id: number;
  }): Observable<void> {

    return this.v1CarParkRentalsDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v1CarParkRentalsPartialUpdate
   */
  static readonly V1CarParkRentalsPartialUpdatePath = '/api/v1/car-park/rentals/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkRentalsPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CarParkRentalsPartialUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Rental.
     */
    id: number;
    body?: PatchedRentalCreateUpdateRequest
  }): Observable<StrictHttpResponse<RentalCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkRentalsPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RentalCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkRentalsPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CarParkRentalsPartialUpdate$Json(params: {

    /**
     * A unique integer value identifying this Rental.
     */
    id: number;
    body?: PatchedRentalCreateUpdateRequest
  }): Observable<RentalCreateUpdate> {

    return this.v1CarParkRentalsPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RentalCreateUpdate>) => r.body as RentalCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkRentalsPartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CarParkRentalsPartialUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Rental.
     */
    id: number;
    body?: PatchedRentalCreateUpdateRequest
  }): Observable<StrictHttpResponse<RentalCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkRentalsPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RentalCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkRentalsPartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CarParkRentalsPartialUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Rental.
     */
    id: number;
    body?: PatchedRentalCreateUpdateRequest
  }): Observable<RentalCreateUpdate> {

    return this.v1CarParkRentalsPartialUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<RentalCreateUpdate>) => r.body as RentalCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkRentalsPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CarParkRentalsPartialUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Rental.
     */
    id: number;
    body?: PatchedRentalCreateUpdateRequest
  }): Observable<StrictHttpResponse<RentalCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkRentalsPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RentalCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkRentalsPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CarParkRentalsPartialUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Rental.
     */
    id: number;
    body?: PatchedRentalCreateUpdateRequest
  }): Observable<RentalCreateUpdate> {

    return this.v1CarParkRentalsPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<RentalCreateUpdate>) => r.body as RentalCreateUpdate)
    );
  }

  /**
   * Path part for operation v1CarParkTravelOrdersCreate
   */
  static readonly V1CarParkTravelOrdersCreatePath = '/api/v1/car-park/travel-orders/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkTravelOrdersCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CarParkTravelOrdersCreate$Json$Response(params: {
    body: TravelOrderRequest
  }): Observable<StrictHttpResponse<TravelOrder>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkTravelOrdersCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TravelOrder>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkTravelOrdersCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CarParkTravelOrdersCreate$Json(params: {
    body: TravelOrderRequest
  }): Observable<TravelOrder> {

    return this.v1CarParkTravelOrdersCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TravelOrder>) => r.body as TravelOrder)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkTravelOrdersCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CarParkTravelOrdersCreate$XWwwFormUrlencoded$Response(params: {
    body: TravelOrderRequest
  }): Observable<StrictHttpResponse<TravelOrder>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkTravelOrdersCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TravelOrder>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkTravelOrdersCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CarParkTravelOrdersCreate$XWwwFormUrlencoded(params: {
    body: TravelOrderRequest
  }): Observable<TravelOrder> {

    return this.v1CarParkTravelOrdersCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<TravelOrder>) => r.body as TravelOrder)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkTravelOrdersCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CarParkTravelOrdersCreate$FormData$Response(params: {
    body: TravelOrderRequest
  }): Observable<StrictHttpResponse<TravelOrder>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkTravelOrdersCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TravelOrder>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkTravelOrdersCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CarParkTravelOrdersCreate$FormData(params: {
    body: TravelOrderRequest
  }): Observable<TravelOrder> {

    return this.v1CarParkTravelOrdersCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<TravelOrder>) => r.body as TravelOrder)
    );
  }

  /**
   * Path part for operation v1CarParkVehiclesList
   */
  static readonly V1CarParkVehiclesListPath = '/api/v1/car-park/vehicles/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkVehiclesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CarParkVehiclesList$Response(params?: {
    is_active?: boolean;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-brand' | '-created_at' | '-id' | '-model' | '-updated_at' | '-vin' | 'brand' | 'created_at' | 'id' | 'model' | 'updated_at' | 'vin';

    /**
     * A search term.
     */
    search?: string;
  }): Observable<StrictHttpResponse<PaginatedVehicleList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkVehiclesListPath, 'get');
    if (params) {
      rb.query('is_active', params.is_active, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('ordering', params.ordering, {});
      rb.query('search', params.search, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedVehicleList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkVehiclesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CarParkVehiclesList(params?: {
    is_active?: boolean;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-brand' | '-created_at' | '-id' | '-model' | '-updated_at' | '-vin' | 'brand' | 'created_at' | 'id' | 'model' | 'updated_at' | 'vin';

    /**
     * A search term.
     */
    search?: string;
  }): Observable<PaginatedVehicleList> {

    return this.v1CarParkVehiclesList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedVehicleList>) => r.body as PaginatedVehicleList)
    );
  }

  /**
   * Path part for operation v1CarParkVehiclesCreate
   */
  static readonly V1CarParkVehiclesCreatePath = '/api/v1/car-park/vehicles/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkVehiclesCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CarParkVehiclesCreate$Json$Response(params: {
    body: VehicleRequest
  }): Observable<StrictHttpResponse<Vehicle>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkVehiclesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Vehicle>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkVehiclesCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CarParkVehiclesCreate$Json(params: {
    body: VehicleRequest
  }): Observable<Vehicle> {

    return this.v1CarParkVehiclesCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Vehicle>) => r.body as Vehicle)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkVehiclesCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CarParkVehiclesCreate$XWwwFormUrlencoded$Response(params: {
    body: VehicleRequest
  }): Observable<StrictHttpResponse<Vehicle>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkVehiclesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Vehicle>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkVehiclesCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CarParkVehiclesCreate$XWwwFormUrlencoded(params: {
    body: VehicleRequest
  }): Observable<Vehicle> {

    return this.v1CarParkVehiclesCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Vehicle>) => r.body as Vehicle)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkVehiclesCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CarParkVehiclesCreate$FormData$Response(params: {
    body: VehicleRequest
  }): Observable<StrictHttpResponse<Vehicle>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkVehiclesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Vehicle>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkVehiclesCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CarParkVehiclesCreate$FormData(params: {
    body: VehicleRequest
  }): Observable<Vehicle> {

    return this.v1CarParkVehiclesCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Vehicle>) => r.body as Vehicle)
    );
  }

  /**
   * Path part for operation v1CarParkVehiclesRetrieve
   */
  static readonly V1CarParkVehiclesRetrievePath = '/api/v1/car-park/vehicles/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkVehiclesRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CarParkVehiclesRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Vehicle.
     */
    id: number;
  }): Observable<StrictHttpResponse<Vehicle>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkVehiclesRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Vehicle>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkVehiclesRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CarParkVehiclesRetrieve(params: {

    /**
     * A unique integer value identifying this Vehicle.
     */
    id: number;
  }): Observable<Vehicle> {

    return this.v1CarParkVehiclesRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Vehicle>) => r.body as Vehicle)
    );
  }

  /**
   * Path part for operation v1CarParkVehiclesUpdate
   */
  static readonly V1CarParkVehiclesUpdatePath = '/api/v1/car-park/vehicles/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkVehiclesUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CarParkVehiclesUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Vehicle.
     */
    id: number;
    body: VehicleRequest
  }): Observable<StrictHttpResponse<Vehicle>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkVehiclesUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Vehicle>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkVehiclesUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CarParkVehiclesUpdate$Json(params: {

    /**
     * A unique integer value identifying this Vehicle.
     */
    id: number;
    body: VehicleRequest
  }): Observable<Vehicle> {

    return this.v1CarParkVehiclesUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Vehicle>) => r.body as Vehicle)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkVehiclesUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CarParkVehiclesUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Vehicle.
     */
    id: number;
    body: VehicleRequest
  }): Observable<StrictHttpResponse<Vehicle>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkVehiclesUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Vehicle>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkVehiclesUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CarParkVehiclesUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Vehicle.
     */
    id: number;
    body: VehicleRequest
  }): Observable<Vehicle> {

    return this.v1CarParkVehiclesUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Vehicle>) => r.body as Vehicle)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkVehiclesUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CarParkVehiclesUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Vehicle.
     */
    id: number;
    body: VehicleRequest
  }): Observable<StrictHttpResponse<Vehicle>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkVehiclesUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Vehicle>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkVehiclesUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CarParkVehiclesUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Vehicle.
     */
    id: number;
    body: VehicleRequest
  }): Observable<Vehicle> {

    return this.v1CarParkVehiclesUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Vehicle>) => r.body as Vehicle)
    );
  }

  /**
   * Path part for operation v1CarParkVehiclesDestroy
   */
  static readonly V1CarParkVehiclesDestroyPath = '/api/v1/car-park/vehicles/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkVehiclesDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CarParkVehiclesDestroy$Response(params: {

    /**
     * A unique integer value identifying this Vehicle.
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkVehiclesDestroyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkVehiclesDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CarParkVehiclesDestroy(params: {

    /**
     * A unique integer value identifying this Vehicle.
     */
    id: number;
  }): Observable<void> {

    return this.v1CarParkVehiclesDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v1CarParkVehiclesPartialUpdate
   */
  static readonly V1CarParkVehiclesPartialUpdatePath = '/api/v1/car-park/vehicles/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkVehiclesPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CarParkVehiclesPartialUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Vehicle.
     */
    id: number;
    body?: PatchedVehicleRequest
  }): Observable<StrictHttpResponse<Vehicle>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkVehiclesPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Vehicle>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkVehiclesPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CarParkVehiclesPartialUpdate$Json(params: {

    /**
     * A unique integer value identifying this Vehicle.
     */
    id: number;
    body?: PatchedVehicleRequest
  }): Observable<Vehicle> {

    return this.v1CarParkVehiclesPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Vehicle>) => r.body as Vehicle)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkVehiclesPartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CarParkVehiclesPartialUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Vehicle.
     */
    id: number;
    body?: PatchedVehicleRequest
  }): Observable<StrictHttpResponse<Vehicle>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkVehiclesPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Vehicle>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkVehiclesPartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CarParkVehiclesPartialUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Vehicle.
     */
    id: number;
    body?: PatchedVehicleRequest
  }): Observable<Vehicle> {

    return this.v1CarParkVehiclesPartialUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Vehicle>) => r.body as Vehicle)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CarParkVehiclesPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CarParkVehiclesPartialUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Vehicle.
     */
    id: number;
    body?: PatchedVehicleRequest
  }): Observable<StrictHttpResponse<Vehicle>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CarParkVehiclesPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Vehicle>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CarParkVehiclesPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CarParkVehiclesPartialUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Vehicle.
     */
    id: number;
    body?: PatchedVehicleRequest
  }): Observable<Vehicle> {

    return this.v1CarParkVehiclesPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Vehicle>) => r.body as Vehicle)
    );
  }

  /**
   * Path part for operation v1CommonMediaList
   */
  static readonly V1CommonMediaListPath = '/api/v1/common/media/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CommonMediaList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CommonMediaList$Response(params?: {

    /**
     * Filters media to only include those that are included in at least one directory
     */
    included_in_directory?: boolean;

    /**
     * Number of results to return per page.
     */
    limit?: number;
    media_type?: 'audio' | 'doc' | 'image' | 'other' | 'video';

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-created_at' | '-id' | 'created_at' | 'id';

    /**
     * A search term.
     */
    search?: string;
  }): Observable<StrictHttpResponse<PaginatedMediaList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CommonMediaListPath, 'get');
    if (params) {
      rb.query('included_in_directory', params.included_in_directory, {});
      rb.query('limit', params.limit, {});
      rb.query('media_type', params.media_type, {});
      rb.query('offset', params.offset, {});
      rb.query('ordering', params.ordering, {});
      rb.query('search', params.search, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedMediaList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CommonMediaList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CommonMediaList(params?: {

    /**
     * Filters media to only include those that are included in at least one directory
     */
    included_in_directory?: boolean;

    /**
     * Number of results to return per page.
     */
    limit?: number;
    media_type?: 'audio' | 'doc' | 'image' | 'other' | 'video';

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-created_at' | '-id' | 'created_at' | 'id';

    /**
     * A search term.
     */
    search?: string;
  }): Observable<PaginatedMediaList> {

    return this.v1CommonMediaList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedMediaList>) => r.body as PaginatedMediaList)
    );
  }

  /**
   * Path part for operation v1CommonMediaCreate
   */
  static readonly V1CommonMediaCreatePath = '/api/v1/common/media/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CommonMediaCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CommonMediaCreate$Json$Response(params: {
    body: MediaRequest
  }): Observable<StrictHttpResponse<Media>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CommonMediaCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Media>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CommonMediaCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CommonMediaCreate$Json(params: {
    body: MediaRequest
  }): Observable<Media> {

    return this.v1CommonMediaCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Media>) => r.body as Media)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CommonMediaCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CommonMediaCreate$FormData$Response(params: {
    body: MediaRequest
  }): Observable<StrictHttpResponse<Media>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CommonMediaCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Media>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CommonMediaCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CommonMediaCreate$FormData(params: {
    body: MediaRequest
  }): Observable<Media> {

    return this.v1CommonMediaCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Media>) => r.body as Media)
    );
  }

  /**
   * Path part for operation v1CommonMediaRetrieve
   */
  static readonly V1CommonMediaRetrievePath = '/api/v1/common/media/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CommonMediaRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CommonMediaRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Media.
     */
    id: number;
  }): Observable<StrictHttpResponse<Media>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CommonMediaRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Media>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CommonMediaRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CommonMediaRetrieve(params: {

    /**
     * A unique integer value identifying this Media.
     */
    id: number;
  }): Observable<Media> {

    return this.v1CommonMediaRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Media>) => r.body as Media)
    );
  }

  /**
   * Path part for operation v1CommonMediaUpdate
   */
  static readonly V1CommonMediaUpdatePath = '/api/v1/common/media/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CommonMediaUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CommonMediaUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Media.
     */
    id: number;
    body?: MediaUpdateRequest
  }): Observable<StrictHttpResponse<MediaUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CommonMediaUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CommonMediaUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CommonMediaUpdate$Json(params: {

    /**
     * A unique integer value identifying this Media.
     */
    id: number;
    body?: MediaUpdateRequest
  }): Observable<MediaUpdate> {

    return this.v1CommonMediaUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MediaUpdate>) => r.body as MediaUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CommonMediaUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CommonMediaUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Media.
     */
    id: number;
    body?: MediaUpdateRequest
  }): Observable<StrictHttpResponse<MediaUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CommonMediaUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CommonMediaUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CommonMediaUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Media.
     */
    id: number;
    body?: MediaUpdateRequest
  }): Observable<MediaUpdate> {

    return this.v1CommonMediaUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<MediaUpdate>) => r.body as MediaUpdate)
    );
  }

  /**
   * Path part for operation v1CommonMediaDestroy
   */
  static readonly V1CommonMediaDestroyPath = '/api/v1/common/media/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CommonMediaDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CommonMediaDestroy$Response(params: {

    /**
     * A unique integer value identifying this Media.
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CommonMediaDestroyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CommonMediaDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CommonMediaDestroy(params: {

    /**
     * A unique integer value identifying this Media.
     */
    id: number;
  }): Observable<void> {

    return this.v1CommonMediaDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v1CommonMediaPartialUpdate
   */
  static readonly V1CommonMediaPartialUpdatePath = '/api/v1/common/media/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CommonMediaPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CommonMediaPartialUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Media.
     */
    id: number;
    body?: PatchedMediaUpdateRequest
  }): Observable<StrictHttpResponse<MediaUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CommonMediaPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CommonMediaPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CommonMediaPartialUpdate$Json(params: {

    /**
     * A unique integer value identifying this Media.
     */
    id: number;
    body?: PatchedMediaUpdateRequest
  }): Observable<MediaUpdate> {

    return this.v1CommonMediaPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MediaUpdate>) => r.body as MediaUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CommonMediaPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CommonMediaPartialUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Media.
     */
    id: number;
    body?: PatchedMediaUpdateRequest
  }): Observable<StrictHttpResponse<MediaUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CommonMediaPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CommonMediaPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CommonMediaPartialUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Media.
     */
    id: number;
    body?: PatchedMediaUpdateRequest
  }): Observable<MediaUpdate> {

    return this.v1CommonMediaPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<MediaUpdate>) => r.body as MediaUpdate)
    );
  }

  /**
   * Path part for operation v1CommonMediaAllowedFormatsRetrieve
   */
  static readonly V1CommonMediaAllowedFormatsRetrievePath = '/api/v1/common/media/allowed-formats/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CommonMediaAllowedFormatsRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CommonMediaAllowedFormatsRetrieve$Response(params?: {
  }): Observable<StrictHttpResponse<MediaAllowedFormats>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CommonMediaAllowedFormatsRetrievePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaAllowedFormats>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CommonMediaAllowedFormatsRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CommonMediaAllowedFormatsRetrieve(params?: {
  }): Observable<MediaAllowedFormats> {

    return this.v1CommonMediaAllowedFormatsRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<MediaAllowedFormats>) => r.body as MediaAllowedFormats)
    );
  }

  /**
   * Path part for operation v1CommonTagsList
   */
  static readonly V1CommonTagsListPath = '/api/v1/common/tags/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CommonTagsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CommonTagsList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedTagList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CommonTagsListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedTagList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CommonTagsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CommonTagsList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedTagList> {

    return this.v1CommonTagsList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedTagList>) => r.body as PaginatedTagList)
    );
  }

  /**
   * Path part for operation v1CommonTagsCreate
   */
  static readonly V1CommonTagsCreatePath = '/api/v1/common/tags/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CommonTagsCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CommonTagsCreate$Json$Response(params: {
    body: TagRequest
  }): Observable<StrictHttpResponse<Tag>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CommonTagsCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tag>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CommonTagsCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CommonTagsCreate$Json(params: {
    body: TagRequest
  }): Observable<Tag> {

    return this.v1CommonTagsCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Tag>) => r.body as Tag)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CommonTagsCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CommonTagsCreate$XWwwFormUrlencoded$Response(params: {
    body: TagRequest
  }): Observable<StrictHttpResponse<Tag>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CommonTagsCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tag>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CommonTagsCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CommonTagsCreate$XWwwFormUrlencoded(params: {
    body: TagRequest
  }): Observable<Tag> {

    return this.v1CommonTagsCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Tag>) => r.body as Tag)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CommonTagsCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CommonTagsCreate$FormData$Response(params: {
    body: TagRequest
  }): Observable<StrictHttpResponse<Tag>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CommonTagsCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tag>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CommonTagsCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CommonTagsCreate$FormData(params: {
    body: TagRequest
  }): Observable<Tag> {

    return this.v1CommonTagsCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Tag>) => r.body as Tag)
    );
  }

  /**
   * Path part for operation v1CommonTagsRetrieve
   */
  static readonly V1CommonTagsRetrievePath = '/api/v1/common/tags/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CommonTagsRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CommonTagsRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Tag.
     */
    id: number;
  }): Observable<StrictHttpResponse<Tag>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CommonTagsRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tag>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CommonTagsRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CommonTagsRetrieve(params: {

    /**
     * A unique integer value identifying this Tag.
     */
    id: number;
  }): Observable<Tag> {

    return this.v1CommonTagsRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Tag>) => r.body as Tag)
    );
  }

  /**
   * Path part for operation v1CommonTagsDestroy
   */
  static readonly V1CommonTagsDestroyPath = '/api/v1/common/tags/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CommonTagsDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CommonTagsDestroy$Response(params: {

    /**
     * A unique integer value identifying this Tag.
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CommonTagsDestroyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CommonTagsDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CommonTagsDestroy(params: {

    /**
     * A unique integer value identifying this Tag.
     */
    id: number;
  }): Observable<void> {

    return this.v1CommonTagsDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

    /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CommonTagsPatch` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
     v1CommonTagsPatch(params: {

      /**
       * A unique integer value identifying this Tag.
       */
      id: number;
      body?: TagPatch
    }): Observable<StrictHttpResponse<Tag>> {
  
      const rb = new RequestBuilder(this.rootUrl, V1Service.V1CommonTagsDestroyPath, 'patch');
      if (params) {
        rb.path('id', params.id, {});
        rb.body(params.body, 'application/json');
      }
  
      return this.http.request(rb.build({
        responseType: 'json',
        accept: 'application/json'
      })).pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Tag>;
        })
      );
    }

  /**
   * Path part for operation v1CompaniesAnnouncementsList
   */
  static readonly V1CompaniesAnnouncementsListPath = '/api/v1/companies/announcements/';

  /**
   * There can only be one instance of Announcement, so you can only update the existing one
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesAnnouncementsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesAnnouncementsList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedAnnouncementList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesAnnouncementsListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedAnnouncementList>;
      })
    );
  }

  /**
   * There can only be one instance of Announcement, so you can only update the existing one
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesAnnouncementsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesAnnouncementsList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedAnnouncementList> {

    return this.v1CompaniesAnnouncementsList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedAnnouncementList>) => r.body as PaginatedAnnouncementList)
    );
  }

  /**
   * Path part for operation v1CompaniesAnnouncementsRetrieve
   */
  static readonly V1CompaniesAnnouncementsRetrievePath = '/api/v1/companies/announcements/{id}/';

  /**
   * There can only be one instance of Announcement, so you can only update the existing one
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesAnnouncementsRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesAnnouncementsRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Announcement.
     */
    id: number;
  }): Observable<StrictHttpResponse<Announcement>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesAnnouncementsRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Announcement>;
      })
    );
  }

  /**
   * There can only be one instance of Announcement, so you can only update the existing one
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesAnnouncementsRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesAnnouncementsRetrieve(params: {

    /**
     * A unique integer value identifying this Announcement.
     */
    id: number;
  }): Observable<Announcement> {

    return this.v1CompaniesAnnouncementsRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Announcement>) => r.body as Announcement)
    );
  }

  /**
   * Path part for operation v1CompaniesAnnouncementsUpdate
   */
  static readonly V1CompaniesAnnouncementsUpdatePath = '/api/v1/companies/announcements/{id}/';

  /**
   * There can only be one instance of Announcement, so you can only update the existing one
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesAnnouncementsUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesAnnouncementsUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Announcement.
     */
    id: number;
    body: AnnouncementRequest
  }): Observable<StrictHttpResponse<Announcement>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesAnnouncementsUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Announcement>;
      })
    );
  }

  /**
   * There can only be one instance of Announcement, so you can only update the existing one
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesAnnouncementsUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesAnnouncementsUpdate$Json(params: {

    /**
     * A unique integer value identifying this Announcement.
     */
    id: number;
    body: AnnouncementRequest
  }): Observable<Announcement> {

    return this.v1CompaniesAnnouncementsUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Announcement>) => r.body as Announcement)
    );
  }

  /**
   * There can only be one instance of Announcement, so you can only update the existing one
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesAnnouncementsUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesAnnouncementsUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Announcement.
     */
    id: number;
    body: AnnouncementRequest
  }): Observable<StrictHttpResponse<Announcement>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesAnnouncementsUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Announcement>;
      })
    );
  }

  /**
   * There can only be one instance of Announcement, so you can only update the existing one
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesAnnouncementsUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesAnnouncementsUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Announcement.
     */
    id: number;
    body: AnnouncementRequest
  }): Observable<Announcement> {

    return this.v1CompaniesAnnouncementsUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Announcement>) => r.body as Announcement)
    );
  }

  /**
   * There can only be one instance of Announcement, so you can only update the existing one
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesAnnouncementsUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesAnnouncementsUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Announcement.
     */
    id: number;
    body: AnnouncementRequest
  }): Observable<StrictHttpResponse<Announcement>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesAnnouncementsUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Announcement>;
      })
    );
  }

  /**
   * There can only be one instance of Announcement, so you can only update the existing one
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesAnnouncementsUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesAnnouncementsUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Announcement.
     */
    id: number;
    body: AnnouncementRequest
  }): Observable<Announcement> {

    return this.v1CompaniesAnnouncementsUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Announcement>) => r.body as Announcement)
    );
  }

  /**
   * Path part for operation v1CompaniesAnnouncementsPartialUpdate
   */
  static readonly V1CompaniesAnnouncementsPartialUpdatePath = '/api/v1/companies/announcements/{id}/';

  /**
   * There can only be one instance of Announcement, so you can only update the existing one
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesAnnouncementsPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesAnnouncementsPartialUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Announcement.
     */
    id: number;
    body?: PatchedAnnouncementRequest
  }): Observable<StrictHttpResponse<Announcement>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesAnnouncementsPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Announcement>;
      })
    );
  }

  /**
   * There can only be one instance of Announcement, so you can only update the existing one
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesAnnouncementsPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesAnnouncementsPartialUpdate$Json(params: {

    /**
     * A unique integer value identifying this Announcement.
     */
    id: number;
    body?: PatchedAnnouncementRequest
  }): Observable<Announcement> {

    return this.v1CompaniesAnnouncementsPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Announcement>) => r.body as Announcement)
    );
  }

  /**
   * There can only be one instance of Announcement, so you can only update the existing one
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesAnnouncementsPartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesAnnouncementsPartialUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Announcement.
     */
    id: number;
    body?: PatchedAnnouncementRequest
  }): Observable<StrictHttpResponse<Announcement>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesAnnouncementsPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Announcement>;
      })
    );
  }

  /**
   * There can only be one instance of Announcement, so you can only update the existing one
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesAnnouncementsPartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesAnnouncementsPartialUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Announcement.
     */
    id: number;
    body?: PatchedAnnouncementRequest
  }): Observable<Announcement> {

    return this.v1CompaniesAnnouncementsPartialUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Announcement>) => r.body as Announcement)
    );
  }

  /**
   * There can only be one instance of Announcement, so you can only update the existing one
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesAnnouncementsPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesAnnouncementsPartialUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Announcement.
     */
    id: number;
    body?: PatchedAnnouncementRequest
  }): Observable<StrictHttpResponse<Announcement>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesAnnouncementsPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Announcement>;
      })
    );
  }

  /**
   * There can only be one instance of Announcement, so you can only update the existing one
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesAnnouncementsPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesAnnouncementsPartialUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Announcement.
     */
    id: number;
    body?: PatchedAnnouncementRequest
  }): Observable<Announcement> {

    return this.v1CompaniesAnnouncementsPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Announcement>) => r.body as Announcement)
    );
  }

  /**
   * Path part for operation v1CompaniesAnnualLeaveList
   */
  static readonly V1CompaniesAnnualLeaveListPath = '/api/v1/companies/annual-leave/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesAnnualLeaveList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesAnnualLeaveList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedAnnualLeaveList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesAnnualLeaveListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedAnnualLeaveList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesAnnualLeaveList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesAnnualLeaveList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedAnnualLeaveList> {

    return this.v1CompaniesAnnualLeaveList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedAnnualLeaveList>) => r.body as PaginatedAnnualLeaveList)
    );
  }

  /**
   * Path part for operation v1CompaniesAnnualLeaveCreate
   */
  static readonly V1CompaniesAnnualLeaveCreatePath = '/api/v1/companies/annual-leave/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesAnnualLeaveCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesAnnualLeaveCreate$Json$Response(params: {
    body: AnnualLeaveRequest
  }): Observable<StrictHttpResponse<AnnualLeave>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesAnnualLeaveCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnnualLeave>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesAnnualLeaveCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesAnnualLeaveCreate$Json(params: {
    body: AnnualLeaveRequest
  }): Observable<AnnualLeave> {

    return this.v1CompaniesAnnualLeaveCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AnnualLeave>) => r.body as AnnualLeave)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesAnnualLeaveCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesAnnualLeaveCreate$XWwwFormUrlencoded$Response(params: {
    body: AnnualLeaveRequest
  }): Observable<StrictHttpResponse<AnnualLeave>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesAnnualLeaveCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnnualLeave>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesAnnualLeaveCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesAnnualLeaveCreate$XWwwFormUrlencoded(params: {
    body: AnnualLeaveRequest
  }): Observable<AnnualLeave> {

    return this.v1CompaniesAnnualLeaveCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<AnnualLeave>) => r.body as AnnualLeave)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesAnnualLeaveCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesAnnualLeaveCreate$FormData$Response(params: {
    body: AnnualLeaveRequest
  }): Observable<StrictHttpResponse<AnnualLeave>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesAnnualLeaveCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnnualLeave>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesAnnualLeaveCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesAnnualLeaveCreate$FormData(params: {
    body: AnnualLeaveRequest
  }): Observable<AnnualLeave> {

    return this.v1CompaniesAnnualLeaveCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<AnnualLeave>) => r.body as AnnualLeave)
    );
  }

  /**
   * Path part for operation v1CompaniesAnnualLeaveRetrieve
   */
  static readonly V1CompaniesAnnualLeaveRetrievePath = '/api/v1/companies/annual-leave/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesAnnualLeaveRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesAnnualLeaveRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Annual Leave.
     */
    id: number;
  }): Observable<StrictHttpResponse<AnnualLeave>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesAnnualLeaveRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnnualLeave>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesAnnualLeaveRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesAnnualLeaveRetrieve(params: {

    /**
     * A unique integer value identifying this Annual Leave.
     */
    id: number;
  }): Observable<AnnualLeave> {

    return this.v1CompaniesAnnualLeaveRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<AnnualLeave>) => r.body as AnnualLeave)
    );
  }

  /**
   * Path part for operation v1CompaniesEmployeesList
   */
  static readonly V1CompaniesEmployeesListPath = '/api/v1/companies/employees/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesEmployeesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesEmployeesList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * Filter results by PrimaryKey of Office
     */
    office?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-created_at' | '-email' | '-first_name' | '-id' | '-last_name' | 'created_at' | 'email' | 'first_name' | 'id' | 'last_name';

    /**
     * A search term. Searches by: (&#x27;first_name&#x27;, &#x27;last_name&#x27;, &#x27;email&#x27;, &#x27;title&#x27;)
     */
    search?: string;
  }): Observable<StrictHttpResponse<PaginatedEmployeeList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesEmployeesListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('office', params.office, {});
      rb.query('offset', params.offset, {});
      rb.query('ordering', params.ordering, {});
      rb.query('search', params.search, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedEmployeeList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesEmployeesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesEmployeesList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * Filter results by PrimaryKey of Office
     */
    office?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-created_at' | '-email' | '-first_name' | '-id' | '-last_name' | 'created_at' | 'email' | 'first_name' | 'id' | 'last_name';

    /**
     * A search term. Searches by: (&#x27;first_name&#x27;, &#x27;last_name&#x27;, &#x27;email&#x27;, &#x27;title&#x27;)
     */
    search?: string;
  }): Observable<PaginatedEmployeeList> {

    return this.v1CompaniesEmployeesList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedEmployeeList>) => r.body as PaginatedEmployeeList)
    );
  }

  /**
   * Path part for operation v1CompaniesEmployeesCreate
   */
  static readonly V1CompaniesEmployeesCreatePath = '/api/v1/companies/employees/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesEmployeesCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesEmployeesCreate$Json$Response(params: {
    body: EmployeeRequest
  }): Observable<StrictHttpResponse<Employee>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesEmployeesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Employee>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesEmployeesCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesEmployeesCreate$Json(params: {
    body: EmployeeRequest
  }): Observable<Employee> {

    return this.v1CompaniesEmployeesCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Employee>) => r.body as Employee)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesEmployeesCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesEmployeesCreate$FormData$Response(params: {
    body: EmployeeRequest
  }): Observable<StrictHttpResponse<Employee>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesEmployeesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Employee>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesEmployeesCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesEmployeesCreate$FormData(params: {
    body: EmployeeRequest
  }): Observable<Employee> {

    return this.v1CompaniesEmployeesCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Employee>) => r.body as Employee)
    );
  }

  /**
   * Path part for operation v1CompaniesEmployeesRetrieve
   */
  static readonly V1CompaniesEmployeesRetrievePath = '/api/v1/companies/employees/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesEmployeesRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesEmployeesRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Employee.
     */
    id: number;
  }): Observable<StrictHttpResponse<Employee>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesEmployeesRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Employee>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesEmployeesRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesEmployeesRetrieve(params: {

    /**
     * A unique integer value identifying this Employee.
     */
    id: number;
  }): Observable<Employee> {

    return this.v1CompaniesEmployeesRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Employee>) => r.body as Employee)
    );
  }

  /**
   * Path part for operation v1CompaniesEmployeesUpdate
   */
  static readonly V1CompaniesEmployeesUpdatePath = '/api/v1/companies/employees/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesEmployeesUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesEmployeesUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Employee.
     */
    id: number;
    body: EmployeeRequest
  }): Observable<StrictHttpResponse<Employee>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesEmployeesUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Employee>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesEmployeesUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesEmployeesUpdate$Json(params: {

    /**
     * A unique integer value identifying this Employee.
     */
    id: number;
    body: EmployeeRequest
  }): Observable<Employee> {

    return this.v1CompaniesEmployeesUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Employee>) => r.body as Employee)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesEmployeesUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesEmployeesUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Employee.
     */
    id: number;
    body: EmployeeRequest
  }): Observable<StrictHttpResponse<Employee>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesEmployeesUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Employee>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesEmployeesUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesEmployeesUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Employee.
     */
    id: number;
    body: EmployeeRequest
  }): Observable<Employee> {

    return this.v1CompaniesEmployeesUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Employee>) => r.body as Employee)
    );
  }

  /**
   * Path part for operation v1CompaniesEmployeesDestroy
   */
  static readonly V1CompaniesEmployeesDestroyPath = '/api/v1/companies/employees/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesEmployeesDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesEmployeesDestroy$Response(params: {

    /**
     * A unique integer value identifying this Employee.
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesEmployeesDestroyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesEmployeesDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesEmployeesDestroy(params: {

    /**
     * A unique integer value identifying this Employee.
     */
    id: number;
  }): Observable<void> {

    return this.v1CompaniesEmployeesDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v1CompaniesEmployeesPartialUpdate
   */
  static readonly V1CompaniesEmployeesPartialUpdatePath = '/api/v1/companies/employees/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesEmployeesPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesEmployeesPartialUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Employee.
     */
    id: number;
    body?: PatchedEmployeeRequest
  }): Observable<StrictHttpResponse<Employee>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesEmployeesPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Employee>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesEmployeesPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesEmployeesPartialUpdate$Json(params: {

    /**
     * A unique integer value identifying this Employee.
     */
    id: number;
    body?: PatchedEmployeeRequest
  }): Observable<Employee> {

    return this.v1CompaniesEmployeesPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Employee>) => r.body as Employee)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesEmployeesPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesEmployeesPartialUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Employee.
     */
    id: number;
    body?: PatchedEmployeeRequest
  }): Observable<StrictHttpResponse<Employee>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesEmployeesPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Employee>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesEmployeesPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesEmployeesPartialUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Employee.
     */
    id: number;
    body?: PatchedEmployeeRequest
  }): Observable<Employee> {

    return this.v1CompaniesEmployeesPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Employee>) => r.body as Employee)
    );
  }

  /**
   * Path part for operation v1CompaniesExtraLeaveList
   */
  static readonly V1CompaniesExtraLeaveListPath = '/api/v1/companies/extra-leave/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesExtraLeaveList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesExtraLeaveList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedExtraLeaveList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesExtraLeaveListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedExtraLeaveList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesExtraLeaveList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesExtraLeaveList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedExtraLeaveList> {

    return this.v1CompaniesExtraLeaveList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedExtraLeaveList>) => r.body as PaginatedExtraLeaveList)
    );
  }

  /**
   * Path part for operation v1CompaniesExtraLeaveCreate
   */
  static readonly V1CompaniesExtraLeaveCreatePath = '/api/v1/companies/extra-leave/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesExtraLeaveCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesExtraLeaveCreate$Json$Response(params: {
    body: ExtraLeaveRequest
  }): Observable<StrictHttpResponse<ExtraLeave>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesExtraLeaveCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExtraLeave>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesExtraLeaveCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesExtraLeaveCreate$Json(params: {
    body: ExtraLeaveRequest
  }): Observable<ExtraLeave> {

    return this.v1CompaniesExtraLeaveCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExtraLeave>) => r.body as ExtraLeave)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesExtraLeaveCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesExtraLeaveCreate$XWwwFormUrlencoded$Response(params: {
    body: ExtraLeaveRequest
  }): Observable<StrictHttpResponse<ExtraLeave>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesExtraLeaveCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExtraLeave>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesExtraLeaveCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesExtraLeaveCreate$XWwwFormUrlencoded(params: {
    body: ExtraLeaveRequest
  }): Observable<ExtraLeave> {

    return this.v1CompaniesExtraLeaveCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<ExtraLeave>) => r.body as ExtraLeave)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesExtraLeaveCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesExtraLeaveCreate$FormData$Response(params: {
    body: ExtraLeaveRequest
  }): Observable<StrictHttpResponse<ExtraLeave>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesExtraLeaveCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExtraLeave>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesExtraLeaveCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesExtraLeaveCreate$FormData(params: {
    body: ExtraLeaveRequest
  }): Observable<ExtraLeave> {

    return this.v1CompaniesExtraLeaveCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<ExtraLeave>) => r.body as ExtraLeave)
    );
  }

  /**
   * Path part for operation v1CompaniesExtraLeaveRetrieve
   */
  static readonly V1CompaniesExtraLeaveRetrievePath = '/api/v1/companies/extra-leave/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesExtraLeaveRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesExtraLeaveRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Extra Leave.
     */
    id: number;
  }): Observable<StrictHttpResponse<ExtraLeave>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesExtraLeaveRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExtraLeave>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesExtraLeaveRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesExtraLeaveRetrieve(params: {

    /**
     * A unique integer value identifying this Extra Leave.
     */
    id: number;
  }): Observable<ExtraLeave> {

    return this.v1CompaniesExtraLeaveRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<ExtraLeave>) => r.body as ExtraLeave)
    );
  }

  /**
   * Path part for operation v1CompaniesHolidaysList
   */
  static readonly V1CompaniesHolidaysListPath = '/api/v1/companies/holidays/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesHolidaysList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesHolidaysList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedHolidayList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesHolidaysListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedHolidayList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesHolidaysList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesHolidaysList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedHolidayList> {

    return this.v1CompaniesHolidaysList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedHolidayList>) => r.body as PaginatedHolidayList)
    );
  }

  /**
   * Path part for operation v1CompaniesHolidaysCreate
   */
  static readonly V1CompaniesHolidaysCreatePath = '/api/v1/companies/holidays/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesHolidaysCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesHolidaysCreate$Json$Response(params: {
    body: HolidayRequest
  }): Observable<StrictHttpResponse<Holiday>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesHolidaysCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Holiday>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesHolidaysCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesHolidaysCreate$Json(params: {
    body: HolidayRequest
  }): Observable<Holiday> {

    return this.v1CompaniesHolidaysCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Holiday>) => r.body as Holiday)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesHolidaysCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesHolidaysCreate$XWwwFormUrlencoded$Response(params: {
    body: HolidayRequest
  }): Observable<StrictHttpResponse<Holiday>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesHolidaysCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Holiday>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesHolidaysCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesHolidaysCreate$XWwwFormUrlencoded(params: {
    body: HolidayRequest
  }): Observable<Holiday> {

    return this.v1CompaniesHolidaysCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Holiday>) => r.body as Holiday)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesHolidaysCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesHolidaysCreate$FormData$Response(params: {
    body: HolidayRequest
  }): Observable<StrictHttpResponse<Holiday>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesHolidaysCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Holiday>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesHolidaysCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesHolidaysCreate$FormData(params: {
    body: HolidayRequest
  }): Observable<Holiday> {

    return this.v1CompaniesHolidaysCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Holiday>) => r.body as Holiday)
    );
  }

  /**
   * Path part for operation v1CompaniesHolidaysRetrieve
   */
  static readonly V1CompaniesHolidaysRetrievePath = '/api/v1/companies/holidays/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesHolidaysRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesHolidaysRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Holiday.
     */
    id: number;
  }): Observable<StrictHttpResponse<Holiday>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesHolidaysRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Holiday>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesHolidaysRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesHolidaysRetrieve(params: {

    /**
     * A unique integer value identifying this Holiday.
     */
    id: number;
  }): Observable<Holiday> {

    return this.v1CompaniesHolidaysRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Holiday>) => r.body as Holiday)
    );
  }

  /**
   * Path part for operation v1CompaniesHolidaysUpdate
   */
  static readonly V1CompaniesHolidaysUpdatePath = '/api/v1/companies/holidays/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesHolidaysUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesHolidaysUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Holiday.
     */
    id: number;
    body: HolidayRequest
  }): Observable<StrictHttpResponse<Holiday>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesHolidaysUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Holiday>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesHolidaysUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesHolidaysUpdate$Json(params: {

    /**
     * A unique integer value identifying this Holiday.
     */
    id: number;
    body: HolidayRequest
  }): Observable<Holiday> {

    return this.v1CompaniesHolidaysUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Holiday>) => r.body as Holiday)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesHolidaysUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesHolidaysUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Holiday.
     */
    id: number;
    body: HolidayRequest
  }): Observable<StrictHttpResponse<Holiday>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesHolidaysUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Holiday>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesHolidaysUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesHolidaysUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Holiday.
     */
    id: number;
    body: HolidayRequest
  }): Observable<Holiday> {

    return this.v1CompaniesHolidaysUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Holiday>) => r.body as Holiday)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesHolidaysUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesHolidaysUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Holiday.
     */
    id: number;
    body: HolidayRequest
  }): Observable<StrictHttpResponse<Holiday>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesHolidaysUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Holiday>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesHolidaysUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesHolidaysUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Holiday.
     */
    id: number;
    body: HolidayRequest
  }): Observable<Holiday> {

    return this.v1CompaniesHolidaysUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Holiday>) => r.body as Holiday)
    );
  }

  /**
   * Path part for operation v1CompaniesHolidaysDestroy
   */
  static readonly V1CompaniesHolidaysDestroyPath = '/api/v1/companies/holidays/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesHolidaysDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesHolidaysDestroy$Response(params: {

    /**
     * A unique integer value identifying this Holiday.
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesHolidaysDestroyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesHolidaysDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesHolidaysDestroy(params: {

    /**
     * A unique integer value identifying this Holiday.
     */
    id: number;
  }): Observable<void> {

    return this.v1CompaniesHolidaysDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v1CompaniesHolidaysPartialUpdate
   */
  static readonly V1CompaniesHolidaysPartialUpdatePath = '/api/v1/companies/holidays/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesHolidaysPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesHolidaysPartialUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Holiday.
     */
    id: number;
    body?: PatchedHolidayRequest
  }): Observable<StrictHttpResponse<Holiday>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesHolidaysPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Holiday>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesHolidaysPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesHolidaysPartialUpdate$Json(params: {

    /**
     * A unique integer value identifying this Holiday.
     */
    id: number;
    body?: PatchedHolidayRequest
  }): Observable<Holiday> {

    return this.v1CompaniesHolidaysPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Holiday>) => r.body as Holiday)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesHolidaysPartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesHolidaysPartialUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Holiday.
     */
    id: number;
    body?: PatchedHolidayRequest
  }): Observable<StrictHttpResponse<Holiday>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesHolidaysPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Holiday>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesHolidaysPartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesHolidaysPartialUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Holiday.
     */
    id: number;
    body?: PatchedHolidayRequest
  }): Observable<Holiday> {

    return this.v1CompaniesHolidaysPartialUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Holiday>) => r.body as Holiday)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesHolidaysPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesHolidaysPartialUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Holiday.
     */
    id: number;
    body?: PatchedHolidayRequest
  }): Observable<StrictHttpResponse<Holiday>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesHolidaysPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Holiday>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesHolidaysPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesHolidaysPartialUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Holiday.
     */
    id: number;
    body?: PatchedHolidayRequest
  }): Observable<Holiday> {

    return this.v1CompaniesHolidaysPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Holiday>) => r.body as Holiday)
    );
  }

  /**
   * Path part for operation v1CompaniesOfficesList
   */
  static readonly V1CompaniesOfficesListPath = '/api/v1/companies/offices/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesOfficesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesOfficesList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedOfficeList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesOfficesListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedOfficeList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesOfficesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesOfficesList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedOfficeList> {

    return this.v1CompaniesOfficesList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedOfficeList>) => r.body as PaginatedOfficeList)
    );
  }

  /**
   * Path part for operation v1CompaniesOfficesCreate
   */
  static readonly V1CompaniesOfficesCreatePath = '/api/v1/companies/offices/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesOfficesCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesOfficesCreate$Json$Response(params: {
    body: OfficeRequest
  }): Observable<StrictHttpResponse<Office>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesOfficesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Office>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesOfficesCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesOfficesCreate$Json(params: {
    body: OfficeRequest
  }): Observable<Office> {

    return this.v1CompaniesOfficesCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Office>) => r.body as Office)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesOfficesCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesOfficesCreate$XWwwFormUrlencoded$Response(params: {
    body: OfficeRequest
  }): Observable<StrictHttpResponse<Office>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesOfficesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Office>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesOfficesCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesOfficesCreate$XWwwFormUrlencoded(params: {
    body: OfficeRequest
  }): Observable<Office> {

    return this.v1CompaniesOfficesCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Office>) => r.body as Office)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesOfficesCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesOfficesCreate$FormData$Response(params: {
    body: OfficeRequest
  }): Observable<StrictHttpResponse<Office>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesOfficesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Office>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesOfficesCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesOfficesCreate$FormData(params: {
    body: OfficeRequest
  }): Observable<Office> {

    return this.v1CompaniesOfficesCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Office>) => r.body as Office)
    );
  }

  /**
   * Path part for operation v1CompaniesOfficesRetrieve
   */
  static readonly V1CompaniesOfficesRetrievePath = '/api/v1/companies/offices/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesOfficesRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesOfficesRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Office.
     */
    id: number;
  }): Observable<StrictHttpResponse<Office>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesOfficesRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Office>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesOfficesRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesOfficesRetrieve(params: {

    /**
     * A unique integer value identifying this Office.
     */
    id: number;
  }): Observable<Office> {

    return this.v1CompaniesOfficesRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Office>) => r.body as Office)
    );
  }

  /**
   * Path part for operation v1CompaniesOfficesUpdate
   */
  static readonly V1CompaniesOfficesUpdatePath = '/api/v1/companies/offices/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesOfficesUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesOfficesUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Office.
     */
    id: number;
    body: OfficeRequest
  }): Observable<StrictHttpResponse<Office>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesOfficesUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Office>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesOfficesUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesOfficesUpdate$Json(params: {

    /**
     * A unique integer value identifying this Office.
     */
    id: number;
    body: OfficeRequest
  }): Observable<Office> {

    return this.v1CompaniesOfficesUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Office>) => r.body as Office)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesOfficesUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesOfficesUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Office.
     */
    id: number;
    body: OfficeRequest
  }): Observable<StrictHttpResponse<Office>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesOfficesUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Office>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesOfficesUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesOfficesUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Office.
     */
    id: number;
    body: OfficeRequest
  }): Observable<Office> {

    return this.v1CompaniesOfficesUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Office>) => r.body as Office)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesOfficesUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesOfficesUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Office.
     */
    id: number;
    body: OfficeRequest
  }): Observable<StrictHttpResponse<Office>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesOfficesUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Office>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesOfficesUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesOfficesUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Office.
     */
    id: number;
    body: OfficeRequest
  }): Observable<Office> {

    return this.v1CompaniesOfficesUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Office>) => r.body as Office)
    );
  }

  /**
   * Path part for operation v1CompaniesOfficesDestroy
   */
  static readonly V1CompaniesOfficesDestroyPath = '/api/v1/companies/offices/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesOfficesDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesOfficesDestroy$Response(params: {

    /**
     * A unique integer value identifying this Office.
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesOfficesDestroyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesOfficesDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesOfficesDestroy(params: {

    /**
     * A unique integer value identifying this Office.
     */
    id: number;
  }): Observable<void> {

    return this.v1CompaniesOfficesDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v1CompaniesOfficesPartialUpdate
   */
  static readonly V1CompaniesOfficesPartialUpdatePath = '/api/v1/companies/offices/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesOfficesPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesOfficesPartialUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Office.
     */
    id: number;
    body?: PatchedOfficeRequest
  }): Observable<StrictHttpResponse<Office>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesOfficesPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Office>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesOfficesPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesOfficesPartialUpdate$Json(params: {

    /**
     * A unique integer value identifying this Office.
     */
    id: number;
    body?: PatchedOfficeRequest
  }): Observable<Office> {

    return this.v1CompaniesOfficesPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Office>) => r.body as Office)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesOfficesPartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesOfficesPartialUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Office.
     */
    id: number;
    body?: PatchedOfficeRequest
  }): Observable<StrictHttpResponse<Office>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesOfficesPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Office>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesOfficesPartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesOfficesPartialUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Office.
     */
    id: number;
    body?: PatchedOfficeRequest
  }): Observable<Office> {

    return this.v1CompaniesOfficesPartialUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Office>) => r.body as Office)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesOfficesPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesOfficesPartialUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Office.
     */
    id: number;
    body?: PatchedOfficeRequest
  }): Observable<StrictHttpResponse<Office>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesOfficesPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Office>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesOfficesPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesOfficesPartialUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Office.
     */
    id: number;
    body?: PatchedOfficeRequest
  }): Observable<Office> {

    return this.v1CompaniesOfficesPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Office>) => r.body as Office)
    );
  }

  /**
   * Path part for operation v1CompaniesSpecialLeaveList
   */
  static readonly V1CompaniesSpecialLeaveListPath = '/api/v1/companies/special-leave/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesSpecialLeaveList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesSpecialLeaveList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedSpecialLeaveList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesSpecialLeaveListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedSpecialLeaveList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesSpecialLeaveList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesSpecialLeaveList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedSpecialLeaveList> {

    return this.v1CompaniesSpecialLeaveList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedSpecialLeaveList>) => r.body as PaginatedSpecialLeaveList)
    );
  }

  /**
   * Path part for operation v1CompaniesSpecialLeaveCreate
   */
  static readonly V1CompaniesSpecialLeaveCreatePath = '/api/v1/companies/special-leave/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesSpecialLeaveCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesSpecialLeaveCreate$Json$Response(params: {
    body: SpecialLeaveRequest
  }): Observable<StrictHttpResponse<SpecialLeave>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesSpecialLeaveCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpecialLeave>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesSpecialLeaveCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1CompaniesSpecialLeaveCreate$Json(params: {
    body: SpecialLeaveRequest
  }): Observable<SpecialLeave> {

    return this.v1CompaniesSpecialLeaveCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SpecialLeave>) => r.body as SpecialLeave)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesSpecialLeaveCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesSpecialLeaveCreate$XWwwFormUrlencoded$Response(params: {
    body: SpecialLeaveRequest
  }): Observable<StrictHttpResponse<SpecialLeave>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesSpecialLeaveCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpecialLeave>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesSpecialLeaveCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1CompaniesSpecialLeaveCreate$XWwwFormUrlencoded(params: {
    body: SpecialLeaveRequest
  }): Observable<SpecialLeave> {

    return this.v1CompaniesSpecialLeaveCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<SpecialLeave>) => r.body as SpecialLeave)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesSpecialLeaveCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesSpecialLeaveCreate$FormData$Response(params: {
    body: SpecialLeaveRequest
  }): Observable<StrictHttpResponse<SpecialLeave>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesSpecialLeaveCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpecialLeave>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesSpecialLeaveCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1CompaniesSpecialLeaveCreate$FormData(params: {
    body: SpecialLeaveRequest
  }): Observable<SpecialLeave> {

    return this.v1CompaniesSpecialLeaveCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<SpecialLeave>) => r.body as SpecialLeave)
    );
  }

  /**
   * Path part for operation v1CompaniesSpecialLeaveRetrieve
   */
  static readonly V1CompaniesSpecialLeaveRetrievePath = '/api/v1/companies/special-leave/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CompaniesSpecialLeaveRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesSpecialLeaveRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Special Leave.
     */
    id: number;
  }): Observable<StrictHttpResponse<SpecialLeave>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1CompaniesSpecialLeaveRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpecialLeave>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1CompaniesSpecialLeaveRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1CompaniesSpecialLeaveRetrieve(params: {

    /**
     * A unique integer value identifying this Special Leave.
     */
    id: number;
  }): Observable<SpecialLeave> {

    return this.v1CompaniesSpecialLeaveRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<SpecialLeave>) => r.body as SpecialLeave)
    );
  }

  /**
   * Path part for operation v1GalleriesAlbumsList
   */
  static readonly V1GalleriesAlbumsListPath = '/api/v1/galleries/albums/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1GalleriesAlbumsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1GalleriesAlbumsList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-created_at' | '-title' | '-updated_at' | 'created_at' | 'title' | 'updated_at';
  }): Observable<StrictHttpResponse<PaginatedGalleryList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1GalleriesAlbumsListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('ordering', params.ordering, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedGalleryList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1GalleriesAlbumsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1GalleriesAlbumsList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-created_at' | '-title' | '-updated_at' | 'created_at' | 'title' | 'updated_at';
  }): Observable<PaginatedGalleryList> {

    return this.v1GalleriesAlbumsList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedGalleryList>) => r.body as PaginatedGalleryList)
    );
  }

  /**
   * Path part for operation v1GalleriesAlbumsCreate
   */
  static readonly V1GalleriesAlbumsCreatePath = '/api/v1/galleries/albums/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1GalleriesAlbumsCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1GalleriesAlbumsCreate$Json$Response(params: {
    body: GalleryCreateUpdateRequest
  }): Observable<StrictHttpResponse<GalleryCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1GalleriesAlbumsCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GalleryCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1GalleriesAlbumsCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1GalleriesAlbumsCreate$Json(params: {
    body: GalleryCreateUpdateRequest
  }): Observable<GalleryCreateUpdate> {

    return this.v1GalleriesAlbumsCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GalleryCreateUpdate>) => r.body as GalleryCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1GalleriesAlbumsCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1GalleriesAlbumsCreate$XWwwFormUrlencoded$Response(params: {
    body: GalleryCreateUpdateRequest
  }): Observable<StrictHttpResponse<GalleryCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1GalleriesAlbumsCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GalleryCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1GalleriesAlbumsCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1GalleriesAlbumsCreate$XWwwFormUrlencoded(params: {
    body: GalleryCreateUpdateRequest
  }): Observable<GalleryCreateUpdate> {

    return this.v1GalleriesAlbumsCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<GalleryCreateUpdate>) => r.body as GalleryCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1GalleriesAlbumsCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1GalleriesAlbumsCreate$FormData$Response(params: {
    body: GalleryCreateUpdateRequest
  }): Observable<StrictHttpResponse<GalleryCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1GalleriesAlbumsCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GalleryCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1GalleriesAlbumsCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1GalleriesAlbumsCreate$FormData(params: {
    body: GalleryCreateUpdateRequest
  }): Observable<GalleryCreateUpdate> {

    return this.v1GalleriesAlbumsCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<GalleryCreateUpdate>) => r.body as GalleryCreateUpdate)
    );
  }

  /**
   * Path part for operation v1GalleriesAlbumsRetrieve
   */
  static readonly V1GalleriesAlbumsRetrievePath = '/api/v1/galleries/albums/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1GalleriesAlbumsRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1GalleriesAlbumsRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
  }): Observable<StrictHttpResponse<Gallery>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1GalleriesAlbumsRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Gallery>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1GalleriesAlbumsRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1GalleriesAlbumsRetrieve(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
  }): Observable<Gallery> {

    return this.v1GalleriesAlbumsRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Gallery>) => r.body as Gallery)
    );
  }

  /**
   * Path part for operation v1GalleriesAlbumsUpdate
   */
  static readonly V1GalleriesAlbumsUpdatePath = '/api/v1/galleries/albums/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1GalleriesAlbumsUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1GalleriesAlbumsUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
    body: GalleryCreateUpdateRequest
  }): Observable<StrictHttpResponse<GalleryCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1GalleriesAlbumsUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GalleryCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1GalleriesAlbumsUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1GalleriesAlbumsUpdate$Json(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
    body: GalleryCreateUpdateRequest
  }): Observable<GalleryCreateUpdate> {

    return this.v1GalleriesAlbumsUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GalleryCreateUpdate>) => r.body as GalleryCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1GalleriesAlbumsUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1GalleriesAlbumsUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
    body: GalleryCreateUpdateRequest
  }): Observable<StrictHttpResponse<GalleryCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1GalleriesAlbumsUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GalleryCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1GalleriesAlbumsUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1GalleriesAlbumsUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
    body: GalleryCreateUpdateRequest
  }): Observable<GalleryCreateUpdate> {

    return this.v1GalleriesAlbumsUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<GalleryCreateUpdate>) => r.body as GalleryCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1GalleriesAlbumsUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1GalleriesAlbumsUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
    body: GalleryCreateUpdateRequest
  }): Observable<StrictHttpResponse<GalleryCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1GalleriesAlbumsUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GalleryCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1GalleriesAlbumsUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1GalleriesAlbumsUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
    body: GalleryCreateUpdateRequest
  }): Observable<GalleryCreateUpdate> {

    return this.v1GalleriesAlbumsUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<GalleryCreateUpdate>) => r.body as GalleryCreateUpdate)
    );
  }

  /**
   * Path part for operation v1GalleriesAlbumsDestroy
   */
  static readonly V1GalleriesAlbumsDestroyPath = '/api/v1/galleries/albums/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1GalleriesAlbumsDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1GalleriesAlbumsDestroy$Response(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1GalleriesAlbumsDestroyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1GalleriesAlbumsDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1GalleriesAlbumsDestroy(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
  }): Observable<void> {

    return this.v1GalleriesAlbumsDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v1GalleriesAlbumsPartialUpdate
   */
  static readonly V1GalleriesAlbumsPartialUpdatePath = '/api/v1/galleries/albums/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1GalleriesAlbumsPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1GalleriesAlbumsPartialUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
    body?: PatchedGalleryCreateUpdateRequest
  }): Observable<StrictHttpResponse<GalleryCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1GalleriesAlbumsPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GalleryCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1GalleriesAlbumsPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1GalleriesAlbumsPartialUpdate$Json(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
    body?: PatchedGalleryCreateUpdateRequest
  }): Observable<GalleryCreateUpdate> {

    return this.v1GalleriesAlbumsPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GalleryCreateUpdate>) => r.body as GalleryCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1GalleriesAlbumsPartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1GalleriesAlbumsPartialUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
    body?: PatchedGalleryCreateUpdateRequest
  }): Observable<StrictHttpResponse<GalleryCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1GalleriesAlbumsPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GalleryCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1GalleriesAlbumsPartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1GalleriesAlbumsPartialUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
    body?: PatchedGalleryCreateUpdateRequest
  }): Observable<GalleryCreateUpdate> {

    return this.v1GalleriesAlbumsPartialUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<GalleryCreateUpdate>) => r.body as GalleryCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1GalleriesAlbumsPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1GalleriesAlbumsPartialUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
    body?: PatchedGalleryCreateUpdateRequest
  }): Observable<StrictHttpResponse<GalleryCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1GalleriesAlbumsPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GalleryCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1GalleriesAlbumsPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1GalleriesAlbumsPartialUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
    body?: PatchedGalleryCreateUpdateRequest
  }): Observable<GalleryCreateUpdate> {

    return this.v1GalleriesAlbumsPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<GalleryCreateUpdate>) => r.body as GalleryCreateUpdate)
    );
  }

  /**
   * Path part for operation v1GalleriesAlbumsChangePositionCreate
   */
  static readonly V1GalleriesAlbumsChangePositionCreatePath = '/api/v1/galleries/albums/{id}/change-position/';

  /**
   * This endpoint is used for positioning gallery items (media) as per user request. Example: We want to move (this) picture(mediaX) above (that) picture(mediaY). To achieve such action, you supply 'media' field as PrimaryKey of (this) mediaX, and 'media_after' as PrimaryKey of (that) mediaY - media that comes after mediaX. Special case: You want to position an item to the last place. Since there is no after-last item, can supply 'media_after' with value of 'null' and 'media' field with PrimaryKey of media, you would like to move to last position.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1GalleriesAlbumsChangePositionCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1GalleriesAlbumsChangePositionCreate$Json$Response(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
    body: GalleryMediaChangePositionRequest
  }): Observable<StrictHttpResponse<Gallery>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1GalleriesAlbumsChangePositionCreatePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Gallery>;
      })
    );
  }

  /**
   * This endpoint is used for positioning gallery items (media) as per user request. Example: We want to move (this) picture(mediaX) above (that) picture(mediaY). To achieve such action, you supply 'media' field as PrimaryKey of (this) mediaX, and 'media_after' as PrimaryKey of (that) mediaY - media that comes after mediaX. Special case: You want to position an item to the last place. Since there is no after-last item, can supply 'media_after' with value of 'null' and 'media' field with PrimaryKey of media, you would like to move to last position.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1GalleriesAlbumsChangePositionCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1GalleriesAlbumsChangePositionCreate$Json(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
    body: GalleryMediaChangePositionRequest
  }): Observable<Gallery> {

    return this.v1GalleriesAlbumsChangePositionCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Gallery>) => r.body as Gallery)
    );
  }

  /**
   * This endpoint is used for positioning gallery items (media) as per user request. Example: We want to move (this) picture(mediaX) above (that) picture(mediaY). To achieve such action, you supply 'media' field as PrimaryKey of (this) mediaX, and 'media_after' as PrimaryKey of (that) mediaY - media that comes after mediaX. Special case: You want to position an item to the last place. Since there is no after-last item, can supply 'media_after' with value of 'null' and 'media' field with PrimaryKey of media, you would like to move to last position.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1GalleriesAlbumsChangePositionCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1GalleriesAlbumsChangePositionCreate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
    body: GalleryMediaChangePositionRequest
  }): Observable<StrictHttpResponse<Gallery>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1GalleriesAlbumsChangePositionCreatePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Gallery>;
      })
    );
  }

  /**
   * This endpoint is used for positioning gallery items (media) as per user request. Example: We want to move (this) picture(mediaX) above (that) picture(mediaY). To achieve such action, you supply 'media' field as PrimaryKey of (this) mediaX, and 'media_after' as PrimaryKey of (that) mediaY - media that comes after mediaX. Special case: You want to position an item to the last place. Since there is no after-last item, can supply 'media_after' with value of 'null' and 'media' field with PrimaryKey of media, you would like to move to last position.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1GalleriesAlbumsChangePositionCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1GalleriesAlbumsChangePositionCreate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
    body: GalleryMediaChangePositionRequest
  }): Observable<Gallery> {

    return this.v1GalleriesAlbumsChangePositionCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Gallery>) => r.body as Gallery)
    );
  }

  /**
   * This endpoint is used for positioning gallery items (media) as per user request. Example: We want to move (this) picture(mediaX) above (that) picture(mediaY). To achieve such action, you supply 'media' field as PrimaryKey of (this) mediaX, and 'media_after' as PrimaryKey of (that) mediaY - media that comes after mediaX. Special case: You want to position an item to the last place. Since there is no after-last item, can supply 'media_after' with value of 'null' and 'media' field with PrimaryKey of media, you would like to move to last position.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1GalleriesAlbumsChangePositionCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1GalleriesAlbumsChangePositionCreate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
    body: GalleryMediaChangePositionRequest
  }): Observable<StrictHttpResponse<Gallery>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1GalleriesAlbumsChangePositionCreatePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Gallery>;
      })
    );
  }

  /**
   * This endpoint is used for positioning gallery items (media) as per user request. Example: We want to move (this) picture(mediaX) above (that) picture(mediaY). To achieve such action, you supply 'media' field as PrimaryKey of (this) mediaX, and 'media_after' as PrimaryKey of (that) mediaY - media that comes after mediaX. Special case: You want to position an item to the last place. Since there is no after-last item, can supply 'media_after' with value of 'null' and 'media' field with PrimaryKey of media, you would like to move to last position.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1GalleriesAlbumsChangePositionCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1GalleriesAlbumsChangePositionCreate$FormData(params: {

    /**
     * A unique integer value identifying this Gallery.
     */
    id: number;
    body: GalleryMediaChangePositionRequest
  }): Observable<Gallery> {

    return this.v1GalleriesAlbumsChangePositionCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Gallery>) => r.body as Gallery)
    );
  }

  /**
   * Path part for operation v1GalleriesGalleryMediaList
   */
  static readonly V1GalleriesGalleryMediaListPath = '/api/v1/galleries/gallery-media/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1GalleriesGalleryMediaList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1GalleriesGalleryMediaList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedGalleryMediaList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1GalleriesGalleryMediaListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedGalleryMediaList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1GalleriesGalleryMediaList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1GalleriesGalleryMediaList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedGalleryMediaList> {

    return this.v1GalleriesGalleryMediaList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedGalleryMediaList>) => r.body as PaginatedGalleryMediaList)
    );
  }

  /**
   * Path part for operation v1GalleriesGalleryMediaBulkUpdateUpdate
   */
  static readonly V1GalleriesGalleryMediaBulkUpdateUpdatePath = '/api/v1/galleries/gallery-media/bulk-update/';

  /**
   * Updates the gallery_media m2m (between Gallery and Media) with the provided `position` field.
   * Supports bulk update.
   * Returns a paginated response
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1GalleriesGalleryMediaBulkUpdateUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1GalleriesGalleryMediaBulkUpdateUpdate$Json$Response(params: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
    body: Array<GalleryMediaBulkUpdateRequest>
  }): Observable<StrictHttpResponse<PaginatedGalleryMediaList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1GalleriesGalleryMediaBulkUpdateUpdatePath, 'put');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedGalleryMediaList>;
      })
    );
  }

  /**
   * Updates the gallery_media m2m (between Gallery and Media) with the provided `position` field.
   * Supports bulk update.
   * Returns a paginated response
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1GalleriesGalleryMediaBulkUpdateUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1GalleriesGalleryMediaBulkUpdateUpdate$Json(params: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
    body: Array<GalleryMediaBulkUpdateRequest>
  }): Observable<PaginatedGalleryMediaList> {

    return this.v1GalleriesGalleryMediaBulkUpdateUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedGalleryMediaList>) => r.body as PaginatedGalleryMediaList)
    );
  }

  /**
   * Updates the gallery_media m2m (between Gallery and Media) with the provided `position` field.
   * Supports bulk update.
   * Returns a paginated response
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1GalleriesGalleryMediaBulkUpdateUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1GalleriesGalleryMediaBulkUpdateUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
    body: Array<GalleryMediaBulkUpdateRequest>
  }): Observable<StrictHttpResponse<PaginatedGalleryMediaList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1GalleriesGalleryMediaBulkUpdateUpdatePath, 'put');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedGalleryMediaList>;
      })
    );
  }

  /**
   * Updates the gallery_media m2m (between Gallery and Media) with the provided `position` field.
   * Supports bulk update.
   * Returns a paginated response
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1GalleriesGalleryMediaBulkUpdateUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1GalleriesGalleryMediaBulkUpdateUpdate$XWwwFormUrlencoded(params: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
    body: Array<GalleryMediaBulkUpdateRequest>
  }): Observable<PaginatedGalleryMediaList> {

    return this.v1GalleriesGalleryMediaBulkUpdateUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedGalleryMediaList>) => r.body as PaginatedGalleryMediaList)
    );
  }

  /**
   * Updates the gallery_media m2m (between Gallery and Media) with the provided `position` field.
   * Supports bulk update.
   * Returns a paginated response
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1GalleriesGalleryMediaBulkUpdateUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1GalleriesGalleryMediaBulkUpdateUpdate$FormData$Response(params: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
    body: Array<GalleryMediaBulkUpdateRequest>
  }): Observable<StrictHttpResponse<PaginatedGalleryMediaList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1GalleriesGalleryMediaBulkUpdateUpdatePath, 'put');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedGalleryMediaList>;
      })
    );
  }

  /**
   * Updates the gallery_media m2m (between Gallery and Media) with the provided `position` field.
   * Supports bulk update.
   * Returns a paginated response
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1GalleriesGalleryMediaBulkUpdateUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1GalleriesGalleryMediaBulkUpdateUpdate$FormData(params: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
    body: Array<GalleryMediaBulkUpdateRequest>
  }): Observable<PaginatedGalleryMediaList> {

    return this.v1GalleriesGalleryMediaBulkUpdateUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedGalleryMediaList>) => r.body as PaginatedGalleryMediaList)
    );
  }

  /**
   * Path part for operation v1HandbooksDirectoriesList
   */
  static readonly V1HandbooksDirectoriesListPath = '/api/v1/handbooks/directories/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1HandbooksDirectoriesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1HandbooksDirectoriesList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-id' | '-order' | '-title' | 'id' | 'order' | 'title';
  }): Observable<StrictHttpResponse<PaginatedDirectoryList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1HandbooksDirectoriesListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('ordering', params.ordering, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedDirectoryList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1HandbooksDirectoriesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1HandbooksDirectoriesList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-id' | '-order' | '-title' | 'id' | 'order' | 'title';
  }): Observable<PaginatedDirectoryList> {

    return this.v1HandbooksDirectoriesList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedDirectoryList>) => r.body as PaginatedDirectoryList)
    );
  }

  /**
   * Path part for operation v1HandbooksDirectoriesCreate
   */
  static readonly V1HandbooksDirectoriesCreatePath = '/api/v1/handbooks/directories/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1HandbooksDirectoriesCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1HandbooksDirectoriesCreate$Json$Response(params: {
    body: DirectoryCreateUpdateRequest
  }): Observable<StrictHttpResponse<DirectoryCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1HandbooksDirectoriesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DirectoryCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1HandbooksDirectoriesCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1HandbooksDirectoriesCreate$Json(params: {
    body: DirectoryCreateUpdateRequest
  }): Observable<DirectoryCreateUpdate> {

    return this.v1HandbooksDirectoriesCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DirectoryCreateUpdate>) => r.body as DirectoryCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1HandbooksDirectoriesCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1HandbooksDirectoriesCreate$XWwwFormUrlencoded$Response(params: {
    body: DirectoryCreateUpdateRequest
  }): Observable<StrictHttpResponse<DirectoryCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1HandbooksDirectoriesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DirectoryCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1HandbooksDirectoriesCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1HandbooksDirectoriesCreate$XWwwFormUrlencoded(params: {
    body: DirectoryCreateUpdateRequest
  }): Observable<DirectoryCreateUpdate> {

    return this.v1HandbooksDirectoriesCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<DirectoryCreateUpdate>) => r.body as DirectoryCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1HandbooksDirectoriesCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1HandbooksDirectoriesCreate$FormData$Response(params: {
    body: DirectoryCreateUpdateRequest
  }): Observable<StrictHttpResponse<DirectoryCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1HandbooksDirectoriesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DirectoryCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1HandbooksDirectoriesCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1HandbooksDirectoriesCreate$FormData(params: {
    body: DirectoryCreateUpdateRequest
  }): Observable<DirectoryCreateUpdate> {

    return this.v1HandbooksDirectoriesCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<DirectoryCreateUpdate>) => r.body as DirectoryCreateUpdate)
    );
  }

  /**
   * Path part for operation v1HandbooksDirectoriesRetrieve
   */
  static readonly V1HandbooksDirectoriesRetrievePath = '/api/v1/handbooks/directories/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1HandbooksDirectoriesRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1HandbooksDirectoriesRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Directory.
     */
    id: number;
  }): Observable<StrictHttpResponse<Directory>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1HandbooksDirectoriesRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Directory>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1HandbooksDirectoriesRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1HandbooksDirectoriesRetrieve(params: {

    /**
     * A unique integer value identifying this Directory.
     */
    id: number;
  }): Observable<Directory> {

    return this.v1HandbooksDirectoriesRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Directory>) => r.body as Directory)
    );
  }

  /**
   * Path part for operation v1HandbooksDirectoriesUpdate
   */
  static readonly V1HandbooksDirectoriesUpdatePath = '/api/v1/handbooks/directories/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1HandbooksDirectoriesUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1HandbooksDirectoriesUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Directory.
     */
    id: number;
    body: DirectoryCreateUpdateRequest
  }): Observable<StrictHttpResponse<DirectoryCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1HandbooksDirectoriesUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DirectoryCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1HandbooksDirectoriesUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1HandbooksDirectoriesUpdate$Json(params: {

    /**
     * A unique integer value identifying this Directory.
     */
    id: number;
    body: DirectoryCreateUpdateRequest
  }): Observable<DirectoryCreateUpdate> {

    return this.v1HandbooksDirectoriesUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DirectoryCreateUpdate>) => r.body as DirectoryCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1HandbooksDirectoriesUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1HandbooksDirectoriesUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Directory.
     */
    id: number;
    body: DirectoryCreateUpdateRequest
  }): Observable<StrictHttpResponse<DirectoryCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1HandbooksDirectoriesUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DirectoryCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1HandbooksDirectoriesUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1HandbooksDirectoriesUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Directory.
     */
    id: number;
    body: DirectoryCreateUpdateRequest
  }): Observable<DirectoryCreateUpdate> {

    return this.v1HandbooksDirectoriesUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<DirectoryCreateUpdate>) => r.body as DirectoryCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1HandbooksDirectoriesUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1HandbooksDirectoriesUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Directory.
     */
    id: number;
    body: DirectoryCreateUpdateRequest
  }): Observable<StrictHttpResponse<DirectoryCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1HandbooksDirectoriesUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DirectoryCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1HandbooksDirectoriesUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1HandbooksDirectoriesUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Directory.
     */
    id: number;
    body: DirectoryCreateUpdateRequest
  }): Observable<DirectoryCreateUpdate> {

    return this.v1HandbooksDirectoriesUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<DirectoryCreateUpdate>) => r.body as DirectoryCreateUpdate)
    );
  }

  /**
   * Path part for operation v1HandbooksDirectoriesDestroy
   */
  static readonly V1HandbooksDirectoriesDestroyPath = '/api/v1/handbooks/directories/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1HandbooksDirectoriesDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1HandbooksDirectoriesDestroy$Response(params: {

    /**
     * A unique integer value identifying this Directory.
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1HandbooksDirectoriesDestroyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1HandbooksDirectoriesDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1HandbooksDirectoriesDestroy(params: {

    /**
     * A unique integer value identifying this Directory.
     */
    id: number;
  }): Observable<void> {

    return this.v1HandbooksDirectoriesDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v1HandbooksDirectoriesPartialUpdate
   */
  static readonly V1HandbooksDirectoriesPartialUpdatePath = '/api/v1/handbooks/directories/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1HandbooksDirectoriesPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1HandbooksDirectoriesPartialUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Directory.
     */
    id: number;
    body?: PatchedDirectoryCreateUpdateRequest
  }): Observable<StrictHttpResponse<DirectoryCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1HandbooksDirectoriesPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DirectoryCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1HandbooksDirectoriesPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1HandbooksDirectoriesPartialUpdate$Json(params: {

    /**
     * A unique integer value identifying this Directory.
     */
    id: number;
    body?: PatchedDirectoryCreateUpdateRequest
  }): Observable<DirectoryCreateUpdate> {

    return this.v1HandbooksDirectoriesPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DirectoryCreateUpdate>) => r.body as DirectoryCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1HandbooksDirectoriesPartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1HandbooksDirectoriesPartialUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Directory.
     */
    id: number;
    body?: PatchedDirectoryCreateUpdateRequest
  }): Observable<StrictHttpResponse<DirectoryCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1HandbooksDirectoriesPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DirectoryCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1HandbooksDirectoriesPartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1HandbooksDirectoriesPartialUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Directory.
     */
    id: number;
    body?: PatchedDirectoryCreateUpdateRequest
  }): Observable<DirectoryCreateUpdate> {

    return this.v1HandbooksDirectoriesPartialUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<DirectoryCreateUpdate>) => r.body as DirectoryCreateUpdate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1HandbooksDirectoriesPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1HandbooksDirectoriesPartialUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Directory.
     */
    id: number;
    body?: PatchedDirectoryCreateUpdateRequest
  }): Observable<StrictHttpResponse<DirectoryCreateUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1HandbooksDirectoriesPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DirectoryCreateUpdate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1HandbooksDirectoriesPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1HandbooksDirectoriesPartialUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Directory.
     */
    id: number;
    body?: PatchedDirectoryCreateUpdateRequest
  }): Observable<DirectoryCreateUpdate> {

    return this.v1HandbooksDirectoriesPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<DirectoryCreateUpdate>) => r.body as DirectoryCreateUpdate)
    );
  }

  /**
   * Path part for operation v1NewsArticlesList
   */
  static readonly V1NewsArticlesListPath = '/api/v1/news/articles/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsArticlesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsArticlesList$Response(params?: {
    is_active?: boolean;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Filters the articles to include only lifestyle tag
     */
    only_lifestyle_tag?: boolean;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-created_at' | '-id' | 'created_at' | 'id';

    /**
     * A search term.
     */
    search?: string;

    /**
     * Multiple values may be separated by commas.
     */
    tags__id__in?: Array<number>;

    /**
     * Multiple values may be separated by commas.
     */
    tags__id__not_in?: Array<number>;
    tags__name__icontains?: string;

    /**
     * Filters the articles to remove market tag
     */
    without_market_tag?: boolean;

    /**
     * Filters the articles to remove eko tag
     */
     without_eco_tag?: boolean;
  }): Observable<StrictHttpResponse<PaginatedArticleListRetrieveList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsArticlesListPath, 'get');
    if (params) {
      rb.query('is_active', params.is_active, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('only_lifestyle_tag', params.only_lifestyle_tag, {});
      rb.query('ordering', params.ordering, {});
      rb.query('search', params.search, {});
      rb.query('tags__id__in', params.tags__id__in, {"style":"form","explode":false});
      rb.query('tags__id__not_in', params.tags__id__not_in, {"style":"form","explode":false});
      rb.query('tags__name__icontains', params.tags__name__icontains, {});
      rb.query('without_market_tag', params.without_market_tag, {});
      rb.query('without_eco_tag', params.without_eco_tag, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedArticleListRetrieveList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsArticlesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsArticlesList(params?: {
    is_active?: boolean;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Filters the articles to include only lifestyle tag
     */
    only_lifestyle_tag?: boolean;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-created_at' | '-id' | 'created_at' | 'id';

    /**
     * A search term.
     */
    search?: string;

    /**
     * Multiple values may be separated by commas.
     */
    tags__id__in?: Array<number>;

    /**
     * Multiple values may be separated by commas.
     */
    tags__id__not_in?: Array<number>;
    tags__name__icontains?: string;

    /**
     * Filters the articles to remove market tag
     */
    without_market_tag?: boolean;

    /**
    * Filters the articles to remove eko tag
    */
    without_eco_tag?: boolean;
  }): Observable<PaginatedArticleListRetrieveList> {

    return this.v1NewsArticlesList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedArticleListRetrieveList>) => r.body as PaginatedArticleListRetrieveList)
    );
  }

  /**
   * Path part for operation v1NewsArticlesCreate
   */
  static readonly V1NewsArticlesCreatePath = '/api/v1/news/articles/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsArticlesCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsArticlesCreate$Json$Response(params: {
    body: ArticleRequest
  }): Observable<StrictHttpResponse<Article>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsArticlesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Article>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsArticlesCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsArticlesCreate$Json(params: {
    body: ArticleRequest
  }): Observable<Article> {

    return this.v1NewsArticlesCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Article>) => r.body as Article)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsArticlesCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsArticlesCreate$XWwwFormUrlencoded$Response(params: {
    body: ArticleRequest
  }): Observable<StrictHttpResponse<Article>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsArticlesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Article>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsArticlesCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsArticlesCreate$XWwwFormUrlencoded(params: {
    body: ArticleRequest
  }): Observable<Article> {

    return this.v1NewsArticlesCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Article>) => r.body as Article)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsArticlesCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsArticlesCreate$FormData$Response(params: {
    body: ArticleRequest
  }): Observable<StrictHttpResponse<Article>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsArticlesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Article>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsArticlesCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsArticlesCreate$FormData(params: {
    body: ArticleRequest
  }): Observable<Article> {

    return this.v1NewsArticlesCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Article>) => r.body as Article)
    );
  }

  /**
   * Path part for operation v1NewsArticlesRetrieve
   */
  static readonly V1NewsArticlesRetrievePath = '/api/v1/news/articles/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsArticlesRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsArticlesRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Article.
     */
    id: number;
  }): Observable<StrictHttpResponse<ArticleListRetrieve>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsArticlesRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ArticleListRetrieve>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsArticlesRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsArticlesRetrieve(params: {

    /**
     * A unique integer value identifying this Article.
     */
    id: number;
  }): Observable<ArticleListRetrieve> {

    return this.v1NewsArticlesRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<ArticleListRetrieve>) => r.body as ArticleListRetrieve)
    );
  }

  /**
   * Path part for operation v1NewsArticlesUpdate
   */
  static readonly V1NewsArticlesUpdatePath = '/api/v1/news/articles/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsArticlesUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsArticlesUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Article.
     */
    id: number;
    body: ArticleRequest
  }): Observable<StrictHttpResponse<Article>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsArticlesUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Article>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsArticlesUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsArticlesUpdate$Json(params: {

    /**
     * A unique integer value identifying this Article.
     */
    id: number;
    body: ArticleRequest
  }): Observable<Article> {

    return this.v1NewsArticlesUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Article>) => r.body as Article)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsArticlesUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsArticlesUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Article.
     */
    id: number;
    body: ArticleRequest
  }): Observable<StrictHttpResponse<Article>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsArticlesUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Article>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsArticlesUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsArticlesUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Article.
     */
    id: number;
    body: ArticleRequest
  }): Observable<Article> {

    return this.v1NewsArticlesUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Article>) => r.body as Article)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsArticlesUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsArticlesUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Article.
     */
    id: number;
    body: ArticleRequest
  }): Observable<StrictHttpResponse<Article>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsArticlesUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Article>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsArticlesUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsArticlesUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Article.
     */
    id: number;
    body: ArticleRequest
  }): Observable<Article> {

    return this.v1NewsArticlesUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Article>) => r.body as Article)
    );
  }

  /**
   * Path part for operation v1NewsArticlesDestroy
   */
  static readonly V1NewsArticlesDestroyPath = '/api/v1/news/articles/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsArticlesDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsArticlesDestroy$Response(params: {

    /**
     * A unique integer value identifying this Article.
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsArticlesDestroyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsArticlesDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsArticlesDestroy(params: {

    /**
     * A unique integer value identifying this Article.
     */
    id: number;
  }): Observable<void> {

    return this.v1NewsArticlesDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v1NewsArticlesPartialUpdate
   */
  static readonly V1NewsArticlesPartialUpdatePath = '/api/v1/news/articles/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsArticlesPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsArticlesPartialUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Article.
     */
    id: number;
    body?: PatchedArticleRequest
  }): Observable<StrictHttpResponse<Article>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsArticlesPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Article>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsArticlesPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsArticlesPartialUpdate$Json(params: {

    /**
     * A unique integer value identifying this Article.
     */
    id: number;
    body?: PatchedArticleRequest
  }): Observable<Article> {

    return this.v1NewsArticlesPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Article>) => r.body as Article)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsArticlesPartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsArticlesPartialUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Article.
     */
    id: number;
    body?: PatchedArticleRequest
  }): Observable<StrictHttpResponse<Article>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsArticlesPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Article>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsArticlesPartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsArticlesPartialUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Article.
     */
    id: number;
    body?: PatchedArticleRequest
  }): Observable<Article> {

    return this.v1NewsArticlesPartialUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Article>) => r.body as Article)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsArticlesPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsArticlesPartialUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Article.
     */
    id: number;
    body?: PatchedArticleRequest
  }): Observable<StrictHttpResponse<Article>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsArticlesPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Article>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsArticlesPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsArticlesPartialUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Article.
     */
    id: number;
    body?: PatchedArticleRequest
  }): Observable<Article> {

    return this.v1NewsArticlesPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Article>) => r.body as Article)
    );
  }

  /**
   * Path part for operation v1NewsLinksList
   */
  static readonly V1NewsLinksListPath = '/api/v1/news/links/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsLinksList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsLinksList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Only one LinkedIn type link is allowed
     */
    type?: 'coc' | 'hr' | 'informatika' | 'linkedin' | 'splosne_sluzbe';
  }): Observable<StrictHttpResponse<PaginatedLinkList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsLinksListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedLinkList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsLinksList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsLinksList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Only one LinkedIn type link is allowed
     */
    type?: 'coc' | 'hr' | 'informatika' | 'linkedin' | 'splosne_sluzbe';
  }): Observable<PaginatedLinkList> {

    return this.v1NewsLinksList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedLinkList>) => r.body as PaginatedLinkList)
    );
  }

  /**
   * Path part for operation v1NewsLinksCreate
   */
  static readonly V1NewsLinksCreatePath = '/api/v1/news/links/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsLinksCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsLinksCreate$Json$Response(params: {
    body: LinkRequest
  }): Observable<StrictHttpResponse<Link>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsLinksCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Link>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsLinksCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsLinksCreate$Json(params: {
    body: LinkRequest
  }): Observable<Link> {

    return this.v1NewsLinksCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Link>) => r.body as Link)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsLinksCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsLinksCreate$XWwwFormUrlencoded$Response(params: {
    body: LinkRequest
  }): Observable<StrictHttpResponse<Link>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsLinksCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Link>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsLinksCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsLinksCreate$XWwwFormUrlencoded(params: {
    body: LinkRequest
  }): Observable<Link> {

    return this.v1NewsLinksCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Link>) => r.body as Link)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsLinksCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsLinksCreate$FormData$Response(params: {
    body: LinkRequest
  }): Observable<StrictHttpResponse<Link>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsLinksCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Link>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsLinksCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsLinksCreate$FormData(params: {
    body: LinkRequest
  }): Observable<Link> {

    return this.v1NewsLinksCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Link>) => r.body as Link)
    );
  }

  /**
   * Path part for operation v1NewsLinksRetrieve
   */
  static readonly V1NewsLinksRetrievePath = '/api/v1/news/links/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsLinksRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsLinksRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Link.
     */
    id: number;
  }): Observable<StrictHttpResponse<Link>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsLinksRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Link>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsLinksRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsLinksRetrieve(params: {

    /**
     * A unique integer value identifying this Link.
     */
    id: number;
  }): Observable<Link> {

    return this.v1NewsLinksRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Link>) => r.body as Link)
    );
  }

  /**
   * Path part for operation v1NewsLinksUpdate
   */
  static readonly V1NewsLinksUpdatePath = '/api/v1/news/links/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsLinksUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsLinksUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Link.
     */
    id: number;
    body: LinkRequest
  }): Observable<StrictHttpResponse<Link>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsLinksUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Link>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsLinksUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsLinksUpdate$Json(params: {

    /**
     * A unique integer value identifying this Link.
     */
    id: number;
    body: LinkRequest
  }): Observable<Link> {

    return this.v1NewsLinksUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Link>) => r.body as Link)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsLinksUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsLinksUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Link.
     */
    id: number;
    body: LinkRequest
  }): Observable<StrictHttpResponse<Link>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsLinksUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Link>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsLinksUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsLinksUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Link.
     */
    id: number;
    body: LinkRequest
  }): Observable<Link> {

    return this.v1NewsLinksUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Link>) => r.body as Link)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsLinksUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsLinksUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Link.
     */
    id: number;
    body: LinkRequest
  }): Observable<StrictHttpResponse<Link>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsLinksUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Link>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsLinksUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsLinksUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Link.
     */
    id: number;
    body: LinkRequest
  }): Observable<Link> {

    return this.v1NewsLinksUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Link>) => r.body as Link)
    );
  }

  /**
   * Path part for operation v1NewsLinksDestroy
   */
  static readonly V1NewsLinksDestroyPath = '/api/v1/news/links/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsLinksDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsLinksDestroy$Response(params: {

    /**
     * A unique integer value identifying this Link.
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsLinksDestroyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsLinksDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsLinksDestroy(params: {

    /**
     * A unique integer value identifying this Link.
     */
    id: number;
  }): Observable<void> {

    return this.v1NewsLinksDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v1NewsLinksPartialUpdate
   */
  static readonly V1NewsLinksPartialUpdatePath = '/api/v1/news/links/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsLinksPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsLinksPartialUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Link.
     */
    id: number;
    body?: PatchedLinkRequest
  }): Observable<StrictHttpResponse<Link>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsLinksPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Link>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsLinksPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsLinksPartialUpdate$Json(params: {

    /**
     * A unique integer value identifying this Link.
     */
    id: number;
    body?: PatchedLinkRequest
  }): Observable<Link> {

    return this.v1NewsLinksPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Link>) => r.body as Link)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsLinksPartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsLinksPartialUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Link.
     */
    id: number;
    body?: PatchedLinkRequest
  }): Observable<StrictHttpResponse<Link>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsLinksPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Link>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsLinksPartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsLinksPartialUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Link.
     */
    id: number;
    body?: PatchedLinkRequest
  }): Observable<Link> {

    return this.v1NewsLinksPartialUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Link>) => r.body as Link)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsLinksPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsLinksPartialUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Link.
     */
    id: number;
    body?: PatchedLinkRequest
  }): Observable<StrictHttpResponse<Link>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsLinksPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Link>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsLinksPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsLinksPartialUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Link.
     */
    id: number;
    body?: PatchedLinkRequest
  }): Observable<Link> {

    return this.v1NewsLinksPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Link>) => r.body as Link)
    );
  }

  /**
   * Path part for operation v1NewsLinksLinkedinRetrieve
   */
  static readonly V1NewsLinksLinkedinRetrievePath = '/api/v1/news/links/linkedin/';

  /**
   * Helper function.
   * Retrieves a model instance of Linkedin embed link
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsLinksLinkedinRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsLinksLinkedinRetrieve$Response(params?: {
  }): Observable<StrictHttpResponse<Link>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsLinksLinkedinRetrievePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Link>;
      })
    );
  }

  /**
   * Helper function.
   * Retrieves a model instance of Linkedin embed link
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsLinksLinkedinRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsLinksLinkedinRetrieve(params?: {
  }): Observable<Link> {

    return this.v1NewsLinksLinkedinRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Link>) => r.body as Link)
    );
  }

  /**
   * Path part for operation v1NewsLinksLinkedinPartialUpdate
   */
  static readonly V1NewsLinksLinkedinPartialUpdatePath = '/api/v1/news/links/linkedin/';

  /**
   * Helper function.
   * Updates a model instance of Linkedin embed link.
   * Removes type from request data since this field is not allowed to be changed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsLinksLinkedinPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsLinksLinkedinPartialUpdate$Json$Response(params?: {
    body?: PatchedLinkRequest
  }): Observable<StrictHttpResponse<Link>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsLinksLinkedinPartialUpdatePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Link>;
      })
    );
  }

  /**
   * Helper function.
   * Updates a model instance of Linkedin embed link.
   * Removes type from request data since this field is not allowed to be changed
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsLinksLinkedinPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsLinksLinkedinPartialUpdate$Json(params?: {
    body?: PatchedLinkRequest
  }): Observable<Link> {

    return this.v1NewsLinksLinkedinPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Link>) => r.body as Link)
    );
  }

  /**
   * Helper function.
   * Updates a model instance of Linkedin embed link.
   * Removes type from request data since this field is not allowed to be changed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsLinksLinkedinPartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsLinksLinkedinPartialUpdate$XWwwFormUrlencoded$Response(params?: {
    body?: PatchedLinkRequest
  }): Observable<StrictHttpResponse<Link>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsLinksLinkedinPartialUpdatePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Link>;
      })
    );
  }

  /**
   * Helper function.
   * Updates a model instance of Linkedin embed link.
   * Removes type from request data since this field is not allowed to be changed
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsLinksLinkedinPartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsLinksLinkedinPartialUpdate$XWwwFormUrlencoded(params?: {
    body?: PatchedLinkRequest
  }): Observable<Link> {

    return this.v1NewsLinksLinkedinPartialUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Link>) => r.body as Link)
    );
  }

  /**
   * Helper function.
   * Updates a model instance of Linkedin embed link.
   * Removes type from request data since this field is not allowed to be changed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsLinksLinkedinPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsLinksLinkedinPartialUpdate$FormData$Response(params?: {
    body?: PatchedLinkRequest
  }): Observable<StrictHttpResponse<Link>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsLinksLinkedinPartialUpdatePath, 'patch');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Link>;
      })
    );
  }

  /**
   * Helper function.
   * Updates a model instance of Linkedin embed link.
   * Removes type from request data since this field is not allowed to be changed
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsLinksLinkedinPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsLinksLinkedinPartialUpdate$FormData(params?: {
    body?: PatchedLinkRequest
  }): Observable<Link> {

    return this.v1NewsLinksLinkedinPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Link>) => r.body as Link)
    );
  }

  /**
   * Path part for operation v1NewsMagazinesList
   */
  static readonly V1NewsMagazinesListPath = '/api/v1/news/magazines/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsMagazinesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsMagazinesList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-created_at' | '-updated_at' | 'created_at' | 'updated_at';
  }): Observable<StrictHttpResponse<PaginatedMagazineListList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsMagazinesListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('ordering', params.ordering, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedMagazineListList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsMagazinesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsMagazinesList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-created_at' | '-updated_at' | 'created_at' | 'updated_at';
  }): Observable<PaginatedMagazineListList> {

    return this.v1NewsMagazinesList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedMagazineListList>) => r.body as PaginatedMagazineListList)
    );
  }

  /**
   * Path part for operation v1NewsMagazinesCreate
   */
  static readonly V1NewsMagazinesCreatePath = '/api/v1/news/magazines/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsMagazinesCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsMagazinesCreate$Json$Response(params: {
    body: MagazineRequest
  }): Observable<StrictHttpResponse<Magazine>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsMagazinesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magazine>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsMagazinesCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsMagazinesCreate$Json(params: {
    body: MagazineRequest
  }): Observable<Magazine> {

    return this.v1NewsMagazinesCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Magazine>) => r.body as Magazine)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsMagazinesCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsMagazinesCreate$XWwwFormUrlencoded$Response(params: {
    body: MagazineRequest
  }): Observable<StrictHttpResponse<Magazine>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsMagazinesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magazine>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsMagazinesCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsMagazinesCreate$XWwwFormUrlencoded(params: {
    body: MagazineRequest
  }): Observable<Magazine> {

    return this.v1NewsMagazinesCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Magazine>) => r.body as Magazine)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsMagazinesCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsMagazinesCreate$FormData$Response(params: {
    body: MagazineRequest
  }): Observable<StrictHttpResponse<Magazine>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsMagazinesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magazine>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsMagazinesCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsMagazinesCreate$FormData(params: {
    body: MagazineRequest
  }): Observable<Magazine> {

    return this.v1NewsMagazinesCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Magazine>) => r.body as Magazine)
    );
  }

  /**
   * Path part for operation v1NewsMagazinesRetrieve
   */
  static readonly V1NewsMagazinesRetrievePath = '/api/v1/news/magazines/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsMagazinesRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsMagazinesRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Magazine.
     */
    id: number;
  }): Observable<StrictHttpResponse<MagazineList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsMagazinesRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MagazineList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsMagazinesRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsMagazinesRetrieve(params: {

    /**
     * A unique integer value identifying this Magazine.
     */
    id: number;
  }): Observable<MagazineList> {

    return this.v1NewsMagazinesRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<MagazineList>) => r.body as MagazineList)
    );
  }

  /**
   * Path part for operation v1NewsMagazinesUpdate
   */
  static readonly V1NewsMagazinesUpdatePath = '/api/v1/news/magazines/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsMagazinesUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsMagazinesUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Magazine.
     */
    id: number;
    body: MagazineRequest
  }): Observable<StrictHttpResponse<Magazine>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsMagazinesUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magazine>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsMagazinesUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsMagazinesUpdate$Json(params: {

    /**
     * A unique integer value identifying this Magazine.
     */
    id: number;
    body: MagazineRequest
  }): Observable<Magazine> {

    return this.v1NewsMagazinesUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Magazine>) => r.body as Magazine)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsMagazinesUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsMagazinesUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Magazine.
     */
    id: number;
    body: MagazineRequest
  }): Observable<StrictHttpResponse<Magazine>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsMagazinesUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magazine>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsMagazinesUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsMagazinesUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Magazine.
     */
    id: number;
    body: MagazineRequest
  }): Observable<Magazine> {

    return this.v1NewsMagazinesUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Magazine>) => r.body as Magazine)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsMagazinesUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsMagazinesUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Magazine.
     */
    id: number;
    body: MagazineRequest
  }): Observable<StrictHttpResponse<Magazine>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsMagazinesUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magazine>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsMagazinesUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsMagazinesUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Magazine.
     */
    id: number;
    body: MagazineRequest
  }): Observable<Magazine> {

    return this.v1NewsMagazinesUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Magazine>) => r.body as Magazine)
    );
  }

  /**
   * Path part for operation v1NewsMagazinesDestroy
   */
  static readonly V1NewsMagazinesDestroyPath = '/api/v1/news/magazines/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsMagazinesDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsMagazinesDestroy$Response(params: {

    /**
     * A unique integer value identifying this Magazine.
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsMagazinesDestroyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsMagazinesDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsMagazinesDestroy(params: {

    /**
     * A unique integer value identifying this Magazine.
     */
    id: number;
  }): Observable<void> {

    return this.v1NewsMagazinesDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v1NewsMagazinesPartialUpdate
   */
  static readonly V1NewsMagazinesPartialUpdatePath = '/api/v1/news/magazines/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsMagazinesPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsMagazinesPartialUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Magazine.
     */
    id: number;
    body?: PatchedMagazineRequest
  }): Observable<StrictHttpResponse<Magazine>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsMagazinesPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magazine>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsMagazinesPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsMagazinesPartialUpdate$Json(params: {

    /**
     * A unique integer value identifying this Magazine.
     */
    id: number;
    body?: PatchedMagazineRequest
  }): Observable<Magazine> {

    return this.v1NewsMagazinesPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Magazine>) => r.body as Magazine)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsMagazinesPartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsMagazinesPartialUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Magazine.
     */
    id: number;
    body?: PatchedMagazineRequest
  }): Observable<StrictHttpResponse<Magazine>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsMagazinesPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magazine>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsMagazinesPartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsMagazinesPartialUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Magazine.
     */
    id: number;
    body?: PatchedMagazineRequest
  }): Observable<Magazine> {

    return this.v1NewsMagazinesPartialUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Magazine>) => r.body as Magazine)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsMagazinesPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsMagazinesPartialUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Magazine.
     */
    id: number;
    body?: PatchedMagazineRequest
  }): Observable<StrictHttpResponse<Magazine>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsMagazinesPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magazine>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsMagazinesPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsMagazinesPartialUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Magazine.
     */
    id: number;
    body?: PatchedMagazineRequest
  }): Observable<Magazine> {

    return this.v1NewsMagazinesPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Magazine>) => r.body as Magazine)
    );
  }

  /**
   * Path part for operation v1NewsNoticesList
   */
  static readonly V1NewsNoticesListPath = '/api/v1/news/notices/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsNoticesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsNoticesList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-created_at' | '-id' | 'created_at' | 'id';

    /**
     * A search term.
     */
    search?: string;

    /**
     * Multiple values may be separated by commas.
     */
    tags__id__in?: Array<number>;

    /**
     * Multiple values may be separated by commas.
     */
    tags__id__not_in?: Array<number>;
    tags__name__icontains?: string;
  }): Observable<StrictHttpResponse<PaginatedNoticeListRetrieveList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsNoticesListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('ordering', params.ordering, {});
      rb.query('search', params.search, {});
      rb.query('tags__id__in', params.tags__id__in, {"style":"form","explode":false});
      rb.query('tags__id__not_in', params.tags__id__not_in, {"style":"form","explode":false});
      rb.query('tags__name__icontains', params.tags__name__icontains, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedNoticeListRetrieveList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsNoticesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsNoticesList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: '-created_at' | '-id' | 'created_at' | 'id';

    /**
     * A search term.
     */
    search?: string;

    /**
     * Multiple values may be separated by commas.
     */
    tags__id__in?: Array<number>;

    /**
     * Multiple values may be separated by commas.
     */
    tags__id__not_in?: Array<number>;
    tags__name__icontains?: string;
  }): Observable<PaginatedNoticeListRetrieveList> {

    return this.v1NewsNoticesList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedNoticeListRetrieveList>) => r.body as PaginatedNoticeListRetrieveList)
    );
  }

  /**
   * Path part for operation v1NewsNoticesCreate
   */
  static readonly V1NewsNoticesCreatePath = '/api/v1/news/notices/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsNoticesCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsNoticesCreate$Json$Response(params: {
    body: NoticeRequest
  }): Observable<StrictHttpResponse<Notice>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsNoticesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Notice>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsNoticesCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsNoticesCreate$Json(params: {
    body: NoticeRequest
  }): Observable<Notice> {

    return this.v1NewsNoticesCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Notice>) => r.body as Notice)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsNoticesCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsNoticesCreate$XWwwFormUrlencoded$Response(params: {
    body: NoticeRequest
  }): Observable<StrictHttpResponse<Notice>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsNoticesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Notice>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsNoticesCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsNoticesCreate$XWwwFormUrlencoded(params: {
    body: NoticeRequest
  }): Observable<Notice> {

    return this.v1NewsNoticesCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Notice>) => r.body as Notice)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsNoticesCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsNoticesCreate$FormData$Response(params: {
    body: NoticeRequest
  }): Observable<StrictHttpResponse<Notice>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsNoticesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Notice>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsNoticesCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsNoticesCreate$FormData(params: {
    body: NoticeRequest
  }): Observable<Notice> {

    return this.v1NewsNoticesCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Notice>) => r.body as Notice)
    );
  }

  /**
   * Path part for operation v1NewsNoticesRetrieve
   */
  static readonly V1NewsNoticesRetrievePath = '/api/v1/news/notices/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsNoticesRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsNoticesRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Notice.
     */
    id: number;
  }): Observable<StrictHttpResponse<NoticeListRetrieve>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsNoticesRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NoticeListRetrieve>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsNoticesRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsNoticesRetrieve(params: {

    /**
     * A unique integer value identifying this Notice.
     */
    id: number;
  }): Observable<NoticeListRetrieve> {

    return this.v1NewsNoticesRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<NoticeListRetrieve>) => r.body as NoticeListRetrieve)
    );
  }

  /**
   * Path part for operation v1NewsNoticesUpdate
   */
  static readonly V1NewsNoticesUpdatePath = '/api/v1/news/notices/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsNoticesUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsNoticesUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Notice.
     */
    id: number;
    body: NoticeRequest
  }): Observable<StrictHttpResponse<Notice>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsNoticesUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Notice>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsNoticesUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsNoticesUpdate$Json(params: {

    /**
     * A unique integer value identifying this Notice.
     */
    id: number;
    body: NoticeRequest
  }): Observable<Notice> {

    return this.v1NewsNoticesUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Notice>) => r.body as Notice)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsNoticesUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsNoticesUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Notice.
     */
    id: number;
    body: NoticeRequest
  }): Observable<StrictHttpResponse<Notice>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsNoticesUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Notice>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsNoticesUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsNoticesUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Notice.
     */
    id: number;
    body: NoticeRequest
  }): Observable<Notice> {

    return this.v1NewsNoticesUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Notice>) => r.body as Notice)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsNoticesUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsNoticesUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Notice.
     */
    id: number;
    body: NoticeRequest
  }): Observable<StrictHttpResponse<Notice>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsNoticesUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Notice>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsNoticesUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsNoticesUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Notice.
     */
    id: number;
    body: NoticeRequest
  }): Observable<Notice> {

    return this.v1NewsNoticesUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Notice>) => r.body as Notice)
    );
  }

  /**
   * Path part for operation v1NewsNoticesDestroy
   */
  static readonly V1NewsNoticesDestroyPath = '/api/v1/news/notices/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsNoticesDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsNoticesDestroy$Response(params: {

    /**
     * A unique integer value identifying this Notice.
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsNoticesDestroyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsNoticesDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1NewsNoticesDestroy(params: {

    /**
     * A unique integer value identifying this Notice.
     */
    id: number;
  }): Observable<void> {

    return this.v1NewsNoticesDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation v1NewsNoticesPartialUpdate
   */
  static readonly V1NewsNoticesPartialUpdatePath = '/api/v1/news/notices/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsNoticesPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsNoticesPartialUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Notice.
     */
    id: number;
    body?: PatchedNoticeRequest
  }): Observable<StrictHttpResponse<Notice>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsNoticesPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Notice>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsNoticesPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1NewsNoticesPartialUpdate$Json(params: {

    /**
     * A unique integer value identifying this Notice.
     */
    id: number;
    body?: PatchedNoticeRequest
  }): Observable<Notice> {

    return this.v1NewsNoticesPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Notice>) => r.body as Notice)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsNoticesPartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsNoticesPartialUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Notice.
     */
    id: number;
    body?: PatchedNoticeRequest
  }): Observable<StrictHttpResponse<Notice>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsNoticesPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Notice>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsNoticesPartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1NewsNoticesPartialUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Notice.
     */
    id: number;
    body?: PatchedNoticeRequest
  }): Observable<Notice> {

    return this.v1NewsNoticesPartialUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Notice>) => r.body as Notice)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1NewsNoticesPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsNoticesPartialUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Notice.
     */
    id: number;
    body?: PatchedNoticeRequest
  }): Observable<StrictHttpResponse<Notice>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1NewsNoticesPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Notice>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1NewsNoticesPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1NewsNoticesPartialUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Notice.
     */
    id: number;
    body?: PatchedNoticeRequest
  }): Observable<Notice> {

    return this.v1NewsNoticesPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Notice>) => r.body as Notice)
    );
  }

  /**
   * Path part for operation v1UsersGroupsList
   */
  static readonly V1UsersGroupsListPath = '/api/v1/users/groups/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1UsersGroupsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1UsersGroupsList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedGroupList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1UsersGroupsListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedGroupList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1UsersGroupsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1UsersGroupsList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedGroupList> {

    return this.v1UsersGroupsList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedGroupList>) => r.body as PaginatedGroupList)
    );
  }

  /**
   * Path part for operation v1UsersGroupsRetrieve
   */
  static readonly V1UsersGroupsRetrievePath = '/api/v1/users/groups/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1UsersGroupsRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1UsersGroupsRetrieve$Response(params: {

    /**
     * A unique integer value identifying this group.
     */
    id: number;
  }): Observable<StrictHttpResponse<Group>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1UsersGroupsRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Group>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1UsersGroupsRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1UsersGroupsRetrieve(params: {

    /**
     * A unique integer value identifying this group.
     */
    id: number;
  }): Observable<Group> {

    return this.v1UsersGroupsRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Group>) => r.body as Group)
    );
  }

  /**
   * Path part for operation v1UsersUsersList
   */
  static readonly V1UsersUsersListPath = '/api/v1/users/users/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1UsersUsersList()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1UsersUsersList$Response(params?: {
    is_recipient?: boolean;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedUserList>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1UsersUsersListPath, 'get');
    if (params) {
      rb.query('is_recipient', params.is_recipient, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedUserList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1UsersUsersList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1UsersUsersList(params?: {
    is_recipient?: boolean;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedUserList> {

    return this.v1UsersUsersList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedUserList>) => r.body as PaginatedUserList)
    );
  }

  /**
   * Path part for operation v1UsersUsersRetrieve
   */
  static readonly V1UsersUsersRetrievePath = '/api/v1/users/users/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1UsersUsersRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1UsersUsersRetrieve$Response(params: {

    /**
     * A unique integer value identifying this user.
     */
    id: number;
  }): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1UsersUsersRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1UsersUsersRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1UsersUsersRetrieve(params: {

    /**
     * A unique integer value identifying this user.
     */
    id: number;
  }): Observable<User> {

    return this.v1UsersUsersRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation v1UsersUsersMeRetrieve
   */
  static readonly V1UsersUsersMeRetrievePath = '/api/v1/users/users/me/';

  /**
   * Retrieves data for the current authenticated user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1UsersUsersMeRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1UsersUsersMeRetrieve$Response(params?: {
  }): Observable<StrictHttpResponse<UserUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1UsersUsersMeRetrievePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserUpdate>;
      })
    );
  }

  /**
   * Retrieves data for the current authenticated user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1UsersUsersMeRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1UsersUsersMeRetrieve(params?: {
  }): Observable<UserUpdate> {

    return this.v1UsersUsersMeRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<UserUpdate>) => r.body as UserUpdate)
    );
  }

  /**
   * Path part for operation v1UsersUsersMePartialUpdate
   */
  static readonly V1UsersUsersMePartialUpdatePath = '/api/v1/users/users/me/';

  /**
   * Partial update endpoint for current authenticated user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1UsersUsersMePartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1UsersUsersMePartialUpdate$Json$Response(params?: {
    body?: PatchedUserUpdateRequest
  }): Observable<StrictHttpResponse<UserUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1UsersUsersMePartialUpdatePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserUpdate>;
      })
    );
  }

  /**
   * Partial update endpoint for current authenticated user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1UsersUsersMePartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  v1UsersUsersMePartialUpdate$Json(params?: {
    body?: PatchedUserUpdateRequest
  }): Observable<UserUpdate> {

    return this.v1UsersUsersMePartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserUpdate>) => r.body as UserUpdate)
    );
  }

  /**
   * Partial update endpoint for current authenticated user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1UsersUsersMePartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1UsersUsersMePartialUpdate$XWwwFormUrlencoded$Response(params?: {
    body?: PatchedUserUpdateRequest
  }): Observable<StrictHttpResponse<UserUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1UsersUsersMePartialUpdatePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserUpdate>;
      })
    );
  }

  /**
   * Partial update endpoint for current authenticated user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1UsersUsersMePartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  v1UsersUsersMePartialUpdate$XWwwFormUrlencoded(params?: {
    body?: PatchedUserUpdateRequest
  }): Observable<UserUpdate> {

    return this.v1UsersUsersMePartialUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<UserUpdate>) => r.body as UserUpdate)
    );
  }

  /**
   * Partial update endpoint for current authenticated user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1UsersUsersMePartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1UsersUsersMePartialUpdate$FormData$Response(params?: {
    body?: PatchedUserUpdateRequest
  }): Observable<StrictHttpResponse<UserUpdate>> {

    const rb = new RequestBuilder(this.rootUrl, V1Service.V1UsersUsersMePartialUpdatePath, 'patch');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserUpdate>;
      })
    );
  }

  /**
   * Partial update endpoint for current authenticated user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `v1UsersUsersMePartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  v1UsersUsersMePartialUpdate$FormData(params?: {
    body?: PatchedUserUpdateRequest
  }): Observable<UserUpdate> {

    return this.v1UsersUsersMePartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<UserUpdate>) => r.body as UserUpdate)
    );
  }

}
