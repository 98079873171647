<div class="table-wrapper">
  <table class="table table-hover">
    <thead>
      <tr>
        <th *ngFor="let label of labels">
          {{label.label}}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of items">
        <ng-container *ngFor="let label of labels">
          <td *ngIf="label.key && !label.isActions && !label.isBoolean">
            {{label.isDate? (item[label.key] | date: 'dd.MM.YYYY') : item[label.key]}}</td>
          <td *ngIf="label.key && !label.isActions && label.isBoolean">
            <input type="checkbox" [(ngModel)]="item[label.key]" (change)="onCheck(item)">
          </td>
          <td class="actions text-right" *ngIf="label.isActions">
            <input *ngIf="checkable" class="activ" type="checkbox" name="active" [(ngModel)]="item.active"
              (change)="onCheck(item)">
            <div class="editable" *ngIf="editable">
              <span class="edit" (click)="onEdit(item)"></span>
              <span class="delete" (click)="onDelete(item)"></span>
            </div>
          </td>
          <td class="actions text-right" *ngIf="label.customButton">
            <a href="{{item[label.customButton.linkKey]}}" *ngIf="item[label.customButton.linkKey]" target="_blank"
              class="{{label.customButton.class}} btn">{{label.customButton.text}}</a>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>
