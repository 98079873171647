import { TypeofExpr } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-hover-table',
  templateUrl: './hover-table.component.html',
  styleUrls: ['./hover-table.component.scss']
})
export class HoverTableComponent implements OnInit {

  @Input() labels: HoverTableLabel[];
  @Input() items: any[];
  @Input() checkable: boolean;
  @Input() editable: boolean = false;

  @Output() check: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {

  }

  onEdit(item: any): void {
    this.edit.emit(item);
  }

  onDelete(item: any): void {
    this.delete.emit(item);
  }

  onCheck(item: any): void {
    this.check.emit(item);
  }

}

export interface HoverTableLabel {
  label?: string;
  key?: string;
  isActions?: boolean;
  isBoolean?: boolean;
  isDate?: boolean;
  customButton?: {linkKey: string, text: string, class: string}
}
