import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { image } from 'ngx-editor/schema/nodes';
import { Magazine, MagazineList } from 'src/app/api/models';
import { V1Service } from 'src/app/api/services';
import { AuthenticationService } from 'src/app/modules/core/services/authentication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-magazine-modal',
  templateUrl: './magazine-modal.component.html',
  styleUrls: ['./magazine-modal.component.scss']
})
export class MagazineModalComponent implements OnInit, AfterViewInit {

  form = this.fb.group({
    title: ['', Validators.required],
    description: ['', Validators.required],
    image: ['', Validators.required],
    document: ['', Validators.required]
  })

  submitted: boolean = false;
  initialImageFile: File;
  initialPdfFile: File;

  @ViewChild('imageDropzoneRef', { static: false }) imageDropzoneRef?: DropzoneComponent;
  @ViewChild('pdfDropzoneRef', { static: false }) pdfDropzoneRef?: DropzoneComponent;

  public configImg: DropzoneConfigInterface;

  public configPdf: DropzoneConfigInterface;

  constructor(
    public dialogRef: MatDialogRef<MagazineModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MagazineModalData,
    private fb: FormBuilder,
    private auth: AuthenticationService
  ) {
    if (environment.authType == 'basic') {
      this.configPdf = {
        headers: {
          "Authorization": `Basic ${this.auth.getAuthString()}`
        },
        acceptedFiles: 'application/pdf',
        maxFiles: 1,
        createImageThumbnails: false
      };

      this.configImg = {
        headers: {
          "Authorization": `Basic ${this.auth.getAuthString()}`
        },
        acceptedFiles: 'image/*',
        maxFiles: 1,
      };
    }
    else {
      this.configPdf = {
        headers: {
          'Authorization': `Bearer ${this.auth.accessToken}`,
          'X-CSRFToken': this.auth.getCSRFToken()
        },
        acceptedFiles: 'application/pdf',
        maxFiles: 1,
        createImageThumbnails: false
      };

      this.configImg = {
        headers: {
          'Authorization': `Bearer ${this.auth.accessToken}`,
          'X-CSRFToken': this.auth.getCSRFToken()
        },
        acceptedFiles: 'image/*',
        maxFiles: 1,
      };
    }
  }

  ngOnInit(): void {
    if (this.data.magazine) {
      this.form.patchValue(
        this.data.magazine
      );
      this.form.patchValue({
        image: this.data.magazine.image.id,
        document: this.data.magazine.document.id
      });
    }
  }

  ngAfterViewInit() {
    if (this.data.magazine) {

      let imageDropzone = this.imageDropzoneRef.directiveRef.dropzone();
      let pdfDropzone = this.pdfDropzoneRef.directiveRef.dropzone();

      const imageMockFile = { name: this.data.magazine.image?.file, size: this.data.magazine.image?.media_size };
      const pdfMockFile = { name: this.data.magazine.document?.file, size: this.data.magazine.document?.media_size };

      imageDropzone.emit( "addedfile", imageMockFile);
      imageDropzone.emit( "thumbnail", imageMockFile, this.data.magazine.image?.file);
      imageDropzone.emit( "complete", imageMockFile);

      pdfDropzone.emit( "addedfile", pdfMockFile);
      // pdfDropzone.emit( "thumbnail", pdfMockFile,  null);
      pdfDropzone.emit( "complete", pdfMockFile);
    }

  }

  onAccept(): void {
    this.submitted = true;
    if (!this.form.valid) {
      console.log(this.form);
      return;
    }

    this.dialogRef.close(this.form.value);
  }

  public onUploadInitDocument(args: any): void {
    console.log('onUploadInit:', args);
  }

  public onUploadErrorDocument(args: any): void {
    console.log('onUploadError:', args);
  }

  public onUploadSuccessDocument(args: any): void {
    console.log('onUploadSuccess:', args);

    if (args.length > 1) {
      this.form.controls.document.patchValue(args[1].id);
    }
  }

  public onUploadInitImage(args: any): void {
    console.log('onUploadInit:', args);
  }

  public onUploadErrorImage(args: any): void {
    console.log('onUploadError:', args);
  }

  public onUploadSuccessImage(args: any): void {
    console.log('onUploadSuccess:', args);

    if (args.length > 1) {
      this.form.controls.image.patchValue(args[1].id);
    }
  }

  onRemovedImage() {
    if (this.data.magazine) {
      this.form.controls.image.patchValue(this.data.magazine.image.id);
    }
    else {
      this.form.controls.image.patchValue(null);
    }
  }

  onRemovedPdf() {
    if (this.data.magazine) {
      this.form.controls.document.patchValue(this.data.magazine.document.id);
    }
    else {
      this.form.controls.document.patchValue(null);
    }
  }

}

export interface MagazineModalData {
  magazine?: MagazineList;
  buttonText?: string;
  title?: string;
}
