import { Component, Input, OnInit } from '@angular/core';
import { ArticleListRetrieve, Employee, Media, NoticeListRetrieve, Vehicle } from 'src/app/api/models';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {

  @Input() employees: Employee[];
  @Input() news: ArticleListRetrieve[];
  @Input() notices: NoticeListRetrieve[];
  @Input() documents: Media[];
  @Input() vehicles: Vehicle[];
  @Input() small: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
