import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Injector,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  Calendar,
  CalendarOptions,
  FullCalendarComponent,
} from '@fullcalendar/angular';
import slLocale from '@fullcalendar/core/locales/sl';
import { V1Service } from 'src/app/api/services';
import { take } from 'rxjs/operators';
import { Event, Occurrence, User } from 'src/app/api/models';
import { Router } from '@angular/router';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { PopoverWrapperComponent } from './popover-wrapper/popover-wrapper.component';
import * as moment from 'moment';

@Component({
  selector: 'app-calendar-box',
  templateUrl: './calendar-box.component.html',
  styleUrls: ['./calendar-box.component.scss'],
})
export class CalendarBoxComponent implements OnInit {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  @ViewChild('popoverTmpl', { static: true }) popoverTmpl: TemplateRef<any>;
  popoversMap = new Map<any, ComponentRef<PopoverWrapperComponent>>();

  popoverFactory = this.resolver.resolveComponentFactory(
    PopoverWrapperComponent
  );

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    locale: slLocale,
    headerToolbar: {
      start: 'prev',
      center: 'title',
      right: 'next',
    },
    height: 'auto',
    events: [],
    eventTimeFormat: {
      // like '14:30:00'
      hour: '2-digit',
      minute: '2-digit',
      meridiem: false,
    },
    eventDidMount: this.renderTooltip.bind(this),
    eventWillUnmount: this.destroyTooltip.bind(this),
    eventMouseEnter: this.showPopover.bind(this),
    eventMouseLeave: this.hidePopover.bind(this),
    eventClick: this.handleEventClick.bind(this),
    datesSet: this.handleDatesSet.bind(this)
  };

  occurences: Occurrence[];
  users: User[] = [];

  currentDate = null;

  constructor(
    private api: V1Service,
    private router: Router,
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {}

  ngOnInit(): void {
    this.currentDate = moment();
    this.fetchOccurences(this.currentDate);
    this.fetchUsers();
  }

  handleDatesSet(args) {
    console.log(args.view.getCurrentData());
    console.log(typeof args.view);

    this.currentDate = moment(args.view.getCurrentData().currentDate);

    this.fetchOccurences(this.currentDate);
  }

  fetchOccurences(date: any) {
    this.api
      .v1CalendarOccurrencesList({
        limit: 150,
        start_datetime__gte: moment(date).startOf('month').toISOString(),
        end_datetime__lte: moment(date).endOf('month').toISOString(),
      })
      .pipe(take(1))
      .subscribe((res) => {
        // this.events = res.results;
        this.occurences = res.results;

        let calendarEvents: any[] = res.results.map((o) => ({
          title: o.event.title,
          start: o.start_datetime,
          end: o.end_datetime,
          extendedProps: {
            eventObj: o.event,
          },
        }));

        this.calendarOptions.events = calendarEvents;
      });
  }

  fetchUsers() {
    this.api.v1UsersUsersList({
      limit: 1000
    }).pipe(take(1)).subscribe(
      res => {
        console.log(res.results);
        this.users = res.results;
      }
    )
  }

  getName(id: number) {
    let user = this.users.find((u) => u.id == id);
    if (!user) {
      return '';
    }
    return user?.first_name + ' ' + user?.last_name;
  }

  handleEventClick(arg) {
    this.router.navigateByUrl('/koledar');
  }

  renderTooltip(event) {
    const projectableNodes = Array.from(event.el.childNodes);

    const compRef = this.popoverFactory.create(
      this.injector,
      [projectableNodes],
      event.el
    );
    compRef.instance.template = this.popoverTmpl;

    this.appRef.attachView(compRef.hostView);
    this.popoversMap.set(event.el, compRef);
  }

  destroyTooltip(event) {
    const popover = this.popoversMap.get(event.el);
    if (popover) {
      this.appRef.detachView(popover.hostView);
      popover.destroy();
      this.popoversMap.delete(event.el);
    }
  }

  showPopover(event) {
    const popover = this.popoversMap.get(event.el);
    if (popover) {
      console.log(event);
      let events = this.occurences
        .filter(
          (o) =>
            moment(o.start_datetime).isSameOrAfter(
              moment(event.event.start),
              'days'
            ) &&
            moment(o.end_datetime).isSameOrBefore(
              moment(event.event.end),
              'days'
            )
        )
        .map((o) => o.event);

      console.log(events);
      popover.instance.popover.open({ events: events });
    }
  }

  hidePopover(event) {
    const popover = this.popoversMap.get(event.el);
    if (popover) {
      popover.instance.popover.close();
    }
  }
}
