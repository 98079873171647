import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthenticationService,
    private oauth: OAuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (environment.authType == 'basic') {
      let authString = localStorage.getItem('auth');
      request = request.clone({
        setHeaders: {
          Authorization: `Basic ${authString}`,
        }
      });
    } else {
      let access_token = this.oauth.getAccessToken();
      if (access_token != null && this.auth.hasValidToken()) {
        console.log(this.auth.getCSRFToken());
        // exclude discovery document request
        if (['openid-configuration', 'adfs/oauth2/token'].indexOf(request.url) < 0) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${access_token}`,
              'X-CSRFToken': this.auth.getCSRFToken()
            },
            withCredentials: true
          });
        }
      }
    }

    return next.handle(request);
  }
}
