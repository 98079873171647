import { OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from './modules/core/helpers/authConfig';
import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from './modules/core/services/authentication.service';
import { environment, oauth } from 'src/environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  title = 'porsche-intranet';
  $destroy = new Subject<boolean>();

  constructor(public auth: AuthenticationService,  public router: Router) {
    if(environment.authType == 'adfs') {
      // localStorage.setItem('returnUrl', this.router.url);
      this.auth.configure();
    }
    else {
      console.log("configure ", this.router.url);
      localStorage.setItem('returnUrl', this.router.url);
      this.auth.configureBasicAuth();
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.$destroy)
      )
      .subscribe((event: NavigationEnd) => {
        gtag('config', 'UA-197464445-1', {
          page_path: event.urlAfterRedirects,
        });
      });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
  }
}
