<div>
  <h2 mat-dialog-title>{{ data.title }} <a [mat-dialog-close]="false" class="modal-close-btn"></a></h2>
  <div mat-dialog-content class="text-center">
    <div class="confirmation-content">
      <div class="success-icon mb-5" *ngIf="!data.error">
        <img src="assets/image/check-circle.svg" alt="">
      </div>
      <h2 *ngIf="data.contentTitle">{{ data.contentTitle }}</h2>
      <p *ngIf="data.contentText">{{ data.contentText }}</p>
    </div>
  </div>
</div>
