<div class="footer-main">
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <h4>Uredništvo</h4>
          <div class="text : text2">
            <h5>Sabrina Pečelin, vodja odnosov z javnostmi</h5>
            <p class="mb-0">+ 386 (0)1 58 25 105</p>
            <p><a href="mailto:sabrina.pecelin@porsche.si">sabrina.pecelin@porsche.si</a></p>
          </div>
        </div>
        <div class="col-md-3">
          <h4 class="hideme">&nbsp;</h4>
          <div class="text : text2">
            <h5>Simona Podlipny, marketing Group Sales</h5>
            <p class="mb-0">+ 386 (0)1 58 25 217</p>
            <p><a href="mailto:simona.podlipny@porsche.si">simona.podlipny@porsche.si</a></p>
          </div>
        </div>
        <div class="col-md-3">
          <h4>Tehnična pomoč</h4>
          <div class="text : text2">
            <h5>Helpdesk</h5>
            <p><a target="_blank" href="https://podpornicenter.porsche.si">podpornicenter.porsche.si</a></p>
          </div>
        </div>
        <div class="col-md-3">
          <h4>Navodila</h4>
          <div class="text : text2">
            <p><a target="_blank"
                href="/application/ps/upload/userfiles/documents/PS_intranet_navodila_za_uporabo_VSI.pdf">Navodila za
                uporabo intraneta</a></p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="line">&nbsp;</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <h4>Hitre povezave</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg">
          <a href="http://www.poslo.si/" target="_blank">
            <h5>Porsche Slovenija</h5>
          </a>
          <a href="https://www.poslo.si/aktualno/revija-poslovni-carzine/"
            target="_blank">Revija Poslovni Carzine</a>
          <a href="https://www.poslo.si/medijsko-sredisce/sporocila-za-javnost/" target="_blank">Sporočila za
            javnost</a>
        </div>
        <div class="col-12 col-lg">
          <a href="http://www.volkswagen.si/" target="_blank">
            <h5>Volkswagen</h5>
          </a>
          <a href="https://vw-revija.si/" target="_blank">Revija Volkswagen</a>
        </div>
        <div class="col-12 col-lg">
          <a href="http://www.skoda.si/" target="_blank">
            <h5>Škoda</h5>
          </a>
          <a href="http://www.simplyclever.si/revije" target="_blank">Revija Simply Clever</a>
        </div>
        <div class="col-12 col-lg">
          <a href="http://www.audi.si/" target="_blank">
            <h5>Audi</h5>
          </a>
          <a href="https://intranet.poslo.si/pdf-viewer/AUDI_2-2018_1554714561" target="_blank">Revija Audi magazin</a>
        </div>
        <div class="col-12 col-lg">
          <a href="http://www.seat.si/" target="_blank">
            <h5>SEAT</h5>
          </a>
          <h5>&nbsp;</h5>
        </div>
        <div class="col-12 col-lg">
          <a href="http://www.vw-gospodarska.com/?pk_campaign=iPROM_Search" target="_blank">
            <h5>Volkswagen GV</h5>
          </a>
        </div>
        <div class="col-12 col-lg">
          <a href="https://www.cupraofficial.si/" target="_blank">
            <h5>CUPRA</h5>
          </a>
        </div>
        <div class="col-12 col-lg">
          <a href="https://dasweltauto.si" target="_blank">
            <h5>DasWeltAuto</h5>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6">
          <p>© Porsche Slovenija 2021. Vse pravice pridržane.</p>
        </div>
      </div>
    </div>
  </div>
</div>
