import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { take } from 'rxjs/operators';
import { Article, ArticleListRetrieve, Employee, Event, Link, Magazine, MagazineList, Media } from 'src/app/api/models';
import { V1Service } from 'src/app/api/services';
import { SidebarStoreService } from 'src/app/modules/core/services/sidebar-store.service';
import { LinkModalComponent, LinkModalData } from '../modals/link-modal/link-modal.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  constructor(public store: SidebarStoreService) {
    store.loadData();
  }

  ngOnInit(): void {
  }

}
