import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatGridTileHeaderCssMatStyler } from '@angular/material/grid-list';
import { NavigationEnd, Router } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime, filter, take, takeUntil } from 'rxjs/operators';
import {
  ArticleListRetrieve,
  Employee,
  Media,
  NoticeListRetrieve,
  Vehicle,
} from 'src/app/api/models';
import { V1Service } from 'src/app/api/services';
import { AuthenticationService } from 'src/app/modules/core/services/authentication.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit, OnDestroy {
  $destroy = new Subject<boolean>();
  searchTerm: string;

  employees: Employee[];
  news: ArticleListRetrieve[];
  notices: NoticeListRetrieve[];
  documents: Media[];
  vehicles: Vehicle[];

  @ViewChild('searchDropdown', { static: true }) searchDropdown: NgbDropdown;

  isCollapsed = true;

  searchForm = this.fb.group({
    search: [''],
  });

  openSearchResults = false;

  constructor(
    private fb: FormBuilder,
    private api: V1Service,
    private router: Router,
    public auth: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntil(this.$destroy)
      )
      .subscribe((e: NavigationEnd) => {
        this.isCollapsed = true;
      });

    this.searchForm.valueChanges
      .pipe(debounceTime(300), takeUntil(this.$destroy))
      .subscribe(async (res) => {
        this.fetchEmployees(res.search);
        this.fetchArticles(res.search);
        this.fetchNotices(res.search);
        this.fetchDocuments(res.search);
        this.fetchVehicles(res.search);

        this.openSearchResults = res.search != '';

        // if (this.searchDropdown) {
        //   this.searchDropdown.open();

        // }
      });
  }

  logout() {
    this.auth.logout();
  }

  onSearch() {
    this.router.navigate(['/iskalnik'], {
      queryParams: {
        search: this.searchForm.value.search,
      },
    });
  }

  fetchEmployees(search: string) {
    this.api
      .v1CompaniesEmployeesList({
        search: search,
      })
      .pipe(take(1))
      .subscribe((res) => {
        this.employees = res.results;
      });
  }

  fetchArticles(search: string) {
    this.api
      .v1NewsArticlesList({
        search: search,
        is_active: true,
      })
      .pipe(take(1))
      .subscribe((res) => {
        this.news = res.results;
      });
  }

  fetchNotices(search: string) {
    this.api
      .v1NewsNoticesList({
        search: search,
        ordering: '-created_at'
      })
      .pipe(take(1))
      .subscribe((res) => {
        this.notices = res.results;
      });
  }

  fetchDocuments(search: string) {
    this.api
      .v1CommonMediaList({
        included_in_directory: true,
        search: search,
      })
      .pipe(take(1))
      .subscribe((res) => {
        this.documents = res.results;
      });
  }

  fetchVehicles(search: string) {
    this.api
      .v1CarParkVehiclesList({
        search: search,
      })
      .pipe(take(1))
      .subscribe((res) => {
        this.vehicles = res.results;
      });
  }

  ngOnDestroy() {
    this.$destroy.next(true);
  }
}
