import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Employee } from 'src/app/api/models';

@Component({
  selector: 'app-employee-item',
  templateUrl: './employee-item.component.html',
  styleUrls: ['./employee-item.component.scss']
})
export class EmployeeItemComponent implements OnInit {

  @Input() employee: Employee;
  @Input() extendedInfo: boolean = true;
  @Input() editable = false;

  @Output() delete = new EventEmitter<Employee>();
  @Output() edit = new EventEmitter<Employee>();

  constructor() { }

  ngOnInit(): void {
  }

  onEdit() {
    this.edit.emit(this.employee);
  }

  onDelete() {
    this.delete.emit(this.employee);
  }

}
