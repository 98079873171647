<div class="container">
  <div class="{{class}} row">
    <div class="col-12 col-lg-8">
      <ng-content>
      </ng-content>
    </div>
    <div class="col-12 col-lg-4">
      <app-sidebar></app-sidebar>
    </div>
  </div>
</div>
