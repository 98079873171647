<div id="modal-window">
  <div mat-dialog-title>
    <h3>{{data.title || 'Uredi novico'}}</h3>
    <a [mat-dialog-close]="false" class="modal-close-btn"></a>
  </div>

  <div mat-dialog-content>
    <form action="" [formGroup]="form" [ngClass]="{'submitted' : submitted}">

      <ng-container *ngIf="form.value.type != 'linkedin'">
        <label>Naslov</label>
        <input type="text" formControlName="title">
      </ng-container>


      <label>{{form.value.type == 'linkedin'? 'Embed koda' : 'Uri'}}</label>
      <input type="text" formControlName="uri">

      <ng-container *ngIf="form.value.type != 'linkedin'">
        <label>Tip</label>
        <div class="type">
          <label>
            <input type="radio" value="hr" formControlName="type">
            <span>HR</span>
          </label>
          <label>
            <input type="radio" value="informatika" formControlName="type">
            <span>Informatika</span>
          </label>
          <label>
            <input type="radio" value="coc" formControlName="type">
            <span>Coc</span>
          </label>
          <label>
            <input type="radio" value="splosne_sluzbe" formControlName="type">
            <span>Splošne službe</span>
          </label>
          <!-- <label>
            <input type="radio" value="linkedin" formControlName="type">
            <span>LinkedIn Embed</span>
          </label> -->
        </div>
      </ng-container>
    </form>
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button mat-button (click)="onAccept()">{{data.buttonText || 'Dodaj povezavo'}}</button>
  </div>
</div>
