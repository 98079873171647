import { environment } from "src/environments/environment";

export class MyUploadAdapter {
  loader: any;
  xhr: any;
  authHeaders: {};
  constructor( loader, authHeaders ) {
      this.loader = loader;
      this.authHeaders = authHeaders
  }
  upload() {
      return this.loader.file
          .then( file => new Promise( ( resolve, reject ) => {
              this._initRequest();
              this._initListeners( resolve, reject, file );
              this._sendRequest( file );
          } ) );
  }
  abort() {
      if ( this.xhr ) {
          this.xhr.abort();
      }
  }
  _initRequest() {
      const xhr = this.xhr = new XMLHttpRequest();
      xhr.open( 'POST', `${environment.rootUrl}/api/v1/common/media/`, true ); // TODO change the URL
      xhr.responseType = 'json';
      xhr.setRequestHeader("Accept", "application/json");
      xhr.setRequestHeader("Authorization", this.authHeaders['Authorization']);
      
      if(this.authHeaders['X-CSRFToken']){
        xhr.setRequestHeader("X-CSRFToken", this.authHeaders['X-CSRFToken']);
      }
  }
  _initListeners( resolve, reject, file ) {
      const xhr = this.xhr;
      const loader = this.loader;
      const genericErrorText = `Couldn't upload file: ${ file.name }.`;
      xhr.addEventListener( 'error', () => reject( genericErrorText ) );
      xhr.addEventListener( 'abort', () => reject() );
      xhr.addEventListener( 'load', () => {
          const response = xhr.response;
          if ( !response || response.error ) {
              return reject( response && response.error ? response.error.message : genericErrorText );
          }
          resolve( {
              default: response.file
          } );
      } );
      if ( xhr.upload ) {
          xhr.upload.addEventListener( 'progress', evt => {
              if ( evt.lengthComputable ) {
                  loader.uploadTotal = evt.total;
                  loader.uploaded = evt.loaded;
              }
          } );
      }
  }
  _sendRequest( file ) {
      const data = new FormData();
      data.append( 'file', file );
      this.xhr.send( data );
  }
}
