import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { take, takeUntil } from 'rxjs/operators';
import { MagazineList, MagazineRequest } from 'src/app/api/models';
import { V1Service } from 'src/app/api/services';
import { AuthenticationService } from 'src/app/modules/core/services/authentication.service';
import { SidebarStoreService } from 'src/app/modules/core/services/sidebar-store.service';
import { ConfirmModalComponent, ConfirmModalData } from 'src/app/modules/shared/components/modals/confirm-modal/confirm-modal.component';
import { MagazineModalComponent, MagazineModalData } from 'src/app/modules/shared/components/modals/magazine-modal/magazine-modal.component';

@Component({
  selector: 'app-magazines-box',
  templateUrl: './magazines-box.component.html',
  styleUrls: ['./magazines-box.component.scss'],
})
export class MagazinesBoxComponent implements OnInit {
  config: SwiperConfigInterface = {
    slidesPerView: 1,
    direction: 'horizontal',
    autoplay: true,
    navigation: {
      nextEl: '.magazines-navigation .swiper-next',
      prevEl: '.magazines-navigation .swiper-prev',
    },
  };
  index: number = 0;

  magazines: MagazineList[] = [];

  constructor(
    private api: V1Service,
    private dialog: MatDialog,
    public auth: AuthenticationService,
    private store: SidebarStoreService
  ) {
    store.magazines$.subscribe(
      res => {
        if (res) {
          this.magazines = res;
        }
      }
    )
  }

  ngOnInit(): void {
    // this.fetchMagazines();
  }

  fetchMagazines() {
    this.api
      .v1NewsMagazinesList({
        ordering: '-created_at',
      })
      .pipe(take(1))
      .subscribe((res) => {
        this.magazines = res.results;
      });
  }

  onAdd() {
    let data: MagazineModalData = {
      buttonText: 'Dodaj revijo',
      title: 'Dodaj revijo',
    };

    const dialogRef = this.dialog.open(MagazineModalComponent, {
      data: data,
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (res) => {
        if (res) {
          console.log(res);
          this.api
            .v1NewsMagazinesCreate$FormData({
              body: res,
            })
            .pipe(take(1))
            .subscribe((res) => {
              this.store.fetchMagazines();
            });
        }
      });
  }

  onEdit() {
    let magazine = this.magazines[this.index];

    let data: MagazineModalData = {
      buttonText: 'Uredi revijo',
      title: 'Uredi revijo',
      magazine: magazine,
    };

    const dialogRef = this.dialog.open(MagazineModalComponent, {
      data: data,
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (res) => {
        if (res) {
          console.log(res);
          this.api
            .v1NewsMagazinesUpdate$FormData({
              id: magazine.id,
              body: res,
            })
            .pipe(take(1))
            .subscribe((res) => {
              console.log(res);
              this.store.fetchMagazines();
            });
        }
      });
  }

  onDelete() {
    let magazine = this.magazines[this.index];

    let data: ConfirmModalData = {
      title: 'Pozor',
      content: 'Revija bo izbrisana, želite nadaljevati?',
      buttonText: 'Zbriši',
    };
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: data,
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.api
            .v1NewsMagazinesDestroy({
              id: magazine.id,
            })
            .pipe(take(1))
            .subscribe((res) => {
              this.store.fetchMagazines();
            });
        }
      });
  }
}
