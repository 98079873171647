import { Component, Input, OnInit } from '@angular/core';
import { Notice, NoticeListRetrieve } from 'src/app/api/models';

@Component({
  selector: 'app-notices-item',
  templateUrl: './notices-item.component.html',
  styleUrls: ['./notices-item.component.scss']
})
export class NoticesItemComponent implements OnInit {

  @Input() notice: NoticeListRetrieve;

  constructor() { }

  ngOnInit(): void {
  }

}
