<aside class="dir-item" *ngIf="employee">
  <div class="editable" *ngIf="editable">
    <a href="" class="edit" (click)="$event.preventDefault(); onEdit()"></a>
    <a href="" class="delete" (click)="$event.preventDefault(); onDelete()"></a>
  </div>
  <div class="image"><img alt="" src="{{employee.avatar_thumbnail || 'assets/image/dir-no-person.jpg'}}"></div>
  <div class="text"><span>{{employee.first_name}} {{employee.last_name}}</span>
    <p name="title">{{employee.title}}</p>
    <ng-container *ngIf="extendedInfo">
      <div class="phones">
        <p name="tel" *ngIf="employee.phone_number"><strong>T</strong> {{employee.phone_number}}</p>
        <p *ngIf="employee.internal_phone_number"><strong>I</strong>{{employee.internal_phone_number}}</p>
      </div>
      <p name="fax" *ngIf="employee.fax_number"><strong>F</strong> {{employee.fax_number}}</p>
      <p name="mobile" *ngIf="employee.mobile_phone_number"><strong>M</strong> +{{employee.mobile_phone_number}}</p>
      <p name="mail" *ngIf="employee.email"><a href="mailto:{{employee.email}}">{{employee.email}}</a></p>
    </ng-container>
  </div>
</aside>
