import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-container-wide',
  templateUrl: './page-container-wide.component.html',
  styleUrls: ['./page-container-wide.component.scss']
})
export class PageContainerWideComponent implements OnInit {

  @Input() class: string;

  constructor() { }

  ngOnInit(): void {
  }

}
