<div class="magazine-item">
  <div class="editable" *ngIf="editable">
    <a href="" class="edit" (click)="$event.preventDefault(); onEdit()"></a>
    <a href="" class="delete" (click)="$event.preventDefault(); onDelete()"></a>
  </div>

  <div class="magazine-image">
    <img src="{{magazine.image?.file}}">
  </div>

  <div class="magazine-item-info">
      <span class="magazine-item-header text-uppercase">
          {{magazine.title}}
      </span>
      <p class="magazine-item-desc">
          {{magazine.description | slice:0:88}} ...
      </p>

      <a href="/pdf-viewer/{{magazine.document.id}}" target="_blank" class="magazine-item-link">
        Prelistaj
      </a>

      <!-- <a href="{{magazine.document.file}}" class="magazine-item-link">
          Prelistaj
      </a> -->
  </div>
</div>
