import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { V1Service } from 'src/app/api/services';
import { AuthenticationService } from 'src/app/modules/core/services/authentication.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit, OnDestroy {

  settingsForm = this.fb.group({
    article_alert: [true],
    gallery_alert: [true]
  });

  $destroy = new Subject<boolean>();

  constructor(public auth: AuthenticationService, private fb: FormBuilder, private api: V1Service) {

  }

  ngOnInit(): void {
    this.settingsForm.patchValue({
      article_alert: this.auth.userValue.article_alert,
      gallery_alert: this.auth.userValue.gallery_alert
    });

    this.settingsForm.valueChanges.pipe(
        takeUntil(this.$destroy)
      ).subscribe(
      res => {
        console.log(res);
        this.api.v1UsersUsersMePartialUpdate$Json({
          body: res
        }).pipe(take(1)).subscribe(
          res => {
            console.log(res);
          }
        )
      }
    )
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
  }

  logout() {
    this.auth.logout();
  }

}
