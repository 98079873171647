import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { take } from 'rxjs/operators';
import { Media } from 'src/app/api/models';
import { V1Service } from 'src/app/api/services';

@Component({
  selector: 'app-crop-image-modal',
  templateUrl: './crop-image-modal.component.html',
  styleUrls: ['./crop-image-modal.component.scss']
})
export class CropImageModalComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  form = this.fb.group({
    image: ['', Validators.required]
  })

  constructor(
    public dialogRef: MatDialogRef<CropImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CropImageModalData,
    private api: V1Service,
    private fb: FormBuilder
  ) {
    this.dialogRef.addPanelClass('crop-image-modal');
  }

  ngOnInit(): void {
    if (this.data.image) {
      this.form.patchValue({
        image: this.data.image?.id
      });
    }
  }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
      console.log(event, base64ToFile(event.base64));
  }

  imageLoaded() {
      this.showCropper = true;
      console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
      console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
      console.log('Load failed');
  }

  rotateLeft() {
      this.canvasRotation--;
      this.flipAfterRotate();
  }

  rotateRight() {
      this.canvasRotation++;
      this.flipAfterRotate();
  }

  private flipAfterRotate() {
      const flippedH = this.transform.flipH;
      const flippedV = this.transform.flipV;
      this.transform = {
          ...this.transform,
          flipH: flippedV,
          flipV: flippedH
      };
  }


  flipHorizontal() {
      this.transform = {
          ...this.transform,
          flipH: !this.transform.flipH
      };
  }

  flipVertical() {
      this.transform = {
          ...this.transform,
          flipV: !this.transform.flipV
      };
  }

  resetImage() {
      this.scale = 1;
      this.rotation = 0;
      this.canvasRotation = 0;
      this.transform = {};
  }

  zoomOut() {
    if (this.scale < 0.1) return;
      this.scale -= .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }

  zoomIn() {
      this.scale += .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }

  toggleContainWithinAspectRatio() {
      this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
      this.transform = {
          ...this.transform,
          rotate: this.rotation
      };
  }

  onAccept() {
    if (!this.croppedImage) {
      return;
    }

    console.log(base64ToFile(this.croppedImage));

    this.api.v1CommonMediaCreate$FormData({
      body: {
        file: new File([base64ToFile(this.croppedImage)], this.data.image?.id + '-cropped.png')
      }
    }).pipe(take(1)).subscribe(
      res => {
        if (res) {
          this.dialogRef.close(res);
        }
      }
    )

  }

}

export class CropImageModalData {
  image: Media;
}
