import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Media } from 'src/app/api/models/media';

@Component({
  selector: 'app-eco',
  templateUrl: './eco.component.svg',
  styleUrls: ['./eco.component.scss']
})
export class EcoComponent implements OnInit {

  @Input() percent: number = 0;
  @Input() editable = false;

  bar_max_width: number = 511;
  bar_min_width: number = 0;
  bar_width: number = 0;
  bar_fill: string = "#005215";
  text_fill: string = "white";
  percent_x: number = 0;
  bar_path: string = "";
  bar_shadow_path: string = "";
  test = "test123";
  tag_pos = 0;
  tag_transform = "translate(-36)";

  constructor() { }

  ngOnInit(): void {

    if(this.percent >= 0 && this.percent < 25){
      this.bar_fill = "#f2000d";
      this.text_fill = "white";
    }

    if(this.percent >= 25 && this.percent < 50){
      this.bar_fill = "#ffff00";
      this.text_fill = "black";
    }

    if(this.percent >= 50 && this.percent < 90){
      this.bar_fill = "#ccff66";
      this.text_fill = "black";
    }

    if(this.percent >= 90 && this.percent < 101){
      this.bar_fill = "#009900";
      this.text_fill = "white";
    }

    this.bar_width = (this.bar_max_width - this.bar_min_width)/100 * this.percent + this.bar_min_width;
    this.percent_x = 3 + this.bar_width/2 - 5;    
  }

  ngOnChanges(){

    if(this.percent >= 0 && this.percent < 25){
      this.bar_fill = "#f2000d";
      this.text_fill = "white";
    }

    if(this.percent >= 25 && this.percent < 50){
      this.bar_fill = "#ffff00";
      this.text_fill = "black";
    }

    if(this.percent >= 50 && this.percent < 90){
      this.bar_fill = "#ccff66";
      this.text_fill = "black";
    }

    if(this.percent >= 90 && this.percent < 101){
      this.bar_fill = "#009900";
      this.text_fill = "white";
    }

    this.bar_width = (this.bar_max_width - this.bar_min_width)/100 * this.percent + this.bar_min_width;
    this.percent_x = 3 + this.bar_width/2 - 5;    
  }
}
