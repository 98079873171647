<app-content-box class="magazines">
  <div class="title">
    <h2>Življenjski slog</h2>
    <a class="archive" [routerLink]="['/novice']" [queryParams]="{tags_in: '7'}">Vse novice</a>
  </div>
  <aside>
    <app-news-item [showReadMore]="true" [article]="focusedArticle" [hoverAnimation]="false" [showIntro]="true"
      [showTags]="false" [showImageRadius]="false" [noPad]="true"></app-news-item>
  </aside>
</app-content-box>
