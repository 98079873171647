import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { UserUpdate } from 'src/app/api/models';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthenticationService) {}

  isInGroup(user: UserUpdate, groups: string[]) {
    return groups.some(
      (group) =>
        user.groups.findIndex(
          (userGroup) =>
            group == userGroup.name || userGroup.name == 'porsche_admin'
        ) > -1
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    return this.auth.isDoneLoading$.pipe(
      filter(done => done),
      switchMap(_ => {
        return this.auth.user$;
      }),
      map((user) => {
        console.log("done loading ", user, route.data);
        if (!user) {
          this.auth.login();
          return false;
        }
        if (route.data.groups) {
          if (!this.isInGroup(user, route.data.groups)) {
            // role not authorised so redirect to home page
            this.router.navigate(['/']);
            return false;
          }
        }
        // authorised so return true
        return true;
      })
    )


    // let user = this.auth.userValue;

    // if (user) {
    //   if (!user) {
    //     localStorage.setItem('returnUrl', state.url);
    //     this.router.navigate(['/prijava'], {
    //       queryParams: { returnUrl: state.url }
    //     });
    //     return of(false);
    //   }
    //   if (route.data.groups) {
    //     if (!this.isInGroup(user, route.data.groups)) {
    //       // role not authorised so redirect to home page
    //       this.router.navigate(['/']);
    //       return of(false);
    //     }
    //   }
    //   // authorised so return true
    //   return of(true);
    // }

    // return this.auth.fetchCurrentUser().pipe(
    //   take(1),
    //   map(
    //     (user) => {
    //       if (!user) {
    //         localStorage.setItem('returnUrl', state.url);
    //         this.router.navigate(['/prijava'], {
    //           queryParams: { returnUrl: state.url }
    //         });
    //         return false;
    //       }
    //       if (route.data.groups) {
    //         if (!this.isInGroup(user, route.data.groups)) {
    //           // role not authorised so redirect to home page
    //           this.router.navigate(['/']);
    //           return false;
    //         }
    //       }
    //       // authorised so return true
    //       return true;
    //     },
    //   ),
    //   catchError((err) => {
    //     console.log("error in auth guard user fetch", err);
    //     localStorage.setItem('returnUrl', state.url);
    //     this.router.navigate(['/prijava'], {
    //       queryParams: { returnUrl: state.url }
    //     });
    //     return of(false);
    //   })
    // );
  }
}
