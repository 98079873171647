<div class="sidebar">
  <app-employee-box *ngIf="store.employeesValue.length > 0"></app-employee-box>

  <app-article-box *ngIf="!store.employeesValue || store.employeesValue.length < 1"></app-article-box>

  <app-linkedin-box></app-linkedin-box>

  <app-magazines-box></app-magazines-box>

  <app-calendar-box></app-calendar-box>

  <app-link-box></app-link-box>

</div>
