import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { ArticleListRetrieve } from 'src/app/api/models';
import { V1Service } from 'src/app/api/services';
import { SidebarStoreService } from 'src/app/modules/core/services/sidebar-store.service';

@Component({
  selector: 'app-article-box',
  templateUrl: './article-box.component.html',
  styleUrls: ['./article-box.component.scss']
})
export class ArticleBoxComponent implements OnInit {

  focusedArticle: ArticleListRetrieve;

  constructor(private api: V1Service, private store: SidebarStoreService) {
    store.focusedArticle$.subscribe(res => {
      if (res) {
        this.focusedArticle = res;
      }
    })
  }

  ngOnInit(): void {
    // this.fetchFocusedArticle();
  }

  fetchFocusedArticle() {
    //to do, tag__in 7
    this.api.v1NewsArticlesList({
      limit: 1,
      tags__id__in: [7],
      ordering: '-created_at'
    }).pipe(take(1)).subscribe(
      res => {
        this.focusedArticle = res.results[0];
      }
    )
  }

}
