import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Media } from 'src/app/api/models/media';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit {

  @Input() file: Media;
  @Input() editable = false;

  @Output() download: EventEmitter<Media> = new EventEmitter<Media>();
  @Output() getUrl: EventEmitter<Media> = new EventEmitter<Media>();
  @Output() edit: EventEmitter<Media> = new EventEmitter<Media>();
  @Output() move: EventEmitter<Media> = new EventEmitter<Media>();
  @Output() delete: EventEmitter<Media> = new EventEmitter<Media>();

  constructor() { }

  ngOnInit(): void {
  }

  onDownload(event: any) {
    // event.preventDefault();
    this.download.emit(this.file || null);
  }

  onGetUrl(event: any) {
    event.preventDefault();
    this.getUrl.emit(this.file || null);
  }

  onEdit(event: any) {
    event.preventDefault();
    this.edit.emit(this.file || null);
  }

  onMove(event: any) {
    event.preventDefault();
    this.move.emit(this.file || null);
  }

  onDelete(event: any) {
    event.preventDefault();
    this.delete.emit(this.file || null);
  }

  trimExtension(x: string): string {
    return x.replace(/\.[^/.]+$/, "")
  }

  getExtension(x: string): string {
    return (/(?:\.([^.]+))?$/).exec(x)[1] || "";
  }

  getFileType(x: string): string {
    let ext = this.getExtension(x);

    if (["doc", "dot", "docx", "dotx", "docb"].includes(ext)) return "word";
    if (["jpg","jpeg","gif","png","bmp", "jpe", "svg", "rgb"].includes(ext)) return "image";
    if (["xla", "xlc", "xlm", "xls", "xlt", "xlw", "xlsx", "xltx"].includes(ext)) return "excel";
    if (["ppt", "pot", "pps", "pptx", "potx", "ppam", "ppsx", "sldx"].includes(ext)) return "ppt";
    if (["pdf"].includes(ext)) return "pdf";
    if (["msg", "mpp", "wcm", "wdb", "wks", "wps", "tex", "dvi", "txt", "zip", "rar"].includes(ext)) return "dat";
    if (["mpeg", "mpg", "mpv2", "mov", "avi"].includes(ext)) return "video";
    if (["mp3", "m3u", "wav", "mp2", "mpa", "mpe"].includes(ext)) return "sound";

    return "none";
  }
}
