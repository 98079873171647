import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MagazineList } from 'src/app/api/models';

@Component({
  selector: 'app-magazine-item',
  templateUrl: './magazine-item.component.html',
  styleUrls: ['./magazine-item.component.scss']
})
export class MagazineItemComponent implements OnInit {

  @Input() magazine: MagazineList;
  @Input() editable = false;
  @Output() edit = new EventEmitter<MagazineList>();
  @Output() delete = new EventEmitter<MagazineList>();

  constructor() { }

  ngOnInit(): void {

  }

  onEdit() {
    this.edit.emit(this.magazine);
  }

  onDelete() {
    this.delete.emit(this.magazine);

  }

}
