import { Component, Input, OnInit } from '@angular/core';
import { Article, ArticleListRetrieve } from 'src/app/api/models';

@Component({
  selector: 'app-news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.scss']
})
export class NewsItemComponent implements OnInit {

  @Input() article: ArticleListRetrieve;
  @Input() horizontal = false;
  @Input() showIntro = false;
  @Input() showTags = true;
  @Input() hoverAnimation = true;
  @Input() showImageRadius = true;
  @Input() noPad = false;
  @Input() showReadMore = false;

  constructor() { }

  ngOnInit(): void {
  }

}
