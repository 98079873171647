import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'organizacijski-prirocnik',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/organization-manual/organization-manual.module').then(
        (m) => m.OrganizationManualModule
      ),
  },
  {
    path: 'imenik',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/contacts/contacts.module').then(
        (m) => m.ContactsModule
      ),
  },
  {
    path: 'foto-in-video-galerija',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modules/photo-and-video-gallery/photo-and-video-gallery.module'
      ).then((m) => m.PhotoAndVideoGalleryModule),
  },
  {
    path: 'coc',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/coc/coc.module').then((m) => m.CocModule),
  },
  {
    path: 'novice',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/news/news.module').then((m) => m.NewsModule),
  },
  {
    path: 'vozni-park',
    canActivate: [AuthGuard],
    data: {groups: ['car_park_manager']},
    loadChildren: () =>
      import('./modules/driving-park/driving-park.module').then(
        (m) => m.DrivingParkModule
      ),
  },
  {
    path: 'koledar',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/calendar/calendar.module').then(
        (m) => m.CalendarModule
      ),
  },
  {
    path: 'obvestila',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/notices/notices.module').then((m) => m.NoticesModule),
  },
  {
    path: 'revije',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/magazines/magazines.module').then(
        (m) => m.MagazinesModule
      ),
  },
  {
    path: 'iskalnik',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/search/search.module').then((m) => m.SearchModule),
  },
  {
    path: 'pdf-viewer',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/pdf-viewer/pdf-viewer.module').then(
        (m) => m.PdfViewerModule
      ),
  },
  {
    path: 'prijava',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'token',
    loadChildren: () =>
      import('./modules/token/token.module').then((m) => m.TokenModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  { path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy', useHash: false }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
