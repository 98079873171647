import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';
import { environment, oauth } from 'src/environments/environment';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { UserUpdate } from 'src/app/api/models';
import { V1Service } from 'src/app/api/services';
import { authCodeFlowConfig } from '../helpers/authConfig';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private userSubject$: BehaviorSubject<UserUpdate> =
    new BehaviorSubject<UserUpdate>(null);
  public user$: Observable<UserUpdate> = this.userSubject$.asObservable();

  public get userValue(): UserUpdate {
    return this.userSubject$.value;
  }

  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  public navigateToLoginPage(returnUrl?: string) {
    localStorage.setItem('returnUrl', this.router.url);
    this.router.navigate(['/prijava'], {
      queryParams: {
        returnUrl: returnUrl ?? '/',
      },
    });
  }

  constructor(
    private router: Router,
    private api: V1Service,
    private oauthService: OAuthService,
    private route: ActivatedRoute
  ) {
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.setStorage(localStorage);

    // Useful for debugging:
    this.oauthService.events.subscribe((event) => {
      if (event instanceof OAuthErrorEvent) {
        console.error('OAuthErrorEvent Object:', event);
      } else {
        console.warn('OAuthEvent Object:', event);
      }
    });

    // on localstorage change
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }
      console.warn(
        'Noticed changes to access_token (most likely from another tab), updating isAuthenticated'
      );

      if (!this.oauthService.hasValidAccessToken()) {
        this.logout();
      }
    });

    // debugging every event
    // this.oauthService.events.subscribe((_) => {
    //   console.log('any event: ', _);
    //   this.isAuthenticatedSubject$.next(
    //     this.oauthService.hasValidAccessToken()
    //   );
    // });

    // On receive token
    this.oauthService.events
      .pipe(
        filter((e) => ['token_received', 'token_refreshed'].includes(e.type))
      )
      .subscribe((e) => {
        console.log(
          'token received ',
          this.oauthService.getAccessToken()
        );
        this.fetchCurrentUser().subscribe((res) => {
          let returnUrl = localStorage.getItem('returnUrl');
          this.router.navigateByUrl(returnUrl || '/');
        });
      });

    // On session over
    this.oauthService.events
      .pipe(
        filter((e) => ['session_terminated', 'session_error'].includes(e.type))
      )
      .subscribe((e) => {
        this.logout();
      });

    this.oauthService.events
      .pipe(
        filter((e) => e instanceof OAuthErrorEvent)
      )
      .subscribe((e) => {
        this.logout();
      });
  }

  public configure() {
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.setStorage(localStorage);
    this.oauthService.setupAutomaticSilentRefresh();

    this.fetchCurrentUser()
      .pipe(
        take(1),
        catchError((err) => {
          // console.log("error in configure ", err);
          this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
            this.isDoneLoadingSubject$.next(true);
          }).catch(() => this.isDoneLoadingSubject$.next(true));
          return of(false);
        }),
        filter(data => data != false)
      )
      .subscribe((res) => {
        // console.log("data in configure", res);
        this.isDoneLoadingSubject$.next(true);
      });
  }

  public configureBasicAuth() {
    console.log(this.router.url)
    localStorage.setItem('returnUrl', this.router.url);
    this.fetchCurrentUser()
      .pipe(
        take(1),
        catchError((err) => {
          this.isDoneLoadingSubject$.next(true);
          this.navigateToLoginPage();
          return of(false);
        }),
        filter(data => data != false)
      )
      .subscribe((res) => {
        let returnUrl = localStorage.getItem('returnUrl');
        // this.router.navigateByUrl(returnUrl || '/');
        this.isDoneLoadingSubject$.next(true);
      });
  }



  public fetchCurrentUser(): Observable<UserUpdate> {
    return this.api.v1UsersUsersMeRetrieve().pipe(
      take(1),
      map((user) => {
        console.log(user);
        if (user) {
          this.userSubject$.next(user);
        } else {
          this.userSubject$.next(null);
        }
        return this.userValue;
      })
    );
  }

  public login() {
    localStorage.setItem('returnUrl', this.router.url);
    this.oauthService.initCodeFlow();
  }

  public logout() {
    this.userSubject$.next(null);
    if (environment.authType == 'adfs') {
      this.oauthService.logOut();
      window.location.href = this.logoutUrl;
    } else {
      localStorage.removeItem('auth');
      this.router.navigate(['/prijava']);
    }
  }

  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() {
    return this.oauthService.getAccessToken();
  }
  public get refreshToken() {
    return this.oauthService.getRefreshToken();
  }
  public get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }
  public get idToken() {
    return this.oauthService.getIdToken();
  }
  public get logoutUrl() {
    return environment.logoutURL;
  }

  public getAuthString(): string {
    return localStorage.getItem('auth');
  }

  public isCalendarManager(): boolean {
    return (
      this.userValue?.groups.findIndex(
        (g) => g.name == 'calendar_manager' || g.name == 'porsche_admin'
      ) > -1
    );
  }

  public isCarParkManager(): boolean {
    return (
      this.userValue?.groups.findIndex(
        (g) => g.name == 'car_park_manager' || g.name == 'porsche_admin'
      ) > -1
    );
  }

  public isContentManager(): boolean {
    return (
      this.userValue?.groups.findIndex(
        (g) => g.name == 'content_manager' || g.name == 'porsche_admin'
      ) > -1
    );
  }

  public isExternalUser(): boolean {
    return (
      this.userValue?.groups.findIndex((g) => g.name == 'external_user') > -1
    );
  }

  public isFileManager(): boolean {
    return (
      this.userValue?.groups.findIndex(
        (g) => g.name == 'file_manager' || g.name == 'porsche_admin'
      ) > -1
    );
  }

  public isPorscheAdmin(): boolean {
    return (
      this.userValue?.groups.findIndex((g) => g.name == 'porsche_admin') > -1
    );
  }

  public isUser(): boolean {
    return this.userValue?.groups.findIndex((g) => g.name == 'user') > -1;
  }

  public get isLoggedIn(): boolean {
    return this.userValue != null;
  }

  /*basic auth compatibility*/
  basicLogin(email: string, password: string) {
    localStorage.setItem('auth', btoa(`${email}:${password}`));
    return this.fetchCurrentUser();
  }

  public getCSRFToken() {
    return this.getCookie('csrftoken');
  }

  private getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
}
