import { environment, oauth } from '../../../../environments/environment';
import { AuthConfig } from 'angular-oauth2-oidc';

export const authCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: oauth.issuer,
  redirectUri: oauth.redirectURI,
  clientId: oauth.clientId,
  // loginUrl: environment.loginURL,
  // logoutUrl: environment.logoutURL,

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  // dummyClientSecret: 'secret',

  responseType: 'code',

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: 'openid profile email offline_access',

  showDebugInformation: true,
  requireHttps: false,

  resource: oauth.resource,

  customQueryParams: {
    // Your API's name
    audience: oauth.audience,
  },

  tokenEndpoint: oauth.issuer + '/oauth2/token',
  customTokenParameters: [
    'email',
    'given_name',
    'family_name',
    'group',
    'winaccountname',
  ],
};
