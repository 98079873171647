import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { Link } from 'src/app/api/models';
import { V1Service } from 'src/app/api/services';
import { AuthenticationService } from 'src/app/modules/core/services/authentication.service';
import { SidebarStoreService } from 'src/app/modules/core/services/sidebar-store.service';
import { ConfirmModalComponent, ConfirmModalData } from 'src/app/modules/shared/components/modals/confirm-modal/confirm-modal.component';
import { LinkModalComponent, LinkModalData } from 'src/app/modules/shared/components/modals/link-modal/link-modal.component';

@Component({
  selector: 'app-link-box',
  templateUrl: './link-box.component.html',
  styleUrls: ['./link-box.component.scss'],
})
export class LinkBoxComponent implements OnInit {
  links: Link[];
  currentType: string = "hr";

  constructor(private api: V1Service, private dialog: MatDialog, public auth: AuthenticationService, private store: SidebarStoreService) {
    store.links$.subscribe(res => {
      if(res) {
        this.links = res;
      }
    })
  }

  ngOnInit(): void {
    // this.fetchLinks();
  }

  fetchLinks() {
    this.api
      .v1NewsLinksList()
      .pipe(take(1))
      .subscribe((res) => {
        this.links = res.results;
      });
  }

  onAddLink() {
    let data: LinkModalData = {
      buttonText: 'Dodaj',
      title: 'Dodaj povezavo'
    };
    const dialogRef = this.dialog.open(LinkModalComponent, {
      data: data
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.api
            .v1NewsLinksCreate$Json({
              body: res
            })
            .pipe(take(1))
            .subscribe((link) => {

              this.store.fetchLinks();

              // this.links.push(link);
            });
        }
      });
  }

  onTypeChange(type: string) {
    this.currentType = type;
  }

  filterLinks(type: string) {
    return this.links.filter(l => l.type == type);
  }

  onEditLink(link: Link) {
    let data: LinkModalData = {
      buttonText: 'Uredi',
      link: link,
      title: 'Uredi povezavo'
    };
    const dialogRef = this.dialog.open(LinkModalComponent, {
      data: data
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.api
            .v1NewsLinksPartialUpdate$FormData({
              id: link.id,
              body: res
            })
            .pipe(take(1))
            .subscribe((link) => {
              this.store.fetchLinks();
            });
        }
      });
  }

  onDeleteLink(link: Link) {
    let data: ConfirmModalData = {
      buttonText: 'Zbriši',
      title: 'Pozor',
      content: 'Povezava bo trajno zbrisana, ste prepričani?',
      buttonClass: 'btn-red',
    };
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: data,
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.api
            .v1NewsLinksDestroy({
              id: link.id,
            })
            .pipe(take(1))
            .subscribe((res) => {
              this.store.fetchLinks();
            });
        }
      });
  }
}
