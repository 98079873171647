import { Component, Input, OnInit } from '@angular/core';
import { Article, ArticleListRetrieve } from 'src/app/api/models';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {

  @Input() news: ArticleListRetrieve[];
  @Input() firstWide = false;
  @Input() showTags = true;
  @Input() showTagsForFirst = true;

  constructor() { }

  ngOnInit(): void {
  }

}
