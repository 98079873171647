import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentBoxComponent } from './components/content-box/content-box.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgxFileDropModule } from 'ngx-file-drop';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmModalComponent } from './components/modals/confirm-modal/confirm-modal.component';
import { FileSelectComponent } from './components/file-select/file-select.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { HoverTableComponent } from './components/hover-table/hover-table.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_LOCALE,
  MAT_RIPPLE_GLOBAL_OPTIONS,
} from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { NgxPaginationModule } from 'ngx-pagination';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NewsItemComponent } from './components/news-item/news-item.component';
import { NewsListComponent } from './components/news-list/news-list.component';
import { NoticesListComponent } from './components/notices-list/notices-list.component';
import { NoticesItemComponent } from './components/notices-item/notices-item.component';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { FileUrlModalComponent } from './components/modals/file-url-modal/file-url-modal.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ImageUploadPreviewComponent } from './components/image-upload-preview/image-upload-preview.component';
import { VideoModalComponent } from './components/modals/video-modal/video-modal.component';
import { HttpErrorInterceptor } from 'src/app/modules/core/interceptors/http-error.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { NgxEditorModule } from 'ngx-editor';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TagListComponent } from './components/tag-list/tag-list.component';
import { TagsFilterComponent } from './components/tags-filter/tags-filter.component';
import { NewsItemModalComponent } from './components/modals/news-item-modal/news-item-modal.component';
import { AuthInterceptor } from 'src/app/modules/core/interceptors/auth.interceptor';
import { NgbModule, NgbPopover, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { LinkModalComponent } from './components/modals/link-modal/link-modal.component';
import { MagazineItemComponent } from './components/magazine-item/magazine-item.component';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';
import { EmployeeItemComponent } from './components/employee-item/employee-item.component';
import { MagazineModalComponent } from './components/modals/magazine-modal/magazine-modal.component';
import { FileComponent } from './components/file/file.component';
import { EcoComponent } from './components/eco/eco.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { DropzoneModule, DropzoneConfigInterface, DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { environment } from 'src/environments/environment';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CropImageModalComponent } from './components/modals/crop-image-modal/crop-image-modal.component';
import { OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { PageContainerWideComponent } from './components/page-container-wide/page-container-wide.component';
import { PageContainerComponent } from './components/page-container/page-container.component';
import { PageWithSidebarContainerComponent } from './components/page-with-sidebar-container/page-with-sidebar-container.component';
import { ArticleBoxComponent } from './components/sidebar/boxes/article-box/article-box.component';
import { CalendarBoxComponent } from './components/sidebar/boxes/calendar-box/calendar-box.component';
import { PopoverWrapperComponent } from './components/sidebar/boxes/calendar-box/popover-wrapper/popover-wrapper.component';
import { EmployeeBoxComponent } from './components/sidebar/boxes/employee-box/employee-box.component';
import { LinkBoxComponent } from './components/sidebar/boxes/link-box/link-box.component';
import { LinkedinBoxComponent } from './components/sidebar/boxes/linkedin-box/linkedin-box.component';
import { MagazinesBoxComponent } from './components/sidebar/boxes/magazines-box/magazines-box.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ConfirmationOrErrorModalComponent } from './components/modals/confirmation-or-error-modal/confirmation-or-error-modal.component';
import { CustomDateAdapter } from '../core/helpers/custom-date-adapter';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: `${environment.rootUrl}/api/v1/common/media/`,
  method: 'post',
  paramName: 'file',
  createImageThumbnails: true,
  clickable: true,
  autoReset: null,
  errorReset: null,
  cancelReset: null,
  addRemoveLinks: true,
  dictCancelUpload: 'Prekliči',
  dictCancelUploadConfirmation: 'Ste prepričani, da želite preklicati nalaganje datoteke?',
  dictDefaultMessage: 'Izberi datoteko',
  dictRemoveFile: 'Odstrani datoteko'
};

FullCalendarModule.registerPlugins([dayGridPlugin, timeGridPlugin, interactionPlugin, rrulePlugin]);

@NgModule({
  declarations: [
    ContentBoxComponent,
    NewsItemComponent,
    NewsListComponent,
    ConfirmModalComponent,
    FileSelectComponent,
    HoverTableComponent,
    NoticesListComponent,
    NoticesItemComponent,
    FileUrlModalComponent,
    ImageUploadPreviewComponent,
    VideoModalComponent,
    TagListComponent,
    TagsFilterComponent,
    NewsItemModalComponent,
    LinkModalComponent,
    MagazineItemComponent,
    EmployeeItemComponent,
    MagazineModalComponent,
    FileComponent,
    EcoComponent,
    SearchResultsComponent,
    CropImageModalComponent,
    SidebarComponent,
    MagazinesBoxComponent,
    ArticleBoxComponent,
    CalendarBoxComponent,
    LinkedinBoxComponent,
    LinkBoxComponent,
    EmployeeBoxComponent,
    PopoverWrapperComponent,
    PageContainerComponent,
    PageContainerWideComponent,
    PageWithSidebarContainerComponent,
    SafeHtmlPipe,
    ConfirmationOrErrorModalComponent
  ],
  imports: [
    NgxPaginationModule,
    NgxMaterialTimepickerModule.setLocale('sl'),
    FullCalendarModule,
    CommonModule,
    MatMenuModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatButtonModule,
    NgxFileDropModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SortablejsModule.forRoot({ animation: 150 }),
    HttpClientModule,
    MatProgressSpinnerModule,
    ToastrModule.forRoot(),
    NgxEditorModule,
    CKEditorModule,
    NgxDropzoneModule,
    NgbModule,
    SwiperModule,
    NgbPopoverModule,
    DropzoneModule,
    ImageCropperModule
  ],
  entryComponents: [
    PopoverWrapperComponent
  ],
  exports: [
    NgxMaterialTimepickerModule,
    ContentBoxComponent,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    NgxFileDropModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmModalComponent,
    FileSelectComponent,
    SortablejsModule,
    HoverTableComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatMenuModule,
    NgxPaginationModule,
    FullCalendarModule,
    NewsItemComponent,
    NewsListComponent,
    NoticesListComponent,
    NoticesItemComponent,
    HttpClientModule,
    FileUrlModalComponent,
    MatProgressSpinnerModule,
    ImageUploadPreviewComponent,
    ToastrModule,
    NgxEditorModule,
    CKEditorModule,
    NgxDropzoneModule,
    TagListComponent,
    TagsFilterComponent,
    NewsItemModalComponent,
    NgbModule,
    SwiperModule,
    MagazineItemComponent,
    EmployeeItemComponent,
    FileComponent,
    EcoComponent,
    SearchResultsComponent,
    NgbPopoverModule,
    DropzoneModule,
    ImageCropperModule,
    CropImageModalComponent,
    SidebarComponent,
    MagazinesBoxComponent,
    ArticleBoxComponent,
    CalendarBoxComponent,
    LinkedinBoxComponent,
    LinkBoxComponent,
    EmployeeBoxComponent,
    PopoverWrapperComponent,
    PageContainerComponent,
    PageContainerWideComponent,
    PageWithSidebarContainerComponent,
    SafeHtmlPipe,
    ConfirmationOrErrorModalComponent

  ],
  providers: [
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    { provide: MAT_DATE_LOCALE, useValue: 'sl' },
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: DateAdapter, useClass: CustomDateAdapter }
  ],
})
export class SharedModule {}
