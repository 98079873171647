import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Employee, MagazineList, Occurrence, User, Link, ArticleListRetrieve } from 'src/app/api/models';
import { V1Service } from 'src/app/api/services';

@Injectable({
  providedIn: 'root'
})
export class SidebarStoreService {

  private employeesSubject$: BehaviorSubject<Employee[]> = new BehaviorSubject([]);
  public readonly employees$ = this.employeesSubject$.asObservable();
  public get employeesValue() { return this.employeesSubject$.value };


  private magazinesSubject$: BehaviorSubject<MagazineList[]> = new BehaviorSubject([]);
  public readonly magazines$ = this.magazinesSubject$.asObservable();


  private occurencesSubject$: BehaviorSubject<Occurrence[]> = new BehaviorSubject([]);
  public readonly eoccurences$ = this.occurencesSubject$.asObservable();


  private usersSubject$: BehaviorSubject<User[]> = new BehaviorSubject([]);
  public readonly users$ = this.usersSubject$.asObservable();


  private linkedInLinkSubject$: BehaviorSubject<Link> = new BehaviorSubject(null);
  public readonly linkedInLink$ = this.linkedInLinkSubject$.asObservable();


  private linksSubject$: BehaviorSubject<Link[]> = new BehaviorSubject([]);
  public readonly links$ = this.linksSubject$.asObservable();

  private focusedArticleSubject$: BehaviorSubject<ArticleListRetrieve> = new BehaviorSubject(null);
  public readonly focusedArticle$ = this.focusedArticleSubject$.asObservable();


  constructor(private api: V1Service) {
  }

  loadData() {
    console.log("loadData sidebar");
    this.fetchMagazines();
    this.fetchEmployees();
    this.fetchLinkedIn();
    this.fetchLinks();
    this.fetchUsers();
    this.fetchFocusedArticle();
  }

  fetchMagazines() {
    this.api
      .v1NewsMagazinesList({
        ordering: '-created_at',
        limit: 1000
      })
      .pipe(take(1))
      .subscribe((res) => {
        this.magazinesSubject$.next(res.results);
      });
  }

  fetchEmployees() {
    this.api.v1CompaniesEmployeesList({
      limit: 100,
      ordering: '-created_at',
      office: "1"
    }).pipe(
      take(1)
    ).subscribe(
      res => {
        let newEmployees = res.results.filter((employee) => employee.is_new);
        let currentDate = moment();
        this.employeesSubject$.next(newEmployees.filter((e) => Math.abs(moment(e.created_at).diff(currentDate, 'days')) <= 14).slice(0,2));
      }
    )
  }

  fetchLinkedIn() {
    this.api.v1NewsLinksLinkedinRetrieve().pipe(take(1)).subscribe(
      res => {
        this.linkedInLinkSubject$.next(res);
      }
    )
  }

  fetchLinks() {
    this.api
      .v1NewsLinksList({
        limit: 1000
      })
      .pipe(take(1))
      .subscribe((res) => {
        this.linksSubject$.next(res.results);
      });
  }

  fetchOccurences(date: any) {
    this.api
      .v1CalendarOccurrencesList({
        limit: 1000,
        start_datetime__gte: moment(date).startOf('month').toISOString(),
        end_datetime__lte: moment(date).endOf('month').toISOString(),
      })
      .pipe(take(1))
      .subscribe((res) => {
        this.occurencesSubject$.next(res.results);
      });
  }

  fetchUsers() {
    this.api.v1UsersUsersList({
      limit: 1000
    }).pipe(take(1)).subscribe(
      res => {
        console.log(res.results);
        this.usersSubject$.next(res.results);
      }
    )
  }

  fetchFocusedArticle() {
    this.api.v1NewsArticlesList({
      limit: 1,
      tags__id__in: [7],
      ordering: '-created_at'
    }).pipe(take(1)).subscribe(
      res => {
        this.focusedArticleSubject$.next(res.results[0]);
      }
    )
  }
}
