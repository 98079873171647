<div id="modal-window">
  <div mat-dialog-title>
    <h3>{{data.title || 'Uredi novico'}}</h3>
    <a [mat-dialog-close]="false" class="modal-close-btn"></a>
  </div>

  <div mat-dialog-content>
    <form action="" [formGroup]="form" [ngClass]="{'submitted' : submitted}">

      <div class="row justify-content-between mb-3 flex-wrap-reverse">
        <div class="col-12 col-md-8">
          <ng-container *ngIf="this.form.controls.subtitle">
            <label>Nadnaslov</label>
            <input type="text" formControlName="subtitle">
          </ng-container>

          <label>Naslov</label>
          <input type="text" formControlName="headline">

          <label>Uvod</label>
          <textarea rows="2" formControlName="introduction"></textarea>
        </div>

        <div class="col-12 col-md-4">
          <dropzone class="dropzone-container" #dropzoneRef [config]="config" [message]="'NALOŽI SLIKO'"
          (init)="onUploadInit($event)" (error)="onUploadError($event)" (success)="onUploadSuccess($event)" (removedFile)="onRemoved($event)" (complete)="onComplete($event)">
          </dropzone>
          <a href="" (click)="$event.preventDefault(); cropImage()" class="crop-image" *ngIf="form.value.image">OBREŽI SLIKO</a>
        </div>
      </div>

      <ckeditor [editor]="editor" [config]="{
        image: {

          toolbar: [ 'imageTextAlternative', '|', 'imageStyle:alignLeft',  'imageStyle:alignCenter', 'imageStyle:alignRight', 'imageStyle:side'],
          styles: [
          'alignCenter',
          'alignLeft',
          'alignRight',
          'side'
          ]
        },
        mediaEmbed: {
            previewsInData: true
        }
      }" data="" formControlName="text" (ready)="onReady($event)"></ckeditor>

      <div class="mt-3">
        <app-tags-filter [tags]="tags"
          inputPrefix="news-item-modal"
          [value]="this.data.news? this.data.news.tags : this.data.notice? this.data.notice.tags : []"
          (onChange)="onTagChange($event);" [editable]="auth.isPorscheAdmin() || auth.isContentManager() || ((this.data.news || this.data.isNews) && auth.isExternalUser())" (add)="onTagAdd($event)" (delete)="onTagRemove($event)"></app-tags-filter>
      </div>

      <ng-container *ngIf="this.form.controls.is_active">
        <label class="mt-3">Aktivna</label>
        <div>
          <input type="checkbox" formControlName="is_active">
        </div>
      </ng-container>

    </form>
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button mat-button (click)="onAccept()">{{data.buttonText}}</button>
  </div>
</div>
