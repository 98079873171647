import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Tag } from 'src/app/api/models';
import { ConfirmModalComponent, ConfirmModalData } from '../modals/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-tags-filter',
  templateUrl: './tags-filter.component.html',
  styleUrls: ['./tags-filter.component.scss']
})
export class TagsFilterComponent implements OnInit, OnChanges, OnDestroy {

  $destroy = new Subject<boolean>();
  @Output() onChange = new EventEmitter<Tag[]>();
  @Output() delete = new EventEmitter<Tag>();
  @Output() add = new EventEmitter<string>();

  @Input() inputPrefix: string = '';
  @Input() value: Tag[] = [];
  @Input() tags: Tag[] = [];
  @Input() editable: boolean = false;

  selectedTags: Tag[] = [];
  tagFilterForm = this.fb.group({
    name: ['']
  });

  constructor(private fb: FormBuilder, private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    console.log("changes");
    this.tags.forEach(tag => {
      this.selectedTags = this.value.slice();
      this.tagFilterForm.addControl(tag.slug, new FormControl(
        (this.selectedTags.findIndex(t => t.id == tag.id) >= 0)? true : false
      ));
      this.tagFilterForm.controls[tag.slug].valueChanges.pipe(takeUntil(this.$destroy)).subscribe(
        res => {
          if (res) {
            if (this.selectedTags.findIndex((t) => t.id === tag.id) < 0) {
              this.selectedTags.push(tag);
            }
          }
          else {
            this.selectedTags = this.selectedTags.filter((t) => t.id != tag.id);
          }

          this.onChange.emit(this.selectedTags);
        }
      )
    });
    this.tagFilterForm.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.$destroy.next(true);
  }

  onSubmit() {
    if (this.tagFilterForm.invalid) {
      return;
    }
    this.add.emit(this.tagFilterForm.value.name);
  }

  onDelete(tag: Tag) {
    this.delete.emit(tag);
  }





}
