<app-content-box class="links" *ngIf="links">
  <div class="title">
    <h2>Povezave</h2>
    <div class="editable" *ngIf="auth.isContentManager()">
      <span title="Dodaj" class="edit" style="cursor: pointer;" (click)="onAddLink()"></span>
    </div>
  </div>
  <div class="links">
    <div class="link-tabs">
      <div class="tab" (click)="onTypeChange('hr')" [ngClass]="{'active': currentType === 'hr'}">HR</div>
      <div class="tab" (click)="onTypeChange('informatika')" [ngClass]="{'active': currentType === 'informatika'}">Informatika</div>
      <div class="tab" (click)="onTypeChange('coc')" [ngClass]="{'active': currentType === 'coc'}">COC</div>
      <div class="tab smaller" (click)="onTypeChange('splosne_sluzbe')" [ngClass]="{'active': currentType === 'splosne_sluzbe'}">Splošne službe</div>
    </div>
    <ul>
      <li *ngFor="let link of filterLinks(currentType)">
        <a target="_blank" href="{{link.uri}}">
          {{link.title}}
          <div class="editable" *ngIf="auth.isContentManager()">
            <a href="" class="edit" (click)="$event.preventDefault(); onEditLink(link)"></a>
            <a href="" class="delete" (click)="$event.preventDefault(); onDeleteLink(link)"></a>
          </div>
        </a>
      </li>
    </ul>
  </div>
</app-content-box>
