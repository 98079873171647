import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Link } from 'src/app/api/models';

@Component({
  selector: 'app-link-modal',
  templateUrl: './link-modal.component.html',
  styleUrls: ['./link-modal.component.scss'],
})
export class LinkModalComponent implements OnInit {
  form = this.fb.group({
    title: ['', Validators.required],
    uri: ['', Validators.required],
    type: ['hr', Validators.required],
  });

  submitted: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<LinkModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LinkModalData,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form.patchValue(this.data.link);
  }

  onAccept(): void {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }

    this.dialogRef.close(this.form.value);
  }
}

export interface LinkModalData {
  link?: Link;
  title?: string;
  buttonText?: string;
}
