import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { Link } from 'src/app/api/models';
import { V1Service } from 'src/app/api/services';
import { AuthenticationService } from 'src/app/modules/core/services/authentication.service';
import { SidebarStoreService } from 'src/app/modules/core/services/sidebar-store.service';
import { LinkModalComponent, LinkModalData } from 'src/app/modules/shared/components/modals/link-modal/link-modal.component';

@Component({
  selector: 'app-linkedin-box',
  templateUrl: './linkedin-box.component.html',
  styleUrls: ['./linkedin-box.component.scss']
})
export class LinkedinBoxComponent implements OnInit {

  linkedInLink: Link;

  constructor(private api: V1Service, private dialog: MatDialog, public auth: AuthenticationService, private store: SidebarStoreService) {
    store.linkedInLink$.subscribe(res => {
      if (res) {
        this.linkedInLink = res;
      }
    })
  }

  ngOnInit(): void {
    // this.fetchLinkedIn();
  }

  fetchLinkedIn() {
    this.api.v1NewsLinksLinkedinRetrieve().pipe(take(1)).subscribe(
      res => {
        this.linkedInLink = res;
      }
    )
  }

  editLinkedIn() {
    let data: LinkModalData = {
      link: this.linkedInLink,
      buttonText: 'Uredi'
    }
    const dialogRef = this.dialog.open(
      LinkModalComponent, {
        data: data
      }
    )

    dialogRef.afterClosed().pipe(take(1)).subscribe(
      res => {
        console.log(res);
        if (res) {
          this.api.v1NewsLinksLinkedinPartialUpdate$Json({
            body: res
          }).pipe(take(1)).subscribe(
            res => {
              // console.log(res);
              // this.linkedInLink = res;
              this.store.fetchLinkedIn();
            }
          )
        }
      }
    )
  }

}
