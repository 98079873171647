import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArticleListRetrieve, Media, NoticeListRetrieve, Tag } from 'src/app/api/models';
import '@ckeditor/ckeditor5-build-classic/build/translations/sl';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AuthenticationService } from 'src/app/modules/core/services/authentication.service';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { CropImageModalComponent, CropImageModalData } from '../crop-image-modal/crop-image-modal.component';
import { take } from 'rxjs/operators';
import { V1Service } from 'src/app/api/services';
import { ThrowStmt } from '@angular/compiler';
import { ConfirmModalComponent, ConfirmModalData } from '../confirm-modal/confirm-modal.component';
import { MyUploadAdapter } from 'src/app/modules/core/helpers/uploadAdapter';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news-item-modal',
  templateUrl: './news-item-modal.component.html',
  styleUrls: ['./news-item-modal.component.scss']
})
export class NewsItemModalComponent implements OnInit, AfterViewInit {

  form = this.fb.group({
    headline: ['', Validators.required],
    subtitle: ['', Validators.required],
    introduction: ['', Validators.required],
    text: ['', Validators.required],
    image: ['', Validators.required],
    tags: [],
    is_active: [true]
  });

  fileForCropping: Media;

  @ViewChild('dropzoneRef', { static: false }) dropzoneRef?: DropzoneComponent;

  public config: DropzoneConfigInterface;

  submitted: boolean = false;
  public editor = ClassicEditor;
  tags: Tag[];

  constructor(
    public dialogRef: MatDialogRef<NewsItemModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewsItemModalData,
    private fb: FormBuilder,
    public auth: AuthenticationService,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private api: V1Service
  ) {
    console.log(this.editor);
    if (environment.authType == 'basic') {
      this.config = {
        headers: {
          "Authorization": `Basic ${this.auth.getAuthString()}`
        },
        maxFiles: 1,
        acceptedFiles: 'image/*',
      };
    }
    else {
      this.config = {
        headers: {
          'Authorization': `Bearer ${this.auth.accessToken}`,
          'X-CSRFToken': this.auth.getCSRFToken()
        },
        maxFiles: 1,
        withCredentials: true,
        acceptedFiles: 'image/*',
      };
    }

  }

  ngOnInit(): void {
    console.log(this.data);
    this.tags = this.data.tags;
    if (this.data.news) {
      this.data.isNews = true;
      this.form.patchValue(
        this.data.news
      );
      this.form.controls.tags.patchValue(this.data.news.tags.map(t => t.id));
    }
    else if (this.data.notice) {

      this.form.removeControl('subtitle');
      this.form.removeControl('is_active');
      this.form.updateValueAndValidity();

      this.form.patchValue(
        this.data.notice
      );
      this.form.controls.tags.patchValue(this.data.notice.tags.map(t => t.id));
    }
  }

  ngAfterViewInit() {
    if (this.data.news) {
      this.setCurrentImage(this.data.news.image);
    }
    else if (this.data.notice) {
      this.setCurrentImage(this.data.notice.image);
    }
    this.cdRef.detectChanges();
  }

  onReady(editor: ClassicEditor): void {
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
      return new MyUploadAdapter( loader, this.config.headers );
    };
  }

  onAccept(): void {
    this.submitted = true;
    if (!this.form.valid) {
      console.log(this.form.value);
      return;
    }

    this.dialogRef.close(this.form.value);
  }

  onTagChange(selectedTags: Tag[]) {
    this.form.controls.tags.patchValue(selectedTags.map(t => t.id));
  }

  onTagAdd(name: string) {
    this.api.v1CommonTagsCreate$Json({
      body: {
        name: name
      }
    }).pipe(
      take(1)
    ).subscribe(
      res => {
        this.tags = [...this.tags, res];
      }
    )
  }

  onTagRemove(tag: Tag) {

    let data: ConfirmModalData = {
      title: 'Pozor',
      content: 'Oznaka bo trajno zbrisana, ste prepričani?',
      buttonText: 'Zbriši',
      buttonClass: 'btn-red'
    }
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: data
    });

    dialogRef.afterClosed().pipe(
      take(1)
    ).subscribe(
      res => {
        if (res) {
          this.api.v1CommonTagsDestroy({
            id: tag.id
          }).pipe(
            take(1)
          ).subscribe(
            res => {
              this.tags = this.tags.filter((t) => t.id != tag.id);
              this.form.controls.tags.patchValue(
                this.form.value.tags?.filter((t) => t != tag.id)
              );
            }
          )
        }
      }
    )
  }

  public onComplete(args: any): void {
    console.log(args);
  }

  public onUploadInit(args: any): void {
    console.log('onUploadInit:', args);
  }

  public onUploadError(args: any): void {
    console.log('onUploadError:', args);
  }

  public onUploadSuccess(args: any): void {
    console.log('onUploadSuccess:', args);

    if (args.length > 1) {
      this.form.controls.image.patchValue(args[1].id);
      this.fileForCropping = args[1];
    }
  }

  onRemoved(file: File) {
    console.log("on remove ", file);

    if (this.data.news) {
      this.form.controls.image.patchValue(this.data.news.image?.id);
    }
    if (this.data.notice) {
      this.form.controls.image.patchValue(this.data.notice.image?.id);
    }
    else {
      this.form.controls.image.patchValue(null);
    }
    this.fileForCropping = null;
  }

  cropImage() {
    if (!this.fileForCropping) return;

    let data: CropImageModalData = {
      image: this.fileForCropping
    }
    const ref = this.dialog.open(CropImageModalComponent, {
      data: data
    });

    ref.afterClosed().pipe(take(1)).subscribe((res: Media) => {
      if (res) {
        this.setCurrentImage(res);
      }
    });
  }

  setCurrentImage(image: Media) {

    this.fileForCropping = image;
    let dz = this.dropzoneRef.directiveRef.dropzone();
    dz.removeAllFiles(true);

    const imageMockFile = { name: image.file, size: image.media_size, accepted: true };

    dz.emit( "addedfile", imageMockFile);
    dz.emit( "thumbnail", imageMockFile, image.file);
    dz.emit( "complete", imageMockFile);
    dz.files.push(imageMockFile);

    this.form.patchValue({
      image: image.id
    });

  }
}

export interface NewsItemModalData {
  news?: ArticleListRetrieve;
  notice?: NoticeListRetrieve;
  isNews?: boolean;
  tags?: Tag[];
  title?: string;
  buttonText?: string;
}

