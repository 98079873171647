<div class="file-preview d-flex mt-2" *ngIf="fileObjects.length > 0">
  <div class="file col-6 col-lg-3" *ngFor="let file of fileObjects">
    <div class="image">
      <img [src]="file.dataUrl">
    </div>
    <div class="details">
      <div class="size">
        <span><strong>{{ (file.file.size / 1000) | number:'0.0-0'}}</strong> KB</span>
      </div>
      <div class="name">
        <span>{{file.file.name}}</span>
      </div>
    </div>
  </div>
</div>
