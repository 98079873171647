import { Component, Input, OnInit } from '@angular/core';
import { NoticeListRetrieve } from 'src/app/api/models';

@Component({
  selector: 'app-notices-list',
  templateUrl: './notices-list.component.html',
  styleUrls: ['./notices-list.component.scss']
})
export class NoticesListComponent implements OnInit {

  @Input() notices: NoticeListRetrieve[];

  constructor() { }

  ngOnInit(): void {
  }

}
