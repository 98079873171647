<div>
  <div mat-dialog-title>
    <h3>{{data.title || "Dodaj revijo"}}</h3>
    <a [mat-dialog-close]="false" class="modal-close-btn"></a>
  </div>

  <div mat-dialog-content>
    <form action="" [formGroup]="form" [ngClass]="{'submitted' : submitted}">

      <label>Naslov</label>
      <input type="text" formControlName="title">

      <label>Opis</label>
      <input type="text" formControlName="description">

      <div class="row">

        <div class="col-12 col-lg-6">
          <dropzone class="dropzone-container" #imageDropzoneRef [config]="configImg" [message]="'SLIKA'"
            (init)="onUploadInitImage($event)" (error)="onUploadErrorImage($event)" (success)="onUploadSuccessImage($event)" (removedFile)="onRemovedImage()">
          </dropzone>
        </div>

        <div class="col-12 col-lg-6">

          <dropzone class="dropzone-container" #pdfDropzoneRef [config]="configPdf" [message]="'PDF'"
            (init)="onUploadInitDocument($event)" (error)="onUploadErrorDocument($event)" (success)="onUploadSuccessDocument($event)" (removedFile)="onRemovedPdf()">
          </dropzone>
        </div>
      </div>

    </form>
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button mat-button (click)="onAccept()">{{data.buttonText || "Dodaj revijo"}}</button>
  </div>
</div>
