import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MenuComponent } from './components/menu/menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { MainMenuComponent } from './components/menu/main-menu/main-menu.component';
import { TopBarComponent } from './components/menu/top-bar/top-bar.component';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

@NgModule({
  declarations: [
    FooterComponent,
    TopBarComponent,
    MainMenuComponent,
    MenuComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    OAuthModule.forRoot()
  ],
  providers: [
    {
      provide: OAuthStorage,
      useValue: localStorage
    }
  ],
  exports: [
    FooterComponent,
    TopBarComponent,
    MainMenuComponent,
    MenuComponent,
    OAuthModule
  ]
})
export class CoreModule {
  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`CoreModule has already been loaded. Import Core modules in the AppModule only.`);
   }
 }
}
