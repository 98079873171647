<app-content-box class="magazines" *ngIf="magazines">
  <div class="title">
    <h2>Revije</h2>
    <div class="editable" *ngIf="auth.isContentManager() || auth.isExternalUser()">
      <span class="archive upload" title="Dodaj" style="cursor: pointer;" (click)="onAdd()"></span>
      <a class="edit" title="Uredi" (click)="onEdit()"></a>
      <a class="delete" title="Briši" (click)="onDelete()"></a>
    </div>
    <a [routerLink]="['/revije']" class="archive">Arhiv</a>
  </div>

  <div class="magazine-swiper-container" *ngIf="magazines">
    <swiper [config]="config" [(index)]="index">
      <ng-container *ngFor="let magazine of magazines">
        <app-magazine-item [magazine]="magazine"></app-magazine-item>
      </ng-container>
    </swiper>
      <div class="custom-swiper-navigation magazines-navigation mt-3 d-flex justify-content-between align-items-center">
        <span class="swiper-prev"></span>
        <span class="swiper-next"></span>
      </div>
  </div>

</app-content-box>
