import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popover-wrapper',
  templateUrl: './popover-wrapper.component.html',
  styleUrls: ['./popover-wrapper.component.scss']
})
export class PopoverWrapperComponent {
  template: TemplateRef<any>;

  @ViewChild(NgbPopover, { static: true }) popover: NgbPopover;
}
