<div class="tag-filter-container">
  <form action="" [formGroup]="tagFilterForm" class="d-flex flex-wrap">
    <ng-container *ngIf="tags?.length > 0">
      <div class="tag" *ngFor="let tag of tags">
        <input type="checkbox" class="btn-check" id="{{inputPrefix? inputPrefix : ''}}_{{tag.slug}}" formControlName="{{tag.slug}}" autocomplete="off">
        <label for="{{inputPrefix? inputPrefix : ''}}_{{tag.slug}}">{{tag.name}}</label><br>
        <div class="editable">
          <a href="#" (click)="$event.preventDefault(); onDelete(tag)" *ngIf="editable" class="delete"></a>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="editable">
      <input formControlName="name" type="text" placeholder="Nova oznaka">
      <button class="btn-classic ml-1" (click)="onSubmit()">Dodaj oznako</button>
    </ng-container>
  </form>
</div>
