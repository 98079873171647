// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const oauth = {
  clientId : '97bb9f3a-da53-4425-888d-4a4387d89edc',
  redirectURI: 'https://porsche-frontend.dev.mortar.tovarnaidej.com/token',
  resource: 'porsche-intranet.dev.tovarnaidej.com',
  issuer: 'https://sts1.porsche.si/adfs',
  audience: 'microsoft:identityserver:porsche-intranet.dev.tovarnaidej.com"',
  flow: 'code' // code or implicit
}

export const environment = {
  production: false,
  loginURL: oauth.issuer + "/oauth2/authorize?client_id=" + oauth.clientId + "&response_type=token&redirect_uri=" + encodeURI(oauth.redirectURI) + "&response_mode=fragment&resource=" + oauth.resource,
  logoutURL: "https://porsche-intranet.dev.tovarnaidej.com:8000/oauth2/logout",
  rootUrl: 'https://porsche-intranet.dev.tovarnaidej.com:8000',
  authType: 'basic'
};

/*u can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * For easier debugging in development mode, yo
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

