import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-or-error-modal',
  templateUrl: './confirmation-or-error-modal.component.html',
  styleUrls: ['./confirmation-or-error-modal.component.scss']
})
export class ConfirmationOrErrorModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationOrErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationOrErrorModalData
  ) { }

  ngOnInit(): void {
  }

}

export interface ConfirmationOrErrorModalData {
  title: string;
  contentTitle?: string;
  contentText?: string;
  error?: boolean;
}
