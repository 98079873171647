import { Component, Input, OnInit } from '@angular/core';
import { Tag } from 'src/app/api/models';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent implements OnInit {

  @Input() tags: Tag[] = [];
  @Input() limit: number;

  constructor() { }

  ngOnInit(): void {
  }

}
