<div class="crop-image-modal">
  <div mat-dialog-title>
    <h3>{{'Obreži sliko'}}</h3>
    <a [mat-dialog-close]="false" class="modal-close-btn"></a>
  </div>
  <div mat-dialog-content>

    <div class="d-flex flex-wrap">
      <button class="btn-classic mr-3 mb-3" (click)="rotateLeft()">Zavrti levo</button>
      <button class="btn-classic mr-3 mb-3" (click)="rotateRight()">Zavrti desno</button>
      <button class="btn-classic mr-3 mb-3" (click)="flipHorizontal()">Preslikaj horizontalno</button>
      <button class="btn-classic mr-3 mb-3" (click)="flipVertical()">Preslikaj vertikalno</button>
    </div>

    <!-- <label>Spremeni rotacijo</label>
    <input [(ngModel)]="rotation" placeholder="Rotation" type="number" (keyup)="updateRotation()" />
    <br> -->
    <div class="d-flex flex-wrap">
      <button class="btn-classic mb-2 mr-3" (click)="zoomOut()">Zumiraj -</button>
      <button class="btn-classic mb-2 mr-3" (click)="zoomIn()">Zumiraj +</button>
      <button class="btn-classic mb-2" (click)="resetImage()">Ponastavi Sliko</button>
    </div>

    <div>
      <image-cropper [imageURL]="data.image?.file" [maintainAspectRatio]="true"
        [containWithinAspectRatio]="containWithinAspectRatio" [maintainAspectRatio]="false" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation"
        [transform]="transform" [alignImage]="'left'" format="png"
        (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
    <label class="mt-3">Predogled</label>
    <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" class="mb-5" />
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button mat-button (click)="onAccept()">Obreži</button>
  </div>
</div>
