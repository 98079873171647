<div class="topbar">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="topbar-links">
          <a [routerLink]="['/coc']" routerLinkActive="router-link-active" class="link">Smernice CoC</a>
          <a [routerLink]="['/vozni-park']" routerLinkActive="router-link-active" *ngIf="auth.isCarParkManager()"
            class="link">Vozni park</a>
          <a [routerLink]="['/imenik']" routerLinkActive="router-link-active" class="link">Imenik</a>

          <div class="usermenu">
            <div class="usermenu-link">
              <div class="link">{{auth.userValue.first_name}} {{auth.userValue.last_name}}</div>
            </div>
            <div class="menu">
              <div class="usermenu-title">Nastavitve obveščanja</div>
              <form [formGroup]="settingsForm">

                <div class="settings : usermenu-settings">
                  <span class="usermenu-text">Želim, da me prek e-maila obveščate o naslednjih novostih in objavah na
                    intranetu:</span>
                  <div class="item">
                    <p>Novice</p>
                    <label for="newsToggle" [ngClass]="{'yes': settingsForm.value.article_alert, 'no': !settingsForm.value.article_alert }" class="toggle">
                      <div>
                        {{settingsForm.value.article_alert? 'Da' : 'Ne'}}
                      </div>
                      <input type="checkbox" hidden formControlName="article_alert" id="newsToggle">
                    </label>
                  </div>

                  <div class="item">
                    <p>Foto in video galerija:</p>
                    <label for="galleryToggle" [ngClass]="{'yes': settingsForm.value.gallery_alert, 'no': !settingsForm.value.gallery_alert }" class="toggle">
                      <div>
                        {{settingsForm.value.gallery_alert? 'Da' : 'Ne'}}
                      </div>
                      <input type="checkbox" hidden formControlName="gallery_alert" id="galleryToggle">
                    </label>
                  </div>
                </div>
                <span class="usermenu-text">Za potrjene vsebine boste prejemali obvestila na e-naslov:</span>
                <input type="text" value="{{auth.userValue.email}}" readonly="readonly">
              </form>
            </div>
          </div>

          <a href="" class="link" (click)="$event.preventDefault(); logout()">Odjava</a>
        </div>
      </div>
    </div>
  </div>
</div>
