import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-image-upload-preview',
  templateUrl: './image-upload-preview.component.html',
  styleUrls: ['./image-upload-preview.component.scss']
})
export class ImageUploadPreviewComponent implements OnInit, OnChanges {

  @Input() files: File[] = [];
  fileObjects: any[] = [];

  constructor() { }

  ngOnInit(): void {
    this.parse();

    console.log("init", this.files);

    // setInterval(()=> {console.log("init", this.files)}, 300)

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.parse();

    console.log(this.files);
  }

  parse() {
    this.fileObjects = [];
    this.files.forEach((file: File) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.fileObjects.push({file: file, dataUrl: event.target.result});
      }
      reader.readAsDataURL(file);
    })
  }

}
