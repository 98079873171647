<div id="modal-window">
  <div mat-dialog-content>
    <div class="file-url d-flex align-items-center">
      <p class="mb-0">{{data}}</p>
    </div>
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <!-- <button mat-button (click)="onNoClick()">No Thanks</button> -->
    <button mat-button [mat-dialog-close]="" cdkFocusInitial>OK</button>
  </div>
</div>
