<app-content-box class="employees" *ngIf="employees">
  <div class="title">
    <h2>Novi zaposleni</h2>
    <a [routerLink]="['/imenik']" class="archive">Imenik</a>
  </div>
  <div class="row">
    <div class="col-6" *ngFor="let employee of employees">
      <app-employee-item [employee]="employee" [extendedInfo]="false"></app-employee-item>
    </div>
  </div>
</app-content-box>
