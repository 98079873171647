<a [routerLink]="['/obvestila/details/' + notice.id]" class="more news-item horizontal">
  <div class="image"
    style="background-image: url('{{notice.image?.file_thumbnail ? notice.image?.file_thumbnail : notice.image?.file}}');">
  </div>
  <div class="text mt-3 mt-md-0">
    <h3 class="color-primary text-uppercase mb-0">{{notice.headline}}</h3>
    <em class="mb-2">{{notice.created_at | date:"dd.MM.yyyy"}} ob {{notice.created_at | date:"HH:mm"}}</em>
    <p>{{notice.introduction}}</p>
    <app-tag-list [tags]="notice.tags" [limit]="1"></app-tag-list>
    <a [routerLink]="['/obvestila/details/' + notice.id]" class="readmore mt-0">Več</a>
  </div>
</a>
