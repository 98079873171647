import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Employee } from 'src/app/api/models';
import { V1Service } from 'src/app/api/services';
import { SidebarStoreService } from 'src/app/modules/core/services/sidebar-store.service';

@Component({
  selector: 'app-employee-box',
  templateUrl: './employee-box.component.html',
  styleUrls: ['./employee-box.component.scss']
})
export class EmployeeBoxComponent implements OnInit {

  employees: Employee[];

  constructor(private store: SidebarStoreService) {
    store.employees$.subscribe(res => {
      if (res) {
        this.employees = res;
      }
    })
  }

  ngOnInit(): void {
  }

}
