<div id="modal-window">
  <div mat-dialog-content>
    <div class="container">
      <video src="{{data.src}}" controls autoplay></video>
    </div>
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <a [mat-dialog-close]="false" class="modal-close-btn"></a>
  </div>
</div>
