<a [routerLink]="['/novice/details/' + article.id]" class="news-item" [ngClass]="{'horizontal': horizontal, 'hover-animation': hoverAnimation}" *ngIf="article">
  <div class="image" [ngClass]="{'radius': showImageRadius}" style="background-image: url('{{article.image?.file_thumbnail ? article.image?.file_thumbnail : article.image?.file}}');"></div>
  <div class="text" [ngClass]="{'pl-0 pr-0': noPad}">
    <span>{{article.subtitle}}</span>
    <h3 class="text-uppercase">{{article.headline}}</h3>
    <em class="mb-2">{{article.created_at | date:"dd.MM.yyyy"}} ob {{article.created_at | date:"HH:mm"}}</em>
    <p *ngIf="showIntro">{{article.introduction}}</p>
    <app-tag-list *ngIf="showTags" [tags]="article.tags" [limit]="1"></app-tag-list>
    <a [routerLink]="['/novice/details/' + article.id]" *ngIf="showReadMore" class="readmore">Poglej novico</a>
  </div>
</a>
